import { Box, HStack, Text } from '@gluestack-ui/themed-native-base';
import { forwardRef } from 'react';
import { Pressable } from 'react-native';

import { AvatarWithOnline } from '../AvatarWithOnline';
import { CustomSuggestionItemProps } from './types';

export const CustomSuggestionItem = forwardRef((props: CustomSuggestionItemProps, ref: React.Ref<HTMLDivElement>) => {
  const { suggestionType, item, onHoverIn, onHoverOut, onPress, selected } = props;
  switch (suggestionType) {
    case 'mention':
      return (
        <Box ref={ref}>
          <Pressable onHoverIn={onHoverIn} onHoverOut={onHoverOut} onPress={onPress}>
            <HStack
              space={2}
              paddingY={2}
              paddingX={2}
              alignItems="center"
              backgroundColor={selected ? 'gray.200' : 'white'}
            >
              <AvatarWithOnline userId={item.id} name={item.name || ''} online={item.isOnline} size={8} iconSize={8} />
              <Text fontSize="md" bold>
                {item.name}
              </Text>
            </HStack>
          </Pressable>
        </Box>
      );
    case 'emoji':
      return (
        <Box ref={ref}>
          <Pressable onHoverIn={onHoverIn} onHoverOut={onHoverOut} onPress={onPress}>
            <HStack
              space={2}
              paddingY={2}
              paddingX={2}
              alignItems="center"
              backgroundColor={selected ? 'gray.200' : 'white'}
            >
              <Text fontSize="md" bold paddingX={2}>
                {`${item.skins?.[0].native} :${item.id}:`}
              </Text>
            </HStack>
          </Pressable>
        </Box>
      );
    default:
      return null;
  }
});
