import { useMemo } from 'react';

import { Channel as StreamChannel } from 'stream-chat';
import { useStreamChatContext } from '~/contexts/StreamChatContext';
import { AvatarWithOnline } from './AvatarWithOnline';

type ChannelAvatarProps = {
  channel: StreamChannel;
};

// 対象が1人の場合：自分のアバターを表示
// 対象が2人の場合：相手のアバターを表示
// 対象が3人以上の場合：最終投稿者のアバターを表示
const getDMAvatarUser = (channel: StreamChannel, chatUserId: string) => {
  const members = channel.state.members;
  const memberIds = Object.keys(members);

  if (memberIds.length > 2) {
    return channel.state.messages[channel.state.messages.length - 1]?.user;
  } else {
    const otherId = memberIds.find((key) => key !== chatUserId);
    return otherId ? members[otherId].user : members[chatUserId]?.user;
  }
};

export const CustomDMListAvatar: React.FC<ChannelAvatarProps> = ({ channel }) => {
  const { chatUserId } = useStreamChatContext();

  const user = useMemo(() => {
    if (!chatUserId) return null;
    const avatarUser = getDMAvatarUser(channel, chatUserId);
    return {
      id: avatarUser?.id,
      name: avatarUser?.name,
      online: avatarUser?.online,
    };
  }, [channel.state.members, channel.state.messages, chatUserId]);

  return (
    <AvatarWithOnline
      userId={user?.id || ''}
      name={user?.name || ''}
      online={!!user?.online}
      size="xs"
      iconSize="4xl"
    />
  );
};
