export const createSecureStorage = (options = {}) => {
  const replaceCharacter = options.replaceCharacter || '_';
  const replacer = options.replacer || defaultReplacer;

  return {
    getItem: async (key) => localStorage.getItem(replacer(key, replaceCharacter)),
    setItem: async (key, value) => localStorage.setItem(replacer(key, replaceCharacter), value),
    removeItem: async (key) => localStorage.deleteItem(replacer(key, replaceCharacter)),
  };
};

function defaultReplacer(key, replaceCharacter) {
  return key.replace(/[^a-z0-9.\-_]/gi, replaceCharacter);
}
