import { useLinkTo, useNavigation } from '@react-navigation/native';
import { Platform } from 'react-native';

import { useGetGroupsByGroupIdQuery } from '~/api/uFeedApi';
import { useStreamChatAuthContext } from '~/contexts/StreamChatContext';

interface Props {
  farmId: number;
  groupId: number;
}

export const useGroupChat = ({ farmId, groupId }: Props) => {
  const group = useGetGroupsByGroupIdQuery({ groupId });
  const { chatClient } = useStreamChatAuthContext();
  const linkTo = useLinkTo();
  const navigation = useNavigation();

  const share = (message: string, chatChannelId: string = '') => {
    const channel = chatClient?.channel('team', chatChannelId ? chatChannelId : group.data?.chat_channel_id);

    if (Platform.OS === 'web') {
      linkTo(`/chat/${channel?.id}`);
    } else {
      // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      navigation.navigate('ChatChannelMessages', { channelId: channel.id, message });
    }
  };

  return {
    share,
  };
};
