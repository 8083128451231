import { Box, Text, VStack, useTheme } from '@gluestack-ui/themed-native-base';
import React, { useMemo, useState } from 'react';
import { Platform } from 'react-native';

import SimpleMarkdown, { inlineRegex, blockRegex, DefaultRules } from 'simple-markdown';
import { TEXT_WIDTH, getChatTheme } from '~/chatTheme';

import { useChumlyLink } from '~/hooks/useChumlyLink';
// @ts-expect-error TS(2305): Module '"~/lib/StreamChatReact"' has no exported m... Remove this comment to see the full error message
import { renderText, DefaultStreamChatGenerics, RenderTextParams, useChatContext } from '~/lib/StreamChatReact';
import { overlayReactionSelector } from '~/slices/overlayReactionSlice';
import { settingsSelector } from '~/slices/settingsSlice';
import { useAppSelector } from '~/store';
import { markdownRulesForKanasapo } from '~/utils/renderTextWithKanasapo';

import { EmojiPickerComponent } from './EmojiPicker';
import { Previews } from './Previews';
import type { DefaultInOutRule } from 'simple-markdown';
import type { Attachment, ReactionResponse } from 'stream-chat';

const LINK_INSIDE = '(?:\\[[^\\]]*\\]|[^\\[\\]]|\\](?=[^\\[]*\\]))*';
const LINK_HREF_AND_TITLE = '\\s*<?((?:\\([^)]*\\)|[^\\s\\\\]|\\\\.)*?)>?(?:\\s+[\'"]([\\s\\S]*?)[\'"])?\\s*';

const UNESCAPE_URL_R = /\\([^0-9A-Za-z\s])/g;

const unescapeUrl = (rawUrlString: string | undefined) => {
  return typeof rawUrlString === 'string' ? rawUrlString.replace(UNESCAPE_URL_R, '$1') : '';
};

// modified: https://github.com/ariabuckles/simple-markdown/blob/7fb8bb5943ee4e561fec17c2e271a327f4e86d64/src/index.js#L1501
// modified: https://github.com/GetStream/stream-chat-react-native/blob/d0169b97c41d4f5dadc439585a63fa4109121fcb/package/src/components/Message/MessageSimple/utils/renderText.tsx#L146
const getCustomMarkdownRules = (
  onPress: (url: string) => void,
  getRules?: () => {
    colors: ReturnType<typeof useTheme>['colors'];
    markdownRules: DefaultRules;
    markdownStyles: any;
  }
): {
  blockQuote: Partial<DefaultInOutRule>;
  codeBlock: Partial<DefaultInOutRule>;
  inlineCode: Partial<DefaultInOutRule>;
  link: Partial<DefaultInOutRule>;
  url: Partial<DefaultInOutRule>;
} => {
  return {
    blockQuote: {
      match: blockRegex(/^( *\\>[^\n]+(\n[^\n]+)*\n*)+\n{2,}/),
      parse(capture, parse, state) {
        const content = capture[0].replace(/^ *\\> ?/gm, '');
        return {
          content: parse(content, state),
        };
      },
      react(node, output, state) {
        return (
          <Box my={2} key={state.key} borderLeftWidth={4} borderColor="#888888" borderRadius={1} pl={2}>
            {output(node.content, { ...state })}
          </Box>
        );
      },
    },
    codeBlock: {
      react(node, output, state) {
        const regex = /<ruby>.*?<\/ruby>/;
        const unescapeContent = unescapeUrl(node.content);
        const isRubyIncluded = regex.test(unescapeContent);

        let codeBlockText: React.ReactNode;
        if (isRubyIncluded) {
          const rules = getRules?.();
          const customRulesForLink = {
            ...rules,
            markdownRules: {
              ...rules?.markdownRules,
              link: {
                ...SimpleMarkdown.defaultRules.link,
                parse(capture: any) {
                  const content = capture[1];
                  return {
                    content,
                  };
                },
                react(node: any) {
                  return <Text>{unescapeUrl(node.content)}</Text>;
                },
              },
            },
          };

          codeBlockText = renderText({
            ...customRulesForLink,
            message: {
              text: unescapeContent,
            },
          } as any);
        } else {
          const linkRegex = /\[([^\]]+)\]\([^\)]+\)/g;
          const nodeContentWithoutLink = node.content.replace(linkRegex, '$1');
          codeBlockText = <Text>{nodeContentWithoutLink}</Text>;
        }

        return (
          <Box mx={-2} my={2} p={2} key={state.key} backgroundColor="#fee77a" rounded="lg">
            {codeBlockText}
          </Box>
        );
      },
    },
    inlineCode: {
      react(node, _output, _state) {
        // do not linkify
        const content = node.content.flatMap(
          (val: { type: string; content: string | { type: string; content: string } }) => {
            if (typeof val === 'object' && val.type === 'link') {
              return val.content;
            }
            return val;
          }
        ) as { type: string; content: string }[];

        return <Text>{unescapeUrl(content[0].content)}</Text>;
      },
    },
    link: {
      match: inlineRegex(new RegExp('^\\[(' + LINK_INSIDE + ')\\]\\(' + LINK_HREF_AND_TITLE + '\\)')),
      react(node, output, state) {
        const url = node.target;

        return (
          <Text key={state.key} onPress={() => onPress(url)} suppressHighlighting>
            {output(node.content, { ...state, withinLink: true })}
          </Text>
        );
      },
    },
    url: {
      match: inlineRegex(/^(https?:\/\/[^\s\]<]+[^<.,:;"')\\\]\s])/),
      parse(capture, parse, state) {
        return {
          type: 'link',
          content: [
            {
              type: 'text',
              content: unescapeUrl(capture[1]),
            },
          ],
          target: unescapeUrl(capture[1]),
          title: undefined,
        };
      },
    },
  };
};

export const CustomMessageText = (props: RenderTextParams<DefaultStreamChatGenerics>) => {
  const { colors } = useTheme();
  const { message, messageOverlay, markdownRules, markdownStyles } = props;
  const settings = useAppSelector(settingsSelector);
  const { targetMessageId, showEmojiModal } = useAppSelector(overlayReactionSelector);
  const [allReactionList, setAllReactionList] = useState<ReactionResponse[]>([]);

  const { channel } = useChatContext();

  const showModal = useMemo(() => {
    return targetMessageId === message?.id && showEmojiModal;
  }, [targetMessageId, message?.id, showEmojiModal]);

  const onPress = useChumlyLink();

  const customMarkdownRules = getCustomMarkdownRules(onPress, () => ({
    colors,
    markdownRules: {
      ...markdownRules,
      ...getCustomMarkdownRules(onPress),
      ...(settings.enableKanasapo ? markdownRulesForKanasapo : {}),
    },
    markdownStyles: {
      ...markdownStyles,
      ...getChatTheme(settings.enableUDFont).messageSimple?.content?.markdown,
    },
  }));

  React.useEffect(() => {
    const getReactionsList = async () => {
      if (message.latest_reactions && message.latest_reactions.length < 10) {
        setAllReactionList(message.latest_reactions as ReactionResponse[]);
        return;
      }

      try {
        const response = await channel?.getReactions(message.id, { limit: 300 });
        if (response) {
          setAllReactionList([...response.reactions]);
        }
      } catch (e) {
        console.error(e);
      }
    };

    getReactionsList();
  }, [channel, message.latest_reactions]);

  const MemoizedCustomMessageTextComponent = useMemo(() => {
    const copymessage = { ...message };
    if (messageOverlay && copymessage.text && copymessage.text.length > 50)
      copymessage.text = copymessage.text.substring(0, 49) + '...';

    const getRubyDict = () => {
      const rubyDict: { [key: string]: string } = {};
      const rubyRegex = /<ruby>(.*?)《(.*?)》<\/ruby>/g;

      let match;
      while ((match = rubyRegex.exec(message?.ruby || ''))) {
        rubyDict[match[1]] = match[2];
      }
      return rubyDict;
    };

    const getTextWithRuby = (text: string) => {
      const rubyDict = getRubyDict();
      if (Object.keys(rubyDict).length === 0) return text;

      const rubyRegex = new RegExp(Object.keys(rubyDict).join('|'), 'g');
      return text.replace(rubyRegex, (matched) => `<ruby>${matched}《${rubyDict[matched]}》</ruby>`);
    };

    if (!message) {
      return null;
    }

    const formatAttachments = (attachments: Attachment[]) => {
      return attachments.map((attachment) => {
        if (attachment?.og_scrape_url) {
          attachment.og_scrape_url = unescapeUrl(attachment.og_scrape_url);
        }
        return attachment;
      });
    };

    return (
      <>
        <VStack dataSet={{ classname: 'message_parent' }}>
          {message.type !== 'deleted' ? (
            Platform.OS === 'web' ? (
              renderText(messageOverlay ? copymessage?.text : message?.text)
            ) : (
              message.text &&
              renderText({
                ...props,
                colors,
                message: messageOverlay
                  ? copymessage
                  : settings.enableKanasapo && message.ruby && message.ruby !== ''
                    ? {
                        ...message,
                        text: getTextWithRuby(message.text || ''),
                        attachments: message.attachments && formatAttachments(message.attachments),
                      }
                    : { ...message, attachments: message.attachments && formatAttachments(message.attachments) },
                markdownRules: {
                  ...markdownRules,
                  ...customMarkdownRules,
                  ...(settings.enableKanasapo ? markdownRulesForKanasapo : {}),
                },
                markdownStyles: {
                  ...markdownStyles,
                  ...getChatTheme(settings.enableUDFont).messageSimple?.content?.markdown,

                  list: {
                    maxWidth: TEXT_WIDTH - 24,
                  },
                  paragraph: {
                    alignItems: 'flex-end',
                  },
                },
              })
            )
          ) : (
            <Text padding={1} fontSize="sm" color="light.500">
              メッセージが削除されました
            </Text>
          )}
          {messageOverlay ? null : <Previews message={message} />}
        </VStack>
        {/* @ts-expect-error TS(2322): Type '{ messageId: any; latestReactions: any; }' i... Remove this comment to see the full error message */}
        {showModal && <EmojiPickerComponent messageId={message.id} latestReactions={allReactionList} />}
      </>
    );
  }, [message, targetMessageId, showEmojiModal, settings.enableUDFont, settings.enableKanasapo]);

  return MemoizedCustomMessageTextComponent;
};
