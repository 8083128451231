import { useGetGroupsByGroupIdMilkingsQuery } from '~/api/uFeedApi';
import { useDateByRange } from '~/hooks/useDateByRange';
import { DateUtil } from '~/utils/DateUtils';

export const useMilkAmountByRange = (groupId: number, initialEndDate: Date, initialRange: number = 30) => {
  const { beginDate, endDate, range, preEndDate, preBeginDate, nextBeginDate, nextEndDate, setEndDate, setRange } =
    useDateByRange(initialEndDate, initialRange);

  const preMilkings = useGetGroupsByGroupIdMilkingsQuery({
    groupId,
    aggregateType: 'avg',
    beginDate: DateUtil.toYYYYMMDD(preBeginDate),
    endDate: DateUtil.toYYYYMMDD(preEndDate),
  });

  const milkings = useGetGroupsByGroupIdMilkingsQuery({
    groupId,
    aggregateType: 'avg',
    beginDate: DateUtil.toYYYYMMDD(beginDate),
    endDate: DateUtil.toYYYYMMDD(endDate),
  });

  const milkingsMA = useGetGroupsByGroupIdMilkingsQuery({
    groupId,
    // @ts-expect-error TS(2322): Type '"ma"' is not assignable to type '"avg" | "su... Remove this comment to see the full error message
    aggregateType: 'ma',
    beginDate: DateUtil.toYYYYMMDD(beginDate),
    endDate: DateUtil.toYYYYMMDD(endDate),
  });

  const nextMilkings = useGetGroupsByGroupIdMilkingsQuery({
    groupId,
    aggregateType: 'avg',
    beginDate: DateUtil.toYYYYMMDD(nextBeginDate),
    endDate: DateUtil.toYYYYMMDD(nextEndDate),
  });

  return {
    beginDate,
    endDate,
    setEndDate,
    range,
    setRange,
    preEndDate,
    nextEndDate,
    milkings,
    milkingsMA,
    preMilkings,
    nextMilkings,
  };
};
