import { useGetCurrentUserGroupsQuery } from '~/api/uFeedApi';

export const useGroups = () => {
  const { data, isLoading, isError, isSuccess } = useGetCurrentUserGroupsQuery();

  const groupById =
    data?.reduce(
      (groups, cur) => ({
        ...groups,
        // @ts-expect-error TS(2464): A computed property name must be of type 'string',... Remove this comment to see the full error message
        [cur?.id]: cur,
      }),
      {}
    ) ?? {};

  const groupByChatTeamId =
    data?.reduce(
      (groups, cur) => ({
        ...groups,
        // @ts-expect-error TS(2464): A computed property name must be of type 'string',... Remove this comment to see the full error message
        [cur?.chat_team_id]: cur,
      }),
      {}
    ) ?? {};

  return {
    isLoading,
    isError,
    isSuccess,
    groupById,
    groupByChatTeamId,
  };
};
