import { P, match } from 'ts-pattern';

import { useGetCurrentUserSettingQuery, usePutCurrentUserSettingMutation } from '~/api/uFeedApi';

export const useSearchHistory = () => {
  const userSetting = useGetCurrentUserSettingQuery();
  const [put] = usePutCurrentUserSettingMutation();

  const putSearchHistory = (query: string) => {
    if (userSetting.data) {
      if (userSetting.data.details?.search_history?.includes(query) || !query) {
        return;
      }

      const search_history = match(userSetting.data.details?.search_history)
        .with(P.nullish, () => [query])
        .with([], (emptyArr: any) => [query])
        .with(
          [
            P.string,
            P.string,
            P.string,
            P.string,
            P.string,
            P.string,
            P.string,
            P.string,
            P.string,
            P.string,
            P.string,
            P.string,
            P.string,
            P.string,
            P.string,
            P.string,
            P.string,
            P.string,
            P.string,
            P.string,
            ...P.array(P.string),
          ],
          (_arr: any) => [..._arr.slice(1, 20), query]
        )
        .with([P.string, ...P.array(P.string)], (_arr: any) => [..._arr, query])
        .run();

      put({
        body: {
          user_setting: {
            details: {
              ...userSetting.data.details,
              search_history,
            },
          },
        },
      })
        .unwrap()
        .catch((error) => {
          console.error('update error: ', error);
        });
    }
  };

  const deleteSearchHistory = (query: string) => {
    if (userSetting.data) {
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      const search_history = userSetting.data.details?.search_history.filter((h: string) => h !== query);
      put({
        body: {
          user_setting: {
            details: {
              ...userSetting.data.details,
              search_history,
            },
          },
        },
      })
        .unwrap()
        .catch((error) => {
          console.error('update error: ', error);
        });
    }
  };

  return {
    putSearchHistory,
    deleteSearchHistory,
    userSetting,
  };
};
