import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import {
  Avatar,
  Button,
  Center,
  createIcon,
  FormControl,
  HStack,
  Icon,
  IconButton,
  Input,
  Stack,
  Text,
  TextArea,
  VStack,
} from '@gluestack-ui/themed-native-base';
import { useLinkTo, useNavigation, useRoute } from '@react-navigation/native';
import { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { useGetCurrentUserQuery } from '~/api/uFeedApi';
import { useStreamChatContext } from '~/contexts/StreamChatContext';
import { queryChannel } from '~/utils/queryChannels';

type ChannelData = { channel_overview: string; name: string };

export const ChatChannelOverviewEditScreen: React.FC = () => {
  const navigation = useNavigation();
  const { params } = useRoute();
  const linkTo = useLinkTo();
  const currentUser = useGetCurrentUserQuery({});

  const { appChannel, setAppChannel, isUserConnected, chatClient, chatUserId } = useStreamChatContext();

  const [channelData, setChannelData] = useState<ChannelData | undefined>(
    // @ts-expect-error TS(2345): Argument of type '{ channel_overview: unknown; nam... Remove this comment to see the full error message
    appChannel ? { channel_overview: appChannel?.data?.channel_overview, name: appChannel?.data?.name } : undefined
  );

  const [name, setName] = useState(channelData?.name || '');
  const [channelOverview, setChannelOverview] = useState(channelData?.channel_overview || '');

  useEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });
  }, [navigation]);

  useDidUpdate(
    () => {
      const setChannel = async (id: string) => {
        const newChannel = await queryChannel(chatClient, id);
        setAppChannel(newChannel);
        setChannelData({
          channel_overview: newChannel?.data?.channel_overview,
          name: newChannel?.data?.name,
        });
        setName(newChannel?.data?.name || '');
        setChannelOverview(newChannel?.data?.channel_overview || '');
      };
      // @ts-expect-error TS(2339): Property 'channelId' does not exist on type 'objec... Remove this comment to see the full error message
      if (params?.channelId && isUserConnected) {
        // @ts-expect-error TS(2339): Property 'channelId' does not exist on type 'objec... Remove this comment to see the full error message
        setChannel(params?.channelId);

        appChannel?.watch();
      }
    },
    // @ts-expect-error TS(2339): Property 'channelId' does not exist on type 'objec... Remove this comment to see the full error message
    [params?.channelId, isUserConnected, chatClient],
    true
  );

  const onSubmit = async () => {
    try {
      await appChannel?.updatePartial({ set: { channelOverview, name } });
      // @ts-expect-error TS(2339): Property 'channelId' does not exist on type 'objec... Remove this comment to see the full error message
      navigation?.canGoBack() ? navigation.goBack() : linkTo(`/chat/memberlist/${params?.channelId}`);
    } catch (error) {
      console.error(error);
    }
  };

  const errorComponent = <Text>この画面は表示できません</Text>;

  if (appChannel?.data?.isDM || appChannel?.data?.is_my_channel) {
    return errorComponent;
  }

  if (
    // @ts-expect-error TS(2365): Operator '>' cannot be applied to types 'boolean' ... Remove this comment to see the full error message
    !appChannel?.data?.cid.match(/^team\:u-feed\-group.+/)?.length > 0 &&
    // @ts-expect-error TS(2538): Type 'undefined' cannot be used as an index type.
    appChannel?.state.members[chatUserId]?.role !== 'owner' &&
    !currentUser.data?.roles?.includes('group_admin')
  ) {
    return errorComponent;
  }

  const CameraIcon = createIcon({
    viewBox: '0 0 20 20',
    d: 'M10.0003 14.5833C11.042 14.5833 11.9274 14.2187 12.6566 13.4896C13.3857 12.7604 13.7503 11.875 13.7503 10.8333C13.7503 9.79167 13.3857 8.90625 12.6566 8.17708C11.9274 7.44792 11.042 7.08333 10.0003 7.08333C8.95866 7.08333 8.07324 7.44792 7.34408 8.17708C6.61491 8.90625 6.25033 9.79167 6.25033 10.8333C6.25033 11.875 6.61491 12.7604 7.34408 13.4896C8.07324 14.2187 8.95866 14.5833 10.0003 14.5833ZM10.0003 12.9167C9.41699 12.9167 8.92394 12.7153 8.52116 12.3125C8.11838 11.9097 7.91699 11.4167 7.91699 10.8333C7.91699 10.25 8.11838 9.75694 8.52116 9.35417C8.92394 8.95139 9.41699 8.75 10.0003 8.75C10.5837 8.75 11.0767 8.95139 11.4795 9.35417C11.8823 9.75694 12.0837 10.25 12.0837 10.8333C12.0837 11.4167 11.8823 11.9097 11.4795 12.3125C11.0767 12.7153 10.5837 12.9167 10.0003 12.9167ZM3.33366 17.5C2.87533 17.5 2.48296 17.3368 2.15658 17.0104C1.83019 16.684 1.66699 16.2917 1.66699 15.8333V5.83333C1.66699 5.375 1.83019 4.98264 2.15658 4.65625C2.48296 4.32986 2.87533 4.16667 3.33366 4.16667H5.95866L7.00033 3.04167C7.1531 2.875 7.33713 2.74306 7.55241 2.64583C7.76769 2.54861 7.99338 2.5 8.22949 2.5H11.7712C12.0073 2.5 12.233 2.54861 12.4482 2.64583C12.6635 2.74306 12.8475 2.875 13.0003 3.04167L14.042 4.16667H16.667C17.1253 4.16667 17.5177 4.32986 17.8441 4.65625C18.1705 4.98264 18.3337 5.375 18.3337 5.83333V15.8333C18.3337 16.2917 18.1705 16.684 17.8441 17.0104C17.5177 17.3368 17.1253 17.5 16.667 17.5H3.33366Z',
  });

  const CloseIcon = createIcon({
    viewBox: '0 0 20 20',
    d: 'M12.0008 13.3998L7.10078 18.2998C6.91745 18.4831 6.68411 18.5748 6.40078 18.5748C6.11745 18.5748 5.88411 18.4831 5.70078 18.2998C5.51745 18.1165 5.42578 17.8831 5.42578 17.5998C5.42578 17.3165 5.51745 17.0831 5.70078 16.8998L10.6008 11.9998L5.70078 7.0998C5.51745 6.91647 5.42578 6.68314 5.42578 6.3998C5.42578 6.11647 5.51745 5.88314 5.70078 5.6998C5.88411 5.51647 6.11745 5.4248 6.40078 5.4248C6.68411 5.4248 6.91745 5.51647 7.10078 5.6998L12.0008 10.5998L16.9008 5.6998C17.0841 5.51647 17.3174 5.4248 17.6008 5.4248C17.8841 5.4248 18.1174 5.51647 18.3008 5.6998C18.4841 5.88314 18.5758 6.11647 18.5758 6.3998C18.5758 6.68314 18.4841 6.91647 18.3008 7.0998L13.4008 11.9998L18.3008 16.8998C18.4841 17.0831 18.5758 17.3165 18.5758 17.5998C18.5758 17.8831 18.4841 18.1165 18.3008 18.2998C18.1174 18.4831 17.8841 18.5748 17.6008 18.5748C17.3174 18.5748 17.0841 18.4831 16.9008 18.2998L12.0008 13.3998Z',
  });

  return (
    <Stack backgroundColor="surfaceBrightest" flex={1}>
      {Platform.OS !== 'web' && (
        <HStack
          mt="4xl"
          px="md"
          py="xs"
          justifyContent="space-between"
          alignItems="center"
          borderBottomWidth="1"
          borderBottomColor="outlineVariant"
        >
          <Text color="onSurface" fontWeight={700} fontSize="xl" fontFamily="Hiragino Sans">
            ルーム編集
          </Text>
          <IconButton
            icon={<Icon as={CloseIcon} color="onSurfaceBright" />}
            borderRadius="full"
            onPress={() => navigation.goBack()}
          />
        </HStack>
      )}
      <Stack p="md">
        <VStack gap="lg">
          <Center>
            <VStack
              space={2}
              alignItems={{
                base: 'center',
                md: 'center',
              }}
            >
              <Avatar
                bg="purple.600"
                alignSelf="center"
                size="xl"
                source={{
                  // @ts-expect-error
                  uri: appChannel?.data?.created_by?.image,
                }}
              />
              <IconButton
                icon={<CameraIcon color="onPrimary" size="md" />}
                p="1"
                borderRadius="full"
                backgroundColor="primary"
                position="absolute"
                bottom={0}
                right={2}
              />
            </VStack>
          </Center>
          <VStack gap="md">
            <FormControl gap="2xs" fontSize="sm" fontFamify="Hiragino Sans">
              <FormControl.Label color="surfaceBrightest" fontSize="sm" fontFamify="Hiragino Sans">
                名称
              </FormControl.Label>
              <Input
                value={name}
                onChangeText={setName}
                size="2xl"
                py="md"
                outline="none"
                borderColor="outlineVariant"
                fontSize="md"
              />
            </FormControl>

            <FormControl gap="2xs" fontSize="sm" fontFamify="Hiragino Sans">
              <FormControl.Label>概要</FormControl.Label>
              <TextArea
                value={channelOverview}
                onChangeText={setChannelOverview}
                size="md"
                py="2xs"
                outline="none"
                borderColor="outlineVariant"
              />
            </FormControl>

            <Button
              size="xl"
              bg="primaryContainer"
              borderRadius="lg"
              _text={{ color: 'onPrimary', fontSize: 'md', fontWeight: '700' }}
              onPress={onSubmit}
            >
              保存
            </Button>

            <Button
              size="xl"
              variant="solid"
              borderWidth="1"
              borderColor="outlineVariant"
              borderRadius="lg"
              _text={{ color: 'primary', fontSize: 'md', fontWeight: '700' }}
              onPress={() =>
                // @ts-expect-error TS(2339): Property 'channelId' does not exist on type 'objec... Remove this comment to see the full error message
                navigation?.canGoBack() ? navigation.goBack() : linkTo(`/chat/memberlist/${params?.channelId}`)
              }
            >
              キャンセル
            </Button>
          </VStack>
        </VStack>
      </Stack>
    </Stack>
  );
};
