import { extendTheme } from 'native-base';

const baseView = ({ colorMode }: any) => ({
  backgroundColor: colorMode === 'light' ? 'white' : 'rgb(18, 18, 18)',
});

export const getTheme = (isUDFontEnabled: boolean) =>
  extendTheme({
    config: {
      useSystemColorMode: false,
    },
    components: {
      Button: {
        variants: {
          submit: () => {
            return {
              bg: '#005FFF',
              _text: {
                color: 'white',
                fontWeight: 'bold',
              },
              _disabled: {
                bg: '#9e9e9e',
              },
              rounded: 'full',
            };
          },
        },
      },
      ScrollView: {
        baseStyle: baseView,
      },
    },
    colors: {
      um: {
        activity: {
          stand: '#dcdcdc',
          lie: '#a9a9a9',
          feed: '#074f07',
          move: '#31a062',
          ruminationStanding: '#ff8c00',
          ruminationLying: '#ffd700',
        },
        cowGroups: ['#e41a1c', '#377eb8', '#4daf4a', '#984ea3', '#ff7f00', '#ffff33', '#a65628', '#f781bf', '#999999'],
        lactationNumbers: [
          '#888888',
          ...['#ffd661', '#feb052', '#fa8744', '#f35a38', '#e9002c'],
          ...['#003200', '#005800', '#008000', '#44aa34', '#73d65d'].reverse(),
        ],
      },
      background: baseView,
      base: ({ colorMode }: any) => ({
        backgroundColor: colorMode === 'light' ? 'black' : 'white',
      }),
    },
    fontConfig: {
      BIZUDPGothic: {
        100: {
          normal: 'BIZUDPGothic_400Regular',
        },
        200: {
          normal: 'BIZUDPGothic_400Regular',
        },
        300: {
          normal: 'BIZUDPGothic_400Regular',
        },
        400: {
          normal: 'BIZUDPGothic_400Regular',
        },
        500: {
          normal: 'BIZUDPGothic_700Bold',
        },
        600: {
          normal: 'BIZUDPGothic_700Bold',
        },
        700: {
          normal: 'BIZUDPGothic_700Bold',
        },
        800: {
          normal: 'BIZUDPGothic_700Bold',
        },
        900: {
          normal: 'BIZUDPGothic_700Bold',
        },
      },
    },
    fonts: isUDFontEnabled
      ? {
          heading: 'BIZUDPGothic',
          body: 'BIZUDPGothic',
          mono: 'BIZUDPGothic',
        }
      : undefined,
    fontSizes: { '3xs': 8 },
  });
