import { VStack, Divider, Box, Heading, FlatList } from '@gluestack-ui/themed-native-base';
import * as React from 'react';
import { useCallback } from 'react';

import { Task } from '~/api/uFeedApi';

import { EmptyMessage } from './EmptyMessage';
import { TaskListItem } from './TaskListItem';

interface Props {
  tasks: (Task | string)[];
  showAssignedUser?: boolean;
  showGroup?: boolean;
  groupId?: number;
  farmId?: number;
  scroll?: boolean;
}

export const TaskList: React.FC<Props & React.PropsWithChildren> = React.memo(
  ({ tasks, groupId, farmId, showAssignedUser = false, showGroup = true, scroll = true, children }) => {
    const stickyHeaderIndices = tasks
      ?.map((item, index) => {
        if (typeof item === 'string') {
          return index;
        } else {
          return null;
        }
      })
      .filter((item) => item !== null) as number[];

    const renderItem = useCallback(
      ({ item, index }: any) => {
        if (typeof item === 'string') {
          return (
            <Box backgroundColor="#ffffff" paddingBottom={2} marginBottom={2}>
              <Heading fontSize="xl">{item}</Heading>
            </Box>
          );
        }

        return (
          <>
            <TaskListItem
              farmId={farmId ? farmId : item?.farm?.id}
              // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
              groupId={groupId}
              item={item}
              {...{
                showAssignedUser,
                showGroup,
              }}
            />
            <Divider />
          </>
        );
      },
      [tasks]
    );

    return (
      <VStack space={4} flex={1}>
        {tasks.length > 0 ? (
          <FlatList
            data={tasks}
            renderItem={renderItem}
            nestedScrollEnabled={!scroll}
            scrollEnabled={!!scroll}
            //stickyHeaderにするとsectionHeaderが更新されないので、期限日順、期限なしの両方のタスクが既に存在する場合のみstickyにする
            stickyHeaderIndices={stickyHeaderIndices.length == 2 ? stickyHeaderIndices : []}
          />
        ) : (
          <EmptyMessage emptyMessage="タスクがありません" />
        )}
        {children}
      </VStack>
    );
  }
);
