import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { useState } from 'react';
import { ChannelMemberResponse } from 'stream-chat';
import { NUMBER_OF_CHAT_USERS_TO_QUERY, MAX_CHAT_USER_QUERY_OFFSET } from '~/constants';
import { useStreamChatContext } from '~/contexts/StreamChatContext';
import { getStreamChatChannelTypeById } from '~/screens/ChatChannelMessagesScreen/channelType';
import { StreamChatGenerics } from '~/types';

export const useChannelMembers = (channelId: string) => {
  const { chatClient, isUserConnected } = useStreamChatContext();
  const [allMembers, setAllMembers] = useState<ChannelMemberResponse<StreamChatGenerics>[]>([]);

  const queryChannelMembers = async () => {
    const channel = chatClient.channel(getStreamChatChannelTypeById(channelId), channelId);
    let shouldLoadMore = true;
    let offset = 0;
    const sort = {};
    const members = [];

    while (shouldLoadMore) {
      const queryResult = await channel?.queryMembers({}, sort, { offset });

      if (queryResult?.members) {
        members.push(...queryResult.members);
      }

      if ((queryResult?.members?.length ?? 0) < NUMBER_OF_CHAT_USERS_TO_QUERY || offset > MAX_CHAT_USER_QUERY_OFFSET) {
        shouldLoadMore = false;
      } else {
        offset += NUMBER_OF_CHAT_USERS_TO_QUERY;
      }
    }

    setAllMembers(members);
  };

  useDidUpdate(
    () => {
      if (!isUserConnected || !chatClient || !channelId) {
        return;
      }
      const channel = chatClient.channel(getStreamChatChannelTypeById(channelId), channelId);

      if (Object.keys(channel.state.members).length < 100) {
        setAllMembers(Object.values(channel.state.members));
      } else {
        queryChannelMembers();
      }

      channel?.on('member.added', queryChannelMembers);
      channel?.on('member.removed', queryChannelMembers);

      return () => {
        channel?.off('member.added', queryChannelMembers);
        channel.off('member.removed', queryChannelMembers);
      };
    },
    [isUserConnected, channelId],
    true
  );
  return { allMembers };
};
