import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { Box, Skeleton, VStack } from '@gluestack-ui/themed-native-base';
import { StyledProps } from 'native-base';
import * as React from 'react';

import { useActivitiesByRange } from '~/hooks/useActivitiesByRange';
import { VictoryChart, VictoryTheme, VictoryAxis, VictoryLegend, VictoryLine } from '~/lib/Victory';
import { DateUtil } from '~/utils/DateUtils';

import { GraphAlertBox } from './GraphAlertBox';
import { TableChart } from './TableChart';

interface Props {
  endDate: Date;
  range: number;
  chatChannelId?: string;
  groupId?: number;
  farmId?: number;
  isPreview?: boolean;
  width?: number;
  height?: number;
  barWidth?: number;
  showTable?: boolean;
  shareButton?: React.ReactElement;
}

export const ActivityLineChart: React.FC<Props & StyledProps> = React.memo(
  ({
    endDate,
    range,
    chatChannelId,
    groupId,
    farmId,
    isPreview = false,
    width = 400,
    height = 400,
    barWidth = 8,
    showTable = false,
    shareButton,
  }) => {
    const {
      setEndDate,
      setRange,
      activities,
      preActivities,
      range: currentRange,
      beginDate,
      // @ts-expect-error
    } = useActivitiesByRange(groupId, endDate, range);

    useDidUpdate(
      () => {
        setEndDate(endDate);
      },
      [endDate],
      true
    );

    useDidUpdate(
      () => {
        setRange(range);
      },
      [range],
      true
    );

    const addDays = (date: any) => {
      const result = DateUtil.toYYYYMMDD(DateUtil.addDays(new Date(date), range), '-');
      return result;
    };

    const byDate = (a: any, b: any) => {
      if (!a || !b) {
        return 0;
      }
      return new Date(a.date).valueOf() - new Date(b.date).valueOf();
    };

    const stackedBarChart = React.useMemo(
      () => (
        <VictoryChart
          width={isPreview ? 220 : width}
          height={isPreview ? 220 : height}
          theme={VictoryTheme.material}
          domainPadding={{ x: [0, 0], y: [0, 0] }}
          minDomain={{ y: 0 }}
          padding={
            isPreview ? { top: 20, bottom: 40, left: 40, right: 20 } : { top: 50, bottom: 26, left: 50, right: 20 }
          }
        >
          <VictoryAxis
            fixLabelOverlap
            tickFormat={(x) => {
              const date = new Date(x);
              return `${date.getMonth() + 1}/${date.getDate()}`;
            }}
          />
          <VictoryAxis dependentAxis tickFormat={(y) => y} />
          {preActivities?.data?.length === range && false
            ? [
                <VictoryLine
                  style={{ data: { stroke: '#074f07', strokeWidth: 2, strokeOpacity: 0.8, strokeDasharray: 1 } }}
                  data={
                    preActivities.data
                      ? // @ts-expect-error TS(2488): Type 'GetGroupsByGroupIdGroupActivitiesApiResponse... Remove this comment to see the full error message
                        [...preActivities.data]
                          ?.sort(byDate)
                          ?.filter((a) => a.date)
                          .map((daily) => ({ x: addDays(daily.date), y: daily?.average_feed_min }))
                      : [{}]
                  }
                />,
                <VictoryLine
                  style={{ data: { stroke: '#31a062', strokeWidth: 2, strokeOpacity: 0.8, strokeDasharray: 1 } }}
                  data={
                    preActivities.data
                      ? [...preActivities.data]
                          ?.sort(byDate)
                          ?.filter((a) => a.date)
                          .map((daily) => ({ x: addDays(daily.date), y: daily?.average_move_min }))
                      : [{}]
                  }
                />,

                <VictoryLine
                  style={{ data: { stroke: '#ff8c00', strokeWidth: 2, strokeOpacity: 0.8, strokeDasharray: 1 } }}
                  data={
                    preActivities.data
                      ? [...preActivities.data]
                          ?.sort(byDate)
                          ?.filter((a) => a.date)
                          .map((daily) => ({ x: addDays(daily.date), y: daily?.average_rumination_standing_min }))
                      : [{}]
                  }
                />,

                <VictoryLine
                  style={{ data: { stroke: '#ffd700', strokeWidth: 2, strokeOpacity: 0.8, strokeDasharray: 1 } }}
                  data={
                    preActivities.data
                      ? [...preActivities.data]
                          ?.sort(byDate)
                          ?.filter((a) => a.date)
                          .map((daily) => ({ x: addDays(daily.date), y: daily?.average_rumination_lying_min }))
                      : [{}]
                  }
                />,

                <VictoryLine
                  style={{ data: { stroke: '#dcdcdc', strokeWidth: 2, strokeOpacity: 0.8, strokeDasharray: 1 } }}
                  data={
                    preActivities.data
                      ? [...preActivities.data]
                          ?.sort(byDate)
                          ?.filter((a) => a.date)
                          .map((daily) => ({ x: addDays(daily.date), y: daily?.average_stand_min }))
                      : [{}]
                  }
                />,

                <VictoryLine
                  style={{ data: { stroke: '#a9a9a9', strokeWidth: 2, strokeOpacity: 0.8, strokeDasharray: 1 } }}
                  data={
                    preActivities.data
                      ? [...preActivities.data]
                          ?.sort(byDate)
                          ?.filter((a) => a.date)
                          .map((daily) => ({ x: addDays(daily.date), y: daily?.average_lie_min }))
                      : [{}]
                  }
                />,
              ]
            : null}
          <VictoryLine
            style={{ data: { stroke: '#074f07', strokeWidth: isPreview ? 2 : 4 } }}
            data={
              activities.data
                ? [...activities.data]
                    ?.sort(byDate)
                    ?.filter((a) => a.date)
                    .map((daily) => ({ x: daily.date, y: daily?.average_feed_min }))
                : [{}]
            }
          />
          <VictoryLine
            style={{ data: { stroke: '#31a062', strokeWidth: isPreview ? 2 : 4 } }}
            data={
              activities.data
                ? [...activities.data]
                    ?.sort(byDate)
                    ?.filter((a) => a.date)
                    .map((daily) => ({ x: daily.date, y: daily?.average_move_min }))
                : [{}]
            }
          />
          <VictoryLine
            style={{ data: { stroke: '#ff8c00', strokeWidth: isPreview ? 2 : 4 } }}
            data={
              activities.data
                ? [...activities.data]
                    ?.sort(byDate)
                    ?.filter((a) => a.date)
                    // @ts-expect-error TS(2551): Property 'average_rumination_standing_min' does no... Remove this comment to see the full error message
                    .map((daily) => ({ x: daily.date, y: daily?.average_rumination_standing_min }))
                : [{}]
            }
          />
          <VictoryLine
            style={{ data: { stroke: '#ffd700', strokeWidth: isPreview ? 2 : 4 } }}
            data={
              activities.data
                ? [...activities.data]
                    ?.sort(byDate)
                    ?.filter((a) => a.date)
                    .map((daily) => ({ x: daily.date, y: daily?.average_rumination_lying_min }))
                : [{}]
            }
          />
          <VictoryLine
            style={{ data: { stroke: '#dcdcdc', strokeWidth: isPreview ? 2 : 4 } }}
            data={
              activities.data
                ? [...activities.data]
                    ?.sort(byDate)
                    ?.filter((a) => a.date)
                    .map((daily) => ({ x: daily.date, y: daily?.average_stand_min }))
                : [{}]
            }
          />
          <VictoryLine
            style={{ data: { stroke: '#a9a9a9', strokeWidth: isPreview ? 2 : 4 } }}
            data={
              activities.data
                ? [...activities.data]
                    ?.sort(byDate)
                    ?.filter((a) => a.date)
                    .map((daily) => ({ x: daily.date, y: daily?.average_lie_min }))
                : [{}]
            }
          />
          {!isPreview ? (
            <VictoryLegend
              orientation="horizontal"
              x={60}
              gutter={20}
              data={[
                { name: '採食', symbol: { fill: '#074f07', type: 'minus' } },
                { name: '動態', symbol: { fill: '#31a062', type: 'minus' } },
                { name: '起立(反芻)', symbol: { fill: '#ff8c00', type: 'minus' } },
              ]}
            />
          ) : null}
          {!isPreview ? (
            <VictoryLegend
              x={60}
              y={20}
              orientation="horizontal"
              gutter={40}
              data={[
                { name: '横臥(反芻)', symbol: { fill: '#ffd700', type: 'minus' } },
                { name: '起立(非活動)', symbol: { fill: '#dcdcdc', type: 'minus' } },
                { name: '横臥(非活動)', symbol: { fill: '#a9a9a9', type: 'minus' } },
              ]}
            />
          ) : null}
        </VictoryChart>
      ),
      [activities.data, preActivities.data, range]
    );

    if (activities?.isLoading || preActivities?.isLoading) {
      return <Skeleton height={isPreview ? 260 : height} />;
    }

    if (activities.isError || preActivities.isError) {
      return (
        <GraphAlertBox
          onPress={() => {
            activities.refetch();
            preActivities.refetch();
          }}
        />
      );
    }

    if (!activities?.data || !preActivities?.data) {
      return <Skeleton height={isPreview ? 260 : height} />;
    }

    if (isPreview) {
      return <VStack>{stackedBarChart}</VStack>;
    }

    return (
      <>
        <Box paddingX="auto">{stackedBarChart}</Box>
        {activities?.isSuccess ? (
          <TableChart
            data={activities.data.map((item) => ({
              ...item,
              date_label: DateUtil.toMMDD(item.date),
            }))}
            headers={['日付', '採食', '動態', `起立(反芻)`, `起立(非活動)`, '横臥(反芻)', '横臥(非活動)']}
            columns={[
              'date_label',
              'average_feed_min',
              'average_move_min',
              'average_rumination_standing_min',
              'average_stand_min',
              'average_rumination_lying_min',
              'average_lie_min',
            ]}
            padding={4}
            showTable={showTable}
            widthArr={[50, 50, 50, 50, 50, 50, 50]}
          />
        ) : null}
        {shareButton ? shareButton : null}
      </>
    );
  }
);
