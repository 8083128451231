import { useDidMount, useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import { useState, useRef } from 'react';
import { Platform } from 'react-native';

import { uFeedApi, usePostPushConfigsMutation } from '~/api/uFeedApi';

import type { PushTokenListener, Subscription } from 'expo-notifications';

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: false,
  }),
});

export const usePushNotification = () => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [isPushNotificationPermitted, setIsPushNotificationPermitted] = useState(false);

  const [pushToken, setPushToken] = useState('');
  const pushTokenSubscriptionsRef = useRef<Subscription[]>([]);

  const [getPushConfigTrigger, pushConfig] = uFeedApi.endpoints.getPushConfigsExist.useLazyQuery();
  const [setPushConfig] = usePostPushConfigsMutation();
  const [configInited, setConfigInited] = useState(false);
  const NOTIFY_ALL = 0;

  useDidUpdate(() => {
    if (pushConfig.isError && 'status' in pushConfig.error && pushConfig.error.status === 404 && !configInited) {
      setPushConfig({ body: { notify_type: NOTIFY_ALL } });
      setConfigInited(true);
    }
  }, [pushConfig, configInited]);

  useDidMount(() => {
    if (Platform.OS === 'web') {
      return;
    }
    registerForPushNotificationsAsync()
      .then((token) => {
        setIsPushNotificationPermitted(true);
        getPushConfigTrigger();
        setPushToken(token);
      })
      .catch((error) => console.error('registerForPushNotificationsAsync() error', error));

    return () => {
      pushTokenSubscriptionsRef.current?.forEach((subscription) => subscription?.remove());
    };
  });

  const addPushTokenListener = (listener: PushTokenListener) => {
    const subscription = Notifications.addPushTokenListener((newToken) => {
      setPushToken(newToken.data);
      listener(newToken);
    });
    pushTokenSubscriptionsRef?.current.push(subscription);
  };

  const registerForPushNotificationsAsync = async () => {
    if (Platform.OS === 'android') {
      await Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: '#FF231F7C',
        sound: 'notification_sound_default.wav',
      });
    }

    if (Device.isDevice) {
      const { status: existingStatus } = await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;

      if (existingStatus !== 'granted') {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }

      if (finalStatus === 'granted') {
        getPushConfigTrigger(); // Push通知を許可している場合はConfigが存在するか確認して、なければ作成する
      } else {
        console.error('Failed to get push token for push notification!');
      }

      const devicePushToken = await Notifications.getDevicePushTokenAsync().catch((error) =>
        console.error('usePushNotifications() getDevicePushTokenAsync failed: ', error)
      );

      if (devicePushToken) {
        return devicePushToken.data;
      } else {
        console.error('Chumly: devicePushToken was empty');
      }
    } else {
      console.error('Must use physical device for Push Notifications');
    }
  };

  return {
    isInitialized,
    isPushNotificationPermitted,
    pushToken,
    addPushTokenListener,
  };
};
