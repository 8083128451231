import { Ionicons } from '@expo/vector-icons';
import { Box, Center, Icon, Image } from '@gluestack-ui/themed-native-base';
import { memo } from 'react';

import { useCustomMessageAvatar } from '~/hooks/useCustomMessageAvatar';

type Props = {
  userId: string;
  name: string;
  online?: boolean;
  size?: number | string;
  iconSize?: number | string;
};

const AvatarComponent = memo(({ userId, name, size, iconSize }: Omit<Props, 'online'>) => {
  const { avatar, isLoadError, onError, onLoad } = useCustomMessageAvatar(userId);

  if (userId === 'alert-chat-notifier-id') {
    return <Icon as={Ionicons} name="notifications-circle-outline" size={iconSize || 30} color="#ff0000" padding={0} />;
  }

  if (isLoadError || !avatar) {
    return <Icon as={Ionicons} name="person-circle-outline" size={iconSize || 30} color="#ccc" padding={0} />;
  }

  return (
    <Image
      backgroundColor="onSurfaceBrightest"
      source={{ uri: avatar }}
      alt={name}
      onError={onError}
      onLoad={onLoad}
      size={size || 30}
      borderRadius="full"
    />
  );
});

export const AvatarWithOnline = ({ online, ...props }: Props) => {
  return (
    <Box position="relative">
      <AvatarComponent {...props} />
      {online && (
        <Box position="absolute" top={0} right={0}>
          <Center size={3} borderRadius="full" bg="#fff" p={0.5}>
            <Box w="full" h="full" bg="#20E070" borderRadius="full" />
          </Center>
        </Box>
      )}
    </Box>
  );
};
