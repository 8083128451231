import { useGetGroupsByGroupIdGroupActivitiesQuery } from '~/api/uFeedApi';
import { useDateByRange } from '~/hooks/useDateByRange';
import { DateUtil } from '~/utils/DateUtils';

export const useActivitiesByRange = (groupId: number, initialEndDate: Date, initialRange: number = 30) => {
  const { beginDate, endDate, range, preEndDate, preBeginDate, nextBeginDate, nextEndDate, setEndDate, setRange } =
    useDateByRange(initialEndDate, initialRange);

  const preActivities = useGetGroupsByGroupIdGroupActivitiesQuery({
    groupId,
    beginDate: DateUtil.toYYYYMMDD(preBeginDate),
    endDate: DateUtil.toYYYYMMDD(preEndDate),
  });

  const preActivitiesMA = useGetGroupsByGroupIdGroupActivitiesQuery({
    groupId,
    beginDate: DateUtil.toYYYYMMDD(preBeginDate),
    endDate: DateUtil.toYYYYMMDD(preEndDate),
  });

  const activities = useGetGroupsByGroupIdGroupActivitiesQuery({
    groupId,
    beginDate: DateUtil.toYYYYMMDD(beginDate),
    endDate: DateUtil.toYYYYMMDD(endDate),
  });

  const nextActivities = useGetGroupsByGroupIdGroupActivitiesQuery({
    groupId,
    beginDate: DateUtil.toYYYYMMDD(nextBeginDate),
    endDate: DateUtil.toYYYYMMDD(nextEndDate),
  });

  return {
    beginDate,
    endDate,
    setEndDate,
    range,
    setRange,
    preEndDate,
    nextEndDate,
    activities,
    preActivities,
    nextActivities,
  };
};
