import {
  useNavigationState,
  type NavigationState,
  type ParamListBase,
  type PartialState,
  type Route,
} from '@react-navigation/native';

type NavigationRoute<ParamList extends ParamListBase, RouteName extends keyof ParamList> = Route<
  Extract<RouteName, string>,
  ParamList[RouteName]
> & {
  state?: NavigationState | PartialState<NavigationState>;
};

// https://github.com/react-navigation/react-navigation/issues/7965
export const getActiveRouteState = function (
  route: NavigationState | NavigationRoute<ParamListBase, string> | undefined
): NavigationRoute<ParamListBase, string> | null {
  if (!route) return null;
  // @ts-expect-error TS(2345): Argument of type 'Readonly<{ key: string; index: n... Remove this comment to see the full error message
  if ('state' in route) return getActiveRouteState(route.state);
  return 'index' in route ? getActiveRouteState(route.routes[route.index]) : route;
};

export const useActiveRouteState = () => {
  return useNavigationState(getActiveRouteState);
};
