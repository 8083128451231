import { Ionicons } from '@expo/vector-icons';
import { Pressable, Icon } from '@gluestack-ui/themed-native-base';
import { Platform } from 'react-native';

export const HeaderMenuButton = ({ navigation }: any) => {
  return (
    <Pressable
      alignItems="center"
      onPress={() => navigation.toggleDrawer()}
      style={{
        paddingTop: Platform.OS === 'web' ? 0 : 2,
        alignItems: 'flex-start',
        paddingLeft: Platform.OS === 'android' ? 8 : 0,
      }}
      testID="HeaderMenu"
    >
      <Icon as={Ionicons} name="menu" size="2xl" color="onSurfaceBright" />
    </Pressable>
  );
};
