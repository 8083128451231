import { AntDesign } from '@expo/vector-icons';
import { HStack, Text, Icon } from '@gluestack-ui/themed-native-base';
import { useMessageContext } from 'stream-chat-react';

export const ChatCustomPinIndicator = () => {
  const { message, isMyMessage } = useMessageContext('CustomPinIndicator');

  const pinnedBy = message.pinned_by?.name || message.pinned_by?.id;
  if (!pinnedBy) return null;

  return (
    <HStack
      display="flex"
      justifyContent={isMyMessage() ? 'flex-end' : 'flex-start'}
      alignItems="center"
      padding={2}
      space={1}
    >
      <Icon as={AntDesign} name="pushpino" size="xs" />
      <Text fontSize="xs" color="gray.500">
        {pinnedBy} がピン留めしました
      </Text>
    </HStack>
  );
};
