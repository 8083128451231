import { useRoute, useNavigation } from '@react-navigation/native';
import * as React from 'react';

import { useGetAccountsQuery } from '~/api/uFeedApi';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';
import { DetailsBuilder } from '~/components/builder/DetailsBuilder';

export const AdminAccountDetailScreen: React.FC = () => {
  const { params } = useRoute();
  const navigation = useNavigation();

  // @ts-expect-error TS(2339): Property 'queryParams' does not exist on type 'obj... Remove this comment to see the full error message
  const queryParams = params?.queryParams || {};

  return (
    <BaseScreenBuilder>
      <DetailsBuilder
        // @ts-expect-error TS(2322): Type 'UseQueryHookResult<QueryDefinition<void, Bas... Remove this comment to see the full error message
        queryResult={useGetAccountsQuery(queryParams)}
        emptyMessage="企業データがありません"
        fields={[
          {
            key: 'name',
            label: '名前',
          },
          {
            key: 'headcount_limit',
            label: '契約頭数',
            type: 'number',
          },
        ]}
        onEditPress={() =>
          // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
          navigation.navigate('AdminAccountEdit', {
            queryParams,
          })
        }
      />
    </BaseScreenBuilder>
  );
};
