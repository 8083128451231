import { useGetGroupsByGroupIdMilkingsUmCattleGroupsQuery } from '~/api/uFeedApi';
import { useDateByRange } from '~/hooks/useDateByRange';
import { DateUtil } from '~/utils/DateUtils';

export const useGroupMilkAmountByRange = (groupId: number, initialEndDate: Date, initialRange: number = 30) => {
  const { beginDate, endDate, range, preEndDate, preBeginDate, nextBeginDate, nextEndDate, setEndDate, setRange } =
    useDateByRange(initialEndDate, initialRange);

  const preMilkingsByGroup = useGetGroupsByGroupIdMilkingsUmCattleGroupsQuery({
    groupId,
    aggregateType: 'avg',
    beginDate: DateUtil.toYYYYMMDD(preBeginDate),
    endDate: DateUtil.toYYYYMMDD(preEndDate),
  });

  const preMilkingsByGroupMA = useGetGroupsByGroupIdMilkingsUmCattleGroupsQuery({
    groupId,
    // @ts-expect-error TS(2322): Type '"ma"' is not assignable to type '"avg" | "su... Remove this comment to see the full error message
    aggregateType: 'ma',
    beginDate: DateUtil.toYYYYMMDD(preBeginDate),
    endDate: DateUtil.toYYYYMMDD(preEndDate),
  });

  const milkingsByGroup = useGetGroupsByGroupIdMilkingsUmCattleGroupsQuery({
    groupId,
    aggregateType: 'avg',
    beginDate: DateUtil.toYYYYMMDD(beginDate),
    endDate: DateUtil.toYYYYMMDD(endDate),
  });

  const milkingsByGroupMA = useGetGroupsByGroupIdMilkingsUmCattleGroupsQuery({
    groupId,
    // @ts-expect-error TS(2322): Type '"ma"' is not assignable to type '"avg" | "su... Remove this comment to see the full error message
    aggregateType: 'ma',
    beginDate: DateUtil.toYYYYMMDD(beginDate),
    endDate: DateUtil.toYYYYMMDD(endDate),
  });

  const nextMilkingsByGroup = useGetGroupsByGroupIdMilkingsUmCattleGroupsQuery({
    groupId,
    aggregateType: 'avg',
    beginDate: DateUtil.toYYYYMMDD(nextBeginDate),
    endDate: DateUtil.toYYYYMMDD(nextEndDate),
  });

  return {
    beginDate,
    endDate,
    setEndDate,
    range,
    setRange,
    preEndDate,
    nextEndDate,
    milkingsByGroup,
    milkingsByGroupMA,
    preMilkingsByGroup,
    preMilkingsByGroupMA,
    nextMilkingsByGroup,
  };
};
