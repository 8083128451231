import React, { useMemo } from 'react';

import { useStreamChat } from '~/hooks';
import { useChatContext } from '~/lib/StreamChatReact';
import { LoginConfig, StreamChatGenerics } from '~/types';

import type { StreamChat, UserResponse } from 'stream-chat';

type StreamChatContextType = {
  isError: boolean;
  chatClient: StreamChat<StreamChatGenerics>;
  loginUser: (config: LoginConfig) => Promise<void>;
  logout: () => Promise<void>;
  isUserConnected: boolean;
  chatUserId?: string;
  findUsersByTeamIdAsync: (teamId: string, query?: string) => Promise<UserResponse<StreamChatGenerics>[]>;
};

export const StreamChatContext = React.createContext<StreamChatContextType>({} as StreamChatContextType);

export const StreamChatContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const streamChatContext = useStreamChat();
  // @ts-expect-error TS(2741): Property 'loginUser' is missing in type '{ isError... Remove this comment to see the full error message
  return <StreamChatContext.Provider value={streamChatContext}>{children}</StreamChatContext.Provider>;
};

// useStreamChatContext is renamed to useStreamChatAuthContext.
export const useStreamChatAuthContext = () => React.useContext(StreamChatContext);

// Workaround for backward compativility.
export const useStreamChatContext = (component?: string) => {
  const contextVal = React.useContext(StreamChatContext);

  // Using channel value from ChatContext instead of StreamChatContext.
  const { channel, setActiveChannel } = useChatContext();

  return useMemo(
    () => ({
      ...contextVal,
      appChannel: channel,
      setAppChannel: setActiveChannel,
    }),
    [contextVal, channel, setActiveChannel]
  );
};
