import { U_FEED_URL_BASE } from '@env';
import { VStack, Divider } from '@gluestack-ui/themed-native-base';
import { useRoute, useNavigation, useLinkTo } from '@react-navigation/native';
import * as React from 'react';
import { Platform } from 'react-native';

import {
  useGetTasksByIdQuery,
  usePutTasksByIdMutation,
  useGetCurrentUserQuery,
  useDeleteTasksByIdMutation,
  useGetGroupsByGroupIdQuery,
} from '~/api/uFeedApi';
import { AlertBox } from '~/components/builder/AlertBox';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';
import { DetailsBuilder } from '~/components/builder/DetailsBuilder';
import { ConfirmWithComment } from '~/components/ConfirmWithComment';
import { useStreamChatAuthContext } from '~/contexts/StreamChatContext';
import { useCustomToast } from '~/hooks/useCustomToast';
import { useGroupChat } from '~/hooks/useGroupChat';

export const TaskDetailScreen: React.FC = () => {
  const { params } = useRoute();
  const navigation = useNavigation();

  const { chatClient } = useStreamChatAuthContext();

  const queryParams = params ? { ...params } : {};
  // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
  const queryResult = useGetTasksByIdQuery({ id: queryParams?.id });
  // @ts-expect-error TS(2339): Property 'farmId' does not exist on type '{}'.
  const farmId = queryParams?.farmId;
  // @ts-expect-error TS(2339): Property 'groupId' does not exist on type '{}'.
  const groupId = queryParams?.groupId;
  const group = useGetGroupsByGroupIdQuery({ groupId });
  const chatChannelId = group.data?.chat_channel_id;
  // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
  const [updateTask, updateTaskResult] = usePutTasksByIdMutation(queryParams?.id);
  const userQueryResult = useGetCurrentUserQuery({});
  const [deleteTask, deleteTaskResult] = useDeleteTasksByIdMutation();
  const toast = useCustomToast();
  const { share } = useGroupChat({
    // @ts-expect-error TS(2339): Property 'farmId' does not exist on type '{}'.
    farmId: queryParams.farmId,
    // @ts-expect-error TS(2339): Property 'groupId' does not exist on type '{}'.
    groupId: queryParams.groupId,
  });
  const linkTo = useLinkTo();

  const onCompleteTaskPress = (shouldNotify: any, comment: any) => {
    const sendingData = {
      ...queryParams,
      body: {
        task: {
          assigned_user_id: queryResult?.data?.assigned_user?.id,
          content: queryResult?.data?.content,
          deadline: queryResult?.data?.deadline,
          status: 'closed',
        },
      },
    };

    // @ts-expect-error TS(2345): Argument of type '{ body: { task: { assigned_user_... Remove this comment to see the full error message
    updateTask(sendingData)
      .then((result) => {
        toast.showWithShare('タスクを完了しました', () => {
          share(
            // @ts-expect-error TS(2339): Property 'farmId' does not exist on type '{}'.
            `タスクを完了しました\n${U_FEED_URL_BASE}/farms/${queryParams.farmId}/groups/${queryParams.groupId}/tasks/${queryParams.id}`
          );
        });
        navigation.goBack();
      })
      .catch((error) => console.error('task complete failed: ', error));
  };

  const canEdit =
    userQueryResult.data?.id === queryResult.data?.user?.id ||
    userQueryResult.data?.id === queryResult.data?.assigned_user?.id;
  const canDelete = userQueryResult.data?.id === queryResult.data?.user?.id;

  return (
    <BaseScreenBuilder>
      <VStack space={4}>
        <DetailsBuilder
          onEditPress={
            canEdit
              ? () =>
                  Platform.OS === 'web'
                    ? navigation.getState().routeNames[0] === 'TaskSummary'
                      ? linkTo(
                          // @ts-expect-error TS(2339): Property 'farmId' does not exist on type '{}'.
                          `/farms/${queryParams.farmId}/groups/${queryParams.groupId}/tasks/${queryParams?.id}/create_edit/mytask`
                        )
                      : linkTo(
                          // @ts-expect-error TS(2339): Property 'farmId' does not exist on type '{}'.
                          `/farms/${queryParams.farmId}/groups/${queryParams.groupId}/tasks/${queryParams.id}/create_edit`
                        )
                    : // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                      navigation.navigate('TaskCreateEdit', queryParams)
              : undefined
          }
          onSharePress={async () => {
            const channel = chatClient?.channel('team', chatChannelId);
            // @ts-expect-error TS(2339): Property 'farmId' does not exist on type '{}'.
            const message = `${U_FEED_URL_BASE}/farms/${queryParams?.farmId}/groups/${queryParams?.groupId}/tasks/${queryParams?.id}`;

            if (Platform.OS === 'web') {
              linkTo(`/chat/${channel?.id}?message=${encodeURIComponent(message)}`);
            } else {
              // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
              navigation.navigate('ChatChannelMessages', { channelId: channel.id, message });
            }
          }}
          // @ts-expect-error TS(2322): Type '(() => MutationActionCreatorResult<MutationD... Remove this comment to see the full error message
          onDeletePress={
            canDelete
              ? () => {
                  return deleteTask({
                    // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
                    id: queryResult.data?.id,
                  });
                }
              : undefined
          }
          // @ts-expect-error TS(2322): Type 'UseQueryHookResult<QueryDefinition<GetTasksB... Remove this comment to see the full error message
          queryResult={queryResult}
          emptyMessage="タスクデータがありません"
          fields={[
            {
              key: 'content',
              label: '内容',
            },
            {
              key: 'status',
              label: 'ステータス',
              type: 'key-value',
              // @ts-expect-error TS(2322): Type '{ key: string; label: string; type: string; ... Remove this comment to see the full error message
              options: {
                open: '未着手',
                'in progress': '作業中',
                closed: '完了',
              },
            },
            {
              key: 'deadline',
              type: 'date',
              label: '締め切り',
            },
            {
              key: 'user',
              label: '作成者',
              type: 'user',
            },
            {
              key: 'assigned_user',
              label: '担当者',
              type: 'user',
            },
          ]}
        />
        {canEdit && queryResult.data?.status !== 'closed' ? (
          <>
            <Divider />
            <ConfirmWithComment onConfirmed={onCompleteTaskPress} />
          </>
        ) : null}
        {/* @ts-expect-error TS(2339): Property 'errorMessage' does not exist on type 'Fe... Remove this comment to see the full error message */}
        {updateTaskResult?.isError ? <AlertBox type="error" message={updateTaskResult.error.errorMessage} /> : null}
      </VStack>
    </BaseScreenBuilder>
  );
};
