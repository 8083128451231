import { Box, Center, Pressable } from '@gluestack-ui/themed-native-base';
import { Portal } from '@gorhom/portal';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { FrequentlyUsed } from 'emoji-mart';
import { useState, useCallback, type ReactElement, useEffect } from 'react';
import { useMessageContext } from 'stream-chat-react';

import { overlayReactionSelector, toggleShowEmojiModal } from '~/slices/overlayReactionSlice';
import { useAppDispatch, useAppSelector } from '~/store';
import CustomEmojiPicker from './CustomEmojiPicker.web';

const ChatCustomEmojiPickerWeb = (): ReactElement | null => {
  const { targetMessageId, showEmojiModal } = useAppSelector(overlayReactionSelector);
  const dispatch = useAppDispatch();
  const { message, handleReaction } = useMessageContext();
  const [frequentlyUsedEmojiList, setFrequentlyUsedEmojiList] = useState<string[]>(FrequentlyUsed.DEFAULTS.slice(0, 5));

  const closeEmojiModal = useCallback(() => {
    dispatch(toggleShowEmojiModal());
  }, []);

  useEffect(() => {
    const init = async () => {
      const frequentlyUsedEmojisString = (await AsyncStorage.getItem('@frequently_used_emojis_web')) || '[]';
      const frequentlyUsedEmojis = JSON.parse(frequentlyUsedEmojisString) as string[];

      setFrequentlyUsedEmojiList((val) => [...frequentlyUsedEmojis, ...val].slice(0, 5));
    };

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (message?.id !== targetMessageId || !showEmojiModal) {
    return null;
  }

  return (
    <Portal hostName="EmojiPortalHost">
      <Center position="absolute" top={0} right={0} bottom={0} left={0} zIndex={3}>
        <Pressable
          onPress={closeEmojiModal}
          position="absolute"
          top={0}
          right={0}
          bottom={0}
          left={0}
          backgroundColor="rgba(0,0,0,.8)"
        />
        <Box position="relative" zIndex={2}>
          <CustomEmojiPicker
            onEmojiSelect={async (emoji, e) => {
              handleReaction(emoji.id, e);
              const frequentlyUsedEmojis = Array.from(new Set([emoji.id, ...frequentlyUsedEmojiList])).slice(0, 5);
              await AsyncStorage.setItem('@frequently_used_emojis_web', JSON.stringify(frequentlyUsedEmojis));
              closeEmojiModal();
            }}
          />
        </Box>
      </Center>
    </Portal>
  );
};

export default ChatCustomEmojiPickerWeb;
