import data from '@emoji-mart/data';
import i18n from '@emoji-mart/data/i18n/ja.json';
import Picker from '@emoji-mart/react';
import { FC, BaseSyntheticEvent } from 'react';
import { customWebEmojis } from '~/utils/emoji';

type EmojiData = {
  id: string;
  keywords: string[];
  name: string;
  native: string;
  shortcodes: string;
  unified: string;
};

type Props = {
  onEmojiSelect:
    | ((emoji: EmojiData) => void)
    | ((emoji: EmojiData, e: BaseSyntheticEvent<object, any, any>) => Promise<void>);
  disabledCustomEmojis?: boolean;
};

const CustomEmojiPicker: FC<Props> = ({ onEmojiSelect, disabledCustomEmojis }) => (
  <Picker
    data={data}
    theme="light"
    onEmojiSelect={onEmojiSelect}
    i18n={i18n}
    custom={disabledCustomEmojis ? [] : customWebEmojis}
    exceptEmojis={disabledCustomEmojis ? customWebEmojis[0].emojis.map((emoji) => emoji.id) : []}
    autoFocus
  />
);

export default CustomEmojiPicker;
