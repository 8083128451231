import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  email: yup
    .string()
    .label('Email')
    .email('正しいメールアドレスを入力してください')
    .required('メールアドレスを入力してください'),
  password: yup.string().label('Password').required('パスワードを入力してください'),
  remember: yup.boolean(),
});
