import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { Platform } from 'react-native';

import { renderHeaderLeft } from './index';

import { ScreenWidthAdjuster } from '~/components';
import { PrivacyPolicyScreen } from '~/screens/PrivacyPolicyScreen';
import { TermsOfUseScreen } from '~/screens/TermsOfUseScreen';

import { AppHeader } from './AppHeader';
import { commonModalScreens, commonScreens } from './BottomTabNavigator';

const Stack = createNativeStackNavigator();

export const MenuNavigator = () => {
  return (
    <>
      <AppHeader />
      <ScreenWidthAdjuster>
        <Stack.Navigator>
          <Stack.Group
            // @ts-expect-error TS(2322): Type '({ navigation }: { route: RouteProp<ParamLis... Remove this comment to see the full error message
            screenOptions={({ navigation }) => ({
              headerBackTitle: '戻る',
              headerTitleStyle: {
                fontWeight: 'bold',
              },
              headerStyle:
                Platform.OS === 'web'
                  ? {
                      borderBottomWidth: 0,
                    }
                  : {},
              headerLeft: () => {
                return renderHeaderLeft(navigation);
              },
            })}
          >
            {commonScreens}
            {commonModalScreens}
            <Stack.Screen
              name="PrivacyPolicy"
              component={PrivacyPolicyScreen}
              options={{ title: 'プライバシーポリシー' }}
            />
            <Stack.Screen name="TermsOfUse" component={TermsOfUseScreen} options={{ title: '利用規約' }} />
          </Stack.Group>
        </Stack.Navigator>
      </ScreenWidthAdjuster>
    </>
  );
};
