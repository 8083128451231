import debounce from 'lodash/debounce';
import { DraftType } from '~/types';

const STORAGE_KEY = '@chat/drafts-web';

export const getDrafts = (): Record<string, string> => {
  try {
    return JSON.parse(localStorage.getItem(STORAGE_KEY) || '{}');
  } catch (e) {
    console.error(e);
    return {};
  }
};

export const getDraftById = (id: string): DraftType | null => {
  const drafts = getDrafts();
  if (!(id in drafts)) return null;
  const draftText = drafts[id];
  try {
    return JSON.parse(draftText);
  } catch (e) {
    // in case draftText is not a valid JSON (raw text).
    return {
      version: 'v1',
      text: draftText,
      mentioned_users: [],
    };
  }
};

export const removeDraft = debounce((key: string) => {
  const drafts = getDrafts();

  if (drafts[key]) {
    delete drafts[key];
    try {
      localStorage.setItem(STORAGE_KEY, JSON.stringify(drafts));
    } catch (e) {
      console.error(e);
    }
  }
}, 1000);

export const updateDraft = debounce((key: string, value: DraftType) => {
  const drafts = getDrafts();

  if (!value) {
    delete drafts[key];
  } else {
    drafts[key] = JSON.stringify(value);
  }

  try {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(drafts));
  } catch (e) {
    console.error(e);
  }
}, 1000);
