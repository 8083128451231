import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { HStack, Text, Pressable, VStack } from '@gluestack-ui/themed-native-base';
import { useLinkTo, useNavigation } from '@react-navigation/native';
import { Toast } from 'native-base';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  useDeletePushMuteConfigsByIdMutation,
  useGetPushMuteConfigsExistQuery,
  usePostPushMuteConfigsMutation,
} from '~/api/uFeedApi';
import { gluestackUIConfig } from '~/config/gluestack-ui.config';
import { ShowPinMessagesContext, ShowPinMessagesDispatchContext } from '~/contexts/PinMessageContext';
import { useStreamChatContext } from '~/contexts/StreamChatContext';
import { useChannelMembers } from '~/hooks';
import { useChannelStateContext, DefaultStreamChatGenerics } from '~/lib/StreamChatReact';
import type { Channel } from 'stream-chat';

const UnMemoizedCustomHeader: React.FC<{
  handleOpenInfo?: (() => void) | undefined;
  channelSelected?: Channel<DefaultStreamChatGenerics>;
  multiSection?: boolean;
  onClose?: (() => void) | undefined;
}> = ({ handleOpenInfo, multiSection, channelSelected, onClose }) => {
  const { thread } = useChannelStateContext();

  if (thread) {
    return null;
  }
  const navigation = useNavigation();
  const { chatClient, appChannel } = useStreamChatContext();
  const channel = appChannel as Channel<DefaultStreamChatGenerics>;
  const linkTo = useLinkTo();

  const [mute, setMute] = useState(false);
  const pushMuteConfigQuery = useGetPushMuteConfigsExistQuery({ targetId: channel?.cid, targetType: 'channel' });
  const [postPushMuteConfig] = usePostPushMuteConfigsMutation();
  const [deletePushMuteConfig] = useDeletePushMuteConfigsByIdMutation();
  const { allMembers } = useChannelMembers(multiSection ? channelSelected?.id || '' : channel?.id || '');

  const dispatch = useContext(ShowPinMessagesDispatchContext);
  const showPinMessages = useContext(ShowPinMessagesContext);

  useEffect(() => {
    if (thread) {
      handleOpenInfo;
    }
  }, [thread]);

  const switchPinMessage = useCallback(() => {
    dispatch && dispatch({ type: 'switch' });
  }, [dispatch]);

  const handleSearchPress = useCallback(() => {
    const channelId = channel?.id;

    linkTo(`/search?id=${channelId}`);
  }, [channel]);

  const handleMemberlistPress = useCallback(() => {
    const index = (channel?.cid?.indexOf(':') || 0) + 1;
    const channelId = channel?.cid?.substring(index);
    navigation.getState().routeNames[0] === 'DirectMessageList'
      ? linkTo(`/dm/memberlist/${channelId}`)
      : linkTo(`/chat/memberlist/${channelId}`);
  }, [navigation]);

  const handleMutePress = useCallback(() => {
    if (mute) {
      const muteConfig = pushMuteConfigQuery.currentData;
      if (!muteConfig) {
        return;
      }
      deletePushMuteConfig({ id: muteConfig.id });
      Toast.show({
        description: 'プッシュ通知のミュートを解除しました',
        placement: 'top',
      });
    } else {
      postPushMuteConfig({ body: { push_mute_config: { target_type: 'channel', target_id: channel?.cid || '' } } });
      Toast.show({
        description: 'プッシュ通知をミュートしました',
        placement: 'top',
      });
    }
  }, [pushMuteConfigQuery, deletePushMuteConfig, postPushMuteConfig, mute]);

  useDidUpdate(
    () => {
      if (pushMuteConfigQuery.isLoading) {
        return;
      }
      const data = pushMuteConfigQuery.currentData;
      if (pushMuteConfigQuery.isSuccess && data?.id) {
        setMute(true);
      } else {
        setMute(false);
      }
    },
    [pushMuteConfigQuery],
    true
  );

  useDidUpdate(() => {
    dispatch && dispatch({ type: 'close' });
  }, [channel?.cid]);

  //トーストの問題の対応 https://github.com/GeekyAnts/NativeBase/issues/5107
  setTimeout(() => Toast.closeAll(), 5000);

  const memberIds = channel?.state?.members ? Object.keys(channel?.state?.members) : [];

  return (
    <VStack
      position="absolute"
      top="0"
      width="100%"
      px="md"
      py="sm"
      backgroundColor="surfaceBrightest"
      borderBottomWidth={gluestackUIConfig.tokens.borderWidths.medium}
      borderRightWidth={gluestackUIConfig.tokens.borderWidths.medium}
      borderColor="outlineVariant"
      style={{
        zIndex: 1000,
      }}
    >
      <HStack alignItems="center">
        {multiSection ? (
          <VStack>
            <Text fontWeight="bold" fontSize="lg" color="onSurface">
              {!channelSelected?.data?.isDM || memberIds.length <= 1
                ? channelSelected?.data?.name
                : memberIds
                    .filter((key) => key !== chatClient.userID || memberIds.length === 1)
                    .map((key) => channelSelected?.state?.members[key]?.user?.name)
                    .join(', ')}
            </Text>
            <Text fontSize="sm" fontWeight="regular" color="onSurface">
              {allMembers?.length} 名のメンバー
            </Text>
          </VStack>
        ) : (
          <VStack>
            <Text fontWeight="bold" fontSize="lg" color="onSurface">
              {!channel?.data?.isDM || memberIds.length <= 1
                ? channel?.data?.name
                : memberIds
                    .filter((key) => key !== chatClient.userID || memberIds.length === 1)
                    .map((key) => channel?.state?.members[key]?.user?.name)
                    .join(', ')}
            </Text>
            <Text fontSize="sm" fontWeight="regular" color="onSurface">
              {allMembers?.length} 名のメンバー
            </Text>
          </VStack>
        )}
        <HStack marginRight={0} marginLeft="auto" space={10}>
          {multiSection ? (
            <Pressable onPress={onClose}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M12.0008 13.3998L7.10078 18.2998C6.91745 18.4831 6.68411 18.5748 6.40078 18.5748C6.11745 18.5748 5.88411 18.4831 5.70078 18.2998C5.51745 18.1165 5.42578 17.8831 5.42578 17.5998C5.42578 17.3165 5.51745 17.0831 5.70078 16.8998L10.6008 11.9998L5.70078 7.0998C5.51745 6.91647 5.42578 6.68314 5.42578 6.3998C5.42578 6.11647 5.51745 5.88314 5.70078 5.6998C5.88411 5.51647 6.11745 5.4248 6.40078 5.4248C6.68411 5.4248 6.91745 5.51647 7.10078 5.6998L12.0008 10.5998L16.9008 5.6998C17.0841 5.51647 17.3174 5.4248 17.6008 5.4248C17.8841 5.4248 18.1174 5.51647 18.3008 5.6998C18.4841 5.88314 18.5758 6.11647 18.5758 6.3998C18.5758 6.68314 18.4841 6.91647 18.3008 7.0998L13.4008 11.9998L18.3008 16.8998C18.4841 17.0831 18.5758 17.3165 18.5758 17.5998C18.5758 17.8831 18.4841 18.1165 18.3008 18.2998C18.1174 18.4831 17.8841 18.5748 17.6008 18.5748C17.3174 18.5748 17.0841 18.4831 16.9008 18.2998L12.0008 13.3998Z"
                  fill={gluestackUIConfig.tokens.colors.onSurfaceBright}
                />
              </svg>
            </Pressable>
          ) : (
            <Pressable onPress={handleOpenInfo}>
              <HStack alignItems="center" space={1}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <mask id="mask0_1516_744" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                    <rect width="24" height="24" fill={gluestackUIConfig.tokens.colors.outline} />
                  </mask>
                  <g mask="url(#mask0_1516_744)">
                    <path
                      d="M12 17C12.2833 17 12.5208 16.9042 12.7125 16.7125C12.9042 16.5208 13 16.2833 13 16V12C13 11.7167 12.9042 11.4792 12.7125 11.2875C12.5208 11.0958 12.2833 11 12 11C11.7167 11 11.4792 11.0958 11.2875 11.2875C11.0958 11.4792 11 11.7167 11 12V16C11 16.2833 11.0958 16.5208 11.2875 16.7125C11.4792 16.9042 11.7167 17 12 17ZM12 9C12.2833 9 12.5208 8.90417 12.7125 8.7125C12.9042 8.52083 13 8.28333 13 8C13 7.71667 12.9042 7.47917 12.7125 7.2875C12.5208 7.09583 12.2833 7 12 7C11.7167 7 11.4792 7.09583 11.2875 7.2875C11.0958 7.47917 11 7.71667 11 8C11 8.28333 11.0958 8.52083 11.2875 8.7125C11.4792 8.90417 11.7167 9 12 9ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22Z"
                      fill={gluestackUIConfig.tokens.colors.onSurfaceBright}
                    />
                  </g>
                </svg>
              </HStack>
            </Pressable>
          )}
        </HStack>
      </HStack>
    </VStack>
  );
};

export const CustomHeader = React.memo(UnMemoizedCustomHeader);
