import { Avatar, HStack, Pressable, ScrollView, Spinner, Stack, Text, VStack } from '@gluestack-ui/themed-native-base';
import { SearchIndex } from 'emoji-mart';
import normalizeUrl from 'normalize-url';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Attachment,
  Channel,
  CustomMessageActionsListProps,
  MessageInput,
  MessageList,
  renderText,
  ScrollToBottomButton,
  Thread,
  Window,
} from 'stream-chat-react';
import { channelType, getStreamChatChannelTypeById } from '../ChatChannelMessagesScreen/channelType';
import { ChatCustomThreadInput, CustomMessageAvatar } from '~/components';
import { ChatCustomMessage } from '~/components/ChatCustomMessage';
import { ChatCustomPinIndicator } from '~/components/ChatCustomPinIndicator';
import ChatCustomReactionSelectorWeb from '~/components/ChatCustomReactionSelectorWeb.web';
import ChatCustomReactionsListWeb from '~/components/ChatCustomReactionsListWeb';
import { anchorComponent } from '~/components/CustomAnchorComponent.web';
import { CustomSuggestionItem } from '~/components/CustomAutoCompleteTextArea/CustomSuggestionItem.web';
import { CustomSuggestionList } from '~/components/CustomAutoCompleteTextArea/CustomSuggestionList.web';
import { CustomDateSeparator } from '~/components/CustomDateSeparator';

import { CustomHeader } from '~/components/CustomHeader.web';
import { CustomMessageActionsListWeb } from '~/components/CustomMessageActionsListWeb';
import CustomMessageOptions from '~/components/CustomMessageOptions.web';
import { CustomMessageTimestamp } from '~/components/CustomMessageTimestamp.web';
import { gluestackUIConfig } from '~/config/gluestack-ui.config';
import { useStreamChatAuthContext } from '~/contexts/StreamChatContext';
import { usePinnedChannels } from '~/hooks';
import { useDraft } from '~/hooks/useDraft.web';
import { useShouldSutmit } from '~/hooks/useShouldSutmit';
import { useAllChannel } from './index.web';
import type { Channel as StreamChannel, UserResponse } from 'stream-chat';
import type { AttachmentProps } from 'stream-chat-react';

const ChannelWindow = (props: { width: number }) => {
  const { width } = props;
  const { overrideSubmitHandler, isCheckedRef, toggleCheckedRef } = useDraft();
  const { shouldSubmit } = useShouldSutmit();

  const customRenderText = useCallback(
    (text?: string, mentionedUsers?: UserResponse[]) => {
      return renderText(text, mentionedUsers, {
        customMarkDownRenderers: { a: anchorComponent },
      });
    },
    [anchorComponent]
  );

  const CustomThreadInputWrapper = useCallback(
    () => <ChatCustomThreadInput isChecked={isCheckedRef.current} toggleChecked={toggleCheckedRef} isThread />,
    [isCheckedRef, toggleCheckedRef]
  );

  return (
    <HStack width={width}>
      <Window hideOnThread>
        <MessageList messageLimit={20} renderText={customRenderText} />
        <MessageInput
          overrideSubmitHandler={overrideSubmitHandler}
          shouldSubmit={shouldSubmit}
          grow
          mentionQueryParams={{
            options: { limit: 100 },
          }}
        />
      </Window>
      <Thread
        Input={CustomThreadInputWrapper}
        additionalMessageInputProps={{
          overrideSubmitHandler,
          shouldSubmit,
          grow: true,
          mentionQueryParams: {
            options: { limit: 100 },
          },
        }}
      />
    </HStack>
  );
};

const ChannelColumnInner = (props: { channel: StreamChannel; width: number }) => {
  const { channel, width } = props;

  const isDM = useMemo(() => channelType(channel?.cid ?? '') === 'DM', [channel]);
  const { removePinnedChannel } = usePinnedChannels();

  const CustomEditInputWrapper = useCallback(() => <ChatCustomThreadInput isEdit />, []);

  const renderAttachment = useCallback((props: AttachmentProps) => {
    // Remove duplicate attachments by title and title_link
    const uniqueAttachments = props.attachments.filter(
      (item, index, self) =>
        !item.og_scrape_url || // affects only OGAttachment
        index ===
          self.findIndex(
            (t) =>
              !!t.og_scrape_url && // comparison target must be OGAttachment
              t.title === item.title &&
              (t.title_link ? normalizeUrl(t.title_link) : '') ===
                (item.title_link ? normalizeUrl(item.title_link) : '')
          )
    );
    return <Attachment {...props} attachments={uniqueAttachments} />;
  }, []);

  const renderUnreadMessagesSeparatorEmpty = useCallback(() => {
    return null;
  }, []);

  const renderMessageActionList = useCallback(
    (props: CustomMessageActionsListProps) => {
      return <CustomMessageActionsListWeb {...props} isDM={isDM} />;
    },
    [isDM]
  );

  return (
    <HStack flex={1} width="100%">
      <Channel
        channel={channel}
        channelQueryOptions={{
          messages: {
            limit: 20,
          },
        }}
        Avatar={CustomMessageAvatar}
        EditMessageInput={CustomEditInputWrapper}
        // @ts-expect-error TS(2322): Type 'ForwardRefExoticComponent<Omit<any, "ref"> &... Remove this comment to see the full error message
        ReactionSelector={ChatCustomReactionSelectorWeb}
        ReactionsList={ChatCustomReactionsListWeb}
        // @ts-expect-error TS(2322): Type '(props: Props) => React.JSX.Element' is not ... Remove this comment to see the full error message
        AutocompleteSuggestionList={CustomSuggestionList}
        // @ts-expect-error TS(2322): Type 'ForwardRefExoticComponent<CustomSuggestionIt... Remove this comment to see the full error message
        AutocompleteSuggestionItem={CustomSuggestionItem}
        Message={ChatCustomMessage}
        MessageNotification={ScrollToBottomButton}
        CustomMessageActionsList={renderMessageActionList}
        MessageTimestamp={CustomMessageTimestamp}
        MessageOptions={CustomMessageOptions}
        Input={ChatCustomThreadInput}
        Attachment={renderAttachment}
        HeaderComponent={() => (
          <CustomHeader channelSelected={channel} multiSection onClose={() => removePinnedChannel(channel.cid)} />
        )}
        emojiSearchIndex={SearchIndex}
        DateSeparator={CustomDateSeparator}
        UnreadMessagesSeparator={channel?.countUnread() == 0 ? renderUnreadMessagesSeparatorEmpty : undefined}
        PinIndicator={ChatCustomPinIndicator}
      >
        <ChannelWindow width={width} />
      </Channel>
    </HStack>
  );
};

type Props = {
  width: number;
  channel?: StreamChannel;
};

export const ChannelColumn = ({ width: columnWidth, channel: propChannel }: Props) => {
  const [selectedChannel, setSelectedChannel] = useState<StreamChannel | undefined>(propChannel);

  const { allChannels } = useAllChannel();
  const { pinnedChannels } = usePinnedChannels();

  useEffect(() => {
    setSelectedChannel(propChannel);
  }, [propChannel, pinnedChannels]);

  if (allChannels?.length < 1) {
    return <Spinner />;
  }
  return (
    <HStack width={columnWidth} height="100%" flexGrow={0}>
      {selectedChannel && (
        <VStack height="100%">
          <ChannelColumnInner channel={selectedChannel} width={columnWidth} />
        </VStack>
      )}
    </HStack>
  );
};

export const AddChannelColumn = () => {
  const [isOpenChoose, setIsOpenChoose] = useState<boolean>(false);

  const { allChannels } = useAllChannel();
  const { addPinnedChannel } = usePinnedChannels();

  return (
    <HStack height="100%" flexGrow={0}>
      {!isOpenChoose ? (
        <VStack justifyContent="center" height="100%" backgroundColor="surface">
          <Pressable onPress={() => setIsOpenChoose(!isOpenChoose)}>
            <HStack px="sm" gap="2xs" alignItems="center">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <mask id="mask0_1648_8018" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
                  <rect width="20" height="20" fill={gluestackUIConfig.tokens.colors.mono400} />
                </mask>
                <g mask="url(#mask0_1648_8018)">
                  <path
                    d="M15.8327 12.5C15.5966 12.5 15.3987 12.4201 15.2389 12.2604C15.0792 12.1007 14.9993 11.9028 14.9993 11.6667V10.8333H14.166C13.9299 10.8333 13.732 10.7535 13.5723 10.5938C13.4125 10.434 13.3327 10.2361 13.3327 10C13.3327 9.76389 13.4125 9.56597 13.5723 9.40625C13.732 9.24653 13.9299 9.16667 14.166 9.16667H14.9993V8.33333C14.9993 8.09722 15.0792 7.89931 15.2389 7.73958C15.3987 7.57986 15.5966 7.5 15.8327 7.5C16.0688 7.5 16.2667 7.57986 16.4264 7.73958C16.5862 7.89931 16.666 8.09722 16.666 8.33333V9.16667H17.4993C17.7355 9.16667 17.9334 9.24653 18.0931 9.40625C18.2528 9.56597 18.3327 9.76389 18.3327 10C18.3327 10.2361 18.2528 10.434 18.0931 10.5938C17.9334 10.7535 17.7355 10.8333 17.4993 10.8333H16.666V11.6667C16.666 11.9028 16.5862 12.1007 16.4264 12.2604C16.2667 12.4201 16.0688 12.5 15.8327 12.5ZM3.33268 17.5C2.87435 17.5 2.48199 17.3368 2.1556 17.0104C1.82921 16.684 1.66602 16.2917 1.66602 15.8333V4.16667C1.66602 3.70833 1.82921 3.31597 2.1556 2.98958C2.48199 2.66319 2.87435 2.5 3.33268 2.5H8.33268V17.5H3.33268ZM9.99935 17.5V2.5H14.9993C15.4577 2.5 15.85 2.66319 16.1764 2.98958C16.5028 3.31597 16.666 3.70833 16.666 4.16667V5.08333C16.666 5.30556 16.5827 5.48958 16.416 5.63542C16.2494 5.78125 16.0549 5.84722 15.8327 5.83333C14.6799 5.83333 13.6973 6.23611 12.8848 7.04167C12.0723 7.84722 11.666 8.83333 11.666 10C11.666 11.1667 12.0723 12.1528 12.8848 12.9583C13.6973 13.7639 14.6799 14.1667 15.8327 14.1667C16.0549 14.1667 16.2494 14.2361 16.416 14.375C16.5827 14.5139 16.666 14.6944 16.666 14.9167V15.8333C16.666 16.2917 16.5028 16.684 16.1764 17.0104C15.85 17.3368 15.4577 17.5 14.9993 17.5H9.99935Z"
                    fill={gluestackUIConfig.tokens.colors.primary}
                  />
                </g>
              </svg>
              <Text fontSize="md" fontWeight="bold" color="primary">
                列を追加
              </Text>
            </HStack>
          </Pressable>
        </VStack>
      ) : (
        <VStack height="100%" backgroundColor="surfaceBrightest">
          <HStack
            px="md"
            py="xl"
            alignItems="center"
            justifyContent="space-between"
            borderBottomWidth={gluestackUIConfig.tokens.borderWidths.medium}
            borderColor="outlineVariant"
          >
            <Text fontSize="xl" fontWeight="bold" color="onSurface">
              列を追加
            </Text>
            <Pressable onPress={() => setIsOpenChoose(!isOpenChoose)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M12.0008 13.3998L7.10078 18.2998C6.91745 18.4831 6.68411 18.5748 6.40078 18.5748C6.11745 18.5748 5.88411 18.4831 5.70078 18.2998C5.51745 18.1165 5.42578 17.8831 5.42578 17.5998C5.42578 17.3165 5.51745 17.0831 5.70078 16.8998L10.6008 11.9998L5.70078 7.0998C5.51745 6.91647 5.42578 6.68314 5.42578 6.3998C5.42578 6.11647 5.51745 5.88314 5.70078 5.6998C5.88411 5.51647 6.11745 5.4248 6.40078 5.4248C6.68411 5.4248 6.91745 5.51647 7.10078 5.6998L12.0008 10.5998L16.9008 5.6998C17.0841 5.51647 17.3174 5.4248 17.6008 5.4248C17.8841 5.4248 18.1174 5.51647 18.3008 5.6998C18.4841 5.88314 18.5758 6.11647 18.5758 6.3998C18.5758 6.68314 18.4841 6.91647 18.3008 7.0998L13.4008 11.9998L18.3008 16.8998C18.4841 17.0831 18.5758 17.3165 18.5758 17.5998C18.5758 17.8831 18.4841 18.1165 18.3008 18.2998C18.1174 18.4831 17.8841 18.5748 17.6008 18.5748C17.3174 18.5748 17.0841 18.4831 16.9008 18.2998L12.0008 13.3998Z"
                  fill={gluestackUIConfig.tokens.colors.mono700}
                />
              </svg>
            </Pressable>
          </HStack>
          <ScrollView flex="1">
            <VStack p="md">
              <Stack py="2xs">
                <Text fontSize="md" fontWeight="regular" color="onSurface">
                  1つ選択してください
                </Text>
              </Stack>
              {allChannels.map((channel) => (
                <Pressable
                  onPress={() => {
                    addPinnedChannel(channel.cid);
                    setIsOpenChoose(!isOpenChoose);
                  }}
                  key={channel.cid}
                >
                  <HStack py="xs" gap="xs" alignItems="center">
                    <Avatar
                      // @ts-expect-error TS(2239)
                      source={{ uri: channel?.data?.created_by?.image }}
                      height="30"
                      width="30"
                      backgroundColor="onSurfaceBrightest"
                    />
                    <HStack gap="2xs" alignItems="center">
                      {!channel?.data?.isDM && (
                        <Stack>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M3.33398 15V7.50004C3.33398 7.23615 3.39301 6.98615 3.51107 6.75004C3.62912 6.51393 3.79232 6.31949 4.00065 6.16671L9.00065 2.41671C9.29232 2.19449 9.62565 2.08337 10.0007 2.08337C10.3757 2.08337 10.709 2.19449 11.0007 2.41671L16.0007 6.16671C16.209 6.31949 16.3722 6.51393 16.4902 6.75004C16.6083 6.98615 16.6673 7.23615 16.6673 7.50004V15C16.6673 15.4584 16.5041 15.8507 16.1777 16.1771C15.8513 16.5035 15.459 16.6667 15.0007 16.6667H12.5007C12.2645 16.6667 12.0666 16.5868 11.9069 16.4271C11.7472 16.2674 11.6673 16.0695 11.6673 15.8334V11.6667C11.6673 11.4306 11.5875 11.2327 11.4277 11.073C11.268 10.9132 11.0701 10.8334 10.834 10.8334H9.16732C8.93121 10.8334 8.73329 10.9132 8.57357 11.073C8.41385 11.2327 8.33398 11.4306 8.33398 11.6667V15.8334C8.33398 16.0695 8.25412 16.2674 8.0944 16.4271C7.93468 16.5868 7.73676 16.6667 7.50065 16.6667H5.00065C4.54232 16.6667 4.14996 16.5035 3.82357 16.1771C3.49718 15.8507 3.33398 15.4584 3.33398 15Z"
                              fill={gluestackUIConfig.tokens.colors.mono700}
                            />
                          </svg>
                        </Stack>
                      )}

                      <Text fontSize="md" fontWeight="bold" color="onSurface">
                        {channel.data?.name}
                      </Text>
                    </HStack>
                  </HStack>
                </Pressable>
              ))}
            </VStack>
          </ScrollView>
        </VStack>
      )}
    </HStack>
  );
};

export const ChannelColumnWithId = ({ width, channelId }: { width: number; channelId: string }) => {
  const { chatClient } = useStreamChatAuthContext();
  const [channel, setChannel] = useState<StreamChannel | undefined>();

  useEffect(() => {
    const initChannel = async (id: string) => {
      const channelId = id?.split(':').slice(-1).join('');
      const channel = chatClient?.channel(getStreamChatChannelTypeById(id), channelId);

      if (!channel?.initialized) {
        await channel.watch();
      }

      setChannel(channel);
    };

    if (initChannel) {
      initChannel(channelId);
    }
  }, [channelId]);

  return (
    <HStack>
      <ChannelColumn width={width} channel={channel} />
    </HStack>
  );
};
