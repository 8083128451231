import { Ionicons } from '@expo/vector-icons';
import { ScrollView, FormControl, VStack, Input, Icon, Box } from '@gluestack-ui/themed-native-base';
import { useNavigation } from '@react-navigation/native';
import { Logic } from 'native-base-form-builder';
import { useController } from 'react-hook-form';
import { TouchableOpacity } from 'react-native';

import { FormFieldLabel } from './FormFieldLabel';

export const FormSelect: React.FC<React.ComponentProps<typeof Logic>> = ({
  name,
  rules,
  shouldUnregister,
  control,
  label,
  options,
}) => {
  const { field, formState } = useController({
    name,
    rules,
    shouldUnregister,
    control,
  });

  const isRequired = !!rules?.required;
  const errorMessage = formState.errors?.[field.name]?.message;

  const navigation = useNavigation();

  const openSelectScreen = () => {
    // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    navigation.navigate('Select', {
      data: options,
      value: field.value,
      onConfirm: (result: any) => {
        field.onChange(result);
      },
    });
  };
  return (
    <FormControl isRequired={isRequired} isInvalid={errorMessage}>
      <VStack space={2} flex={1}>
        <FormFieldLabel label={label} isRequired={isRequired} />
        <TouchableOpacity onPress={openSelectScreen}>
          <Input
            width="100%"
            size="lg"
            variant="outline"
            borderColor="black"
            py={3}
            px={3}
            isReadOnly
            value={options?.find((option) => option.value == field.value)?.label}
            onPressIn={openSelectScreen}
            InputRightElement={
              <Box padding={2}>
                <Icon as={Ionicons} name="chevron-down-outline" />
              </Box>
            }
          />
        </TouchableOpacity>
        {errorMessage && (
          <ScrollView horizontal showsHorizontalScrollIndicator={false} bounces={false}>
            <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
          </ScrollView>
        )}
      </VStack>
    </FormControl>
  );
};
