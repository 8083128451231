import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { useRoute, useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { useState } from 'react';

import { useGetCurrentUserQuery, usePutUsersByIdMutation } from '~/api/uFeedApi';
import { BaseFormBuilder, errorMessageBuilder } from '~/components/builder';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';
import { ScreenWidthModal } from '~/components/ScreenWithModal';

export const UserSettingsPasswordEditScreen: React.FC = () => {
  const { params } = useRoute();
  const navigation = useNavigation();
  const queryParams = params ? { ...params } : {};
  const queryResult = useGetCurrentUserQuery(queryParams);
  const [update, { isLoading: isUpdating, data }] = usePutUsersByIdMutation();
  const [errorMessages, setErrorMessages] = useState([]);
  const [id, setId] = useState(undefined);

  const onSubmit = (sendingData: any) => {
    setErrorMessages([]);
    const { password } = sendingData;
    update({
      // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'number... Remove this comment to see the full error message
      id,
      body: {
        user: {
          password,
        },
      },
    })
      .unwrap()
      .then((resp) => {
        navigation.goBack();
      })
      .catch((error) => {
        setErrorMessages(errorMessageBuilder(error, fields));
        console.error('update error: ', error);
      });
  };

  const fields = [
    {
      key: 'password',
      label: 'パスワード',
      type: 'password',
      rules: {
        required: {
          value: true,
          message: 'パスワードを入力してください',
        },
        minLength: {
          value: 8,
          message: '8文字以上入力してください',
        },
        pattern: {
          value: /^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/,
          message: 'パスワードは半角英数・記号のみで入力してください',
        },
      },
    },
  ];

  useDidUpdate(
    () => {
      if (queryResult.data?.id) {
        // @ts-expect-error TS(2345): Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
        setId(queryResult.data?.id);
      }
    },
    [queryResult.data],
    true
  );

  return (
    <ScreenWidthModal title={queryResult.data?.name}>
      <BaseScreenBuilder title={queryResult.data?.name}>
        <BaseFormBuilder
          // @ts-expect-error TS(2322): Type 'UseQueryHookResult<QueryDefinition<GetCurren... Remove this comment to see the full error message
          queryResult={queryResult}
          updateResult={{ isUpdating, data }}
          emptyMessage="ユーザーデータがありません"
          onSubmit={onSubmit}
          errorMessage={errorMessages}
          onCancel={() => navigation.goBack()}
          // @ts-expect-error TS(2322): Type '{ key: string; label: string; type: string; ... Remove this comment to see the full error message
          fields={fields}
        />
      </BaseScreenBuilder>
    </ScreenWidthModal>
  );
};
