import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '~/store';

export type Avatar = {
  userId: number;
  chatUserId: string;
  avatar: string;
};
export type Avatars = Avatar[];

export type AvatarsCache = {
  avatars: Avatars;
  avatarsByUserId: {
    [userId: number]: Avatar;
  };
  avatarsByChatUserId: {
    [chatUserId: string]: Avatar;
  };
  updatedAt: number;
};

const slice = createSlice({
  name: 'avatarsCache',
  initialState: {
    avatars: [] as Avatars,
    updatedAt: 0,
    avatarsByChatUserId: {},
    avatarsByUserId: {},
  } as AvatarsCache,
  reducers: {
    setAvatars: (state, { payload }: PayloadAction<Avatars>) => {
      state.avatars = payload;
      state.updatedAt = Date.now();
      state.avatarsByUserId = payload.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.userId]: cur,
        }),
        {}
      );
      state.avatarsByChatUserId = payload.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.chatUserId]: cur,
        }),
        {}
      );
    },
  },
});

export const { setAvatars } = slice.actions;

export const avatarsCacheSelector = (state: RootState) => {
  return state.avatarsCache;
};

export default slice.reducer;
