import { useNavigation, useLinkTo } from '@react-navigation/native';
import * as React from 'react';
import { Platform } from 'react-native';

import { useGetFarmsQuery } from '~/api/uFeedApi';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';
import { ListBuilder } from '~/components/builder/ListBuilder';

export const AdminFarmListScreen: React.FC = () => {
  const navigation = useNavigation();
  const queryResult = useGetFarmsQuery();
  const linkTo = useLinkTo();

  return (
    <BaseScreenBuilder title="牧場管理">
      <ListBuilder
        // @ts-expect-error TS(2322): Type 'UseQueryHookResult<QueryDefinition<void, Bas... Remove this comment to see the full error message
        queryResult={queryResult}
        //onSelect={(item) => navigation.navigate('AdminFarmDetail', { id: item.id })}
        onSelect={(item) =>
          Platform.OS === 'web'
            ? linkTo(`/admin/farms/${item.id}`)
            : // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
              navigation.navigate('AdminFarmDetail', { id: item.id })
        }
        onAddPress={() =>
          Platform.OS === 'web'
            ? linkTo(`/admin/farms/${undefined}/create_edit`)
            : // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
              navigation.navigate('AdminFarmCreateEdit', {})
        }
        addLabel="牧場を追加"
        emptyMessage="グループがありません"
      />
    </BaseScreenBuilder>
  );
};
