import { useRoute, useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';

import { usePostGroupsByGroupIdGroupUsersMutation } from '~/api/uFeedApi';
import { BaseFormBuilder, errorMessageBuilder } from '~/components/builder/BaseFormBuilder';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';

export const AdminExternalGroupUserInviteScreen: React.FC = () => {
  const { params } = useRoute();
  const navigation = useNavigation();
  const queryParams = params ? { ...params } : {};
  const [errorMessages, setErrorMessages] = useState([]);

  const [post, postResult] = usePostGroupsByGroupIdGroupUsersMutation();

  const fields = [
    {
      key: 'role',
      type: 'select',
      label: 'ユーザー・タイプ',
      required: {
        value: true,
        message: 'ユーザー・タイプを選んでください',
      },
      options: [
        {
          label: 'パートナー',
          value: 'partner',
        },
        {
          label: 'ゲスト',
          value: 'guest',
        },
      ],
    },
    {
      key: 'email',
      type: 'email',
      label: 'メール',
      rules: {
        pattern: {
          value:
            /^([\w!#$%&'*+\-\/=?^`{|}~]+(\.[\w!#$%&'*+\-\/=?^`{|}~]+)*|"([\w!#$%&'*+\-\/=?^`{|}~. ()<>\[\]:;@,]|\\[\\"])+")@(([a-zA-Z\d\-]+\.)+[a-zA-Z]+|\[(\d{1,3}(\.\d{1,3}){3}|IPv6:[\da-fA-F]{0,4}(:[\da-fA-F]{0,4}){1,5}(:\d{1,3}(\.\d{1,3}){3}|(:[\da-fA-F]{0,4}){0,2}))\])$/,
          message: 'メールアドレスの形式に誤りがあります',
        },
      },
    },
    {
      key: 'sms_phone_number',
      type: 'text',
      label: '電話番号',
      rules: {
        pattern: {
          value: /^[0-9]+$/,
          message: '電話番号をハイフンなしの数字で入力してください',
        },
      },
    },
  ];

  const onSubmit = (sendingData: any) => {
    setErrorMessages([]);
    post({
      // @ts-expect-error TS(2339): Property 'groupId' does not exist on type '{}'.
      groupId: queryParams?.groupId,
      body: {
        group_user: {
          [sendingData?.email ? 'email' : 'sms_phone_number']: sendingData?.email
            ? sendingData?.email
            : `+81${sendingData?.sms_phone_number.replace(/^0/, '')}`,
          roles: [sendingData?.role],
        },
      },
    })
      .unwrap()
      .then((result) => navigation.goBack())
      .catch((error) => {
        setErrorMessages(errorMessageBuilder(error, fields));
        console.error('invite external user error: ', error);
      });
  };

  return (
    <BaseScreenBuilder title="社外のChumlyユーザーを招待">
      <BaseFormBuilder
        onCancel={() => navigation.goBack()}
        onSubmit={onSubmit}
        submitLabel="招待"
        message="招待するユーザーのメールアドレスか電話番号を入力してください"
        errorMessage={errorMessages}
        // @ts-expect-error TS(2322): Type '({ key: string; type: string; label: string;... Remove this comment to see the full error message
        fields={fields}
        defaultValues={{ role: 'partner' }}
      />
    </BaseScreenBuilder>
  );
};
