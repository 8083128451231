import { baseApi as api } from './uMotionBaseQuery';

export const addTagTypes = [] as const;

export interface AuthResponse {
  sessionToken: string;
  refreshToken: string;
  expiredAt: number;
  activityApiToken: string;
}

export interface AuthArg {
  uid: string;
  token: string;
  name: string;
  smsPhoneNumber: string;
  entryCode: string;
}

const mutationResponseHandler = (response: Response) => {
  if (response.ok === true) {
    return Promise.resolve(response.json());
  } else {
    return Promise.reject(`ERROR: ${response.url}`);
  }
};

const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      postSession: build.mutation<PostSessionApiResponse, PostSessionApiArg>({
        query: (body) => ({
          url: `/session`,
          method: 'POST',
          body,
          responseHandler: mutationResponseHandler,
        }),
      }),
      postResetPassword: build.mutation<PostResetPasswordApiResponse, PostResetPasswordApiArg>({
        query: (body) => ({
          url: `/session/password`,
          method: 'POST',
          body,
          responseHandler: (response: Response) => {
            if (response.ok === true) {
              return Promise.resolve(200);
            } else {
              return Promise.reject(`ERROR: ${response.url}`);
            }
          },
        }),
      }),
      signInSms: build.mutation<AuthResponse, Pick<AuthArg, 'uid' | 'token'>>({
        query: (userData) => ({
          url: '/session/sign_in_sms',
          method: 'POST',
          body: userData,
          responseHandler: mutationResponseHandler,
        }),
      }),
    }),
    overrideExisting: true,
  });

export { injectedRtkApi as uMotionApi };

export type PostSessionApiResponse = /** status 200 Successful login */ {
  sessionToken?: string;
  refreshToken?: string;
  expiredAt?: number;
};

export type PostSessionApiArg = {
  email: string;
  password: string;
};

export type PostResetPasswordApiResponse = /** status 200 Successful login */ undefined;

export type PostResetPasswordApiArg = {
  email: string;
};

export const { usePostSessionMutation, usePostResetPasswordMutation, useSignInSmsMutation } = injectedRtkApi;
