import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { useState } from 'react';

import { useGetCurrentUserTasksQuery, useGetCurrentUserQuery, Task } from '~/api/uFeedApi';
import { DateUtil } from '~/utils/DateUtils';

interface DecoratedTask extends Task {
  deadlineLabel: string;
}

export const useTasks = () => {
  const currentUser = useGetCurrentUserQuery({});
  const [isTasksOpen, setIsTasksOpen] = useState(false);
  const [groupedTasks, setGroupedTasks] = useState([]);
  const [commingTasks, setCommingTasks] = useState<DecoratedTask[]>([]);
  const [noDeadlineTasks, setNoDeadlineTasks] = useState<DecoratedTask[]>([]);
  const [groupKeys, setGroupKeys] = useState([]);

  const tasks = !currentUser.isSuccess ? useGetCurrentUserTasksQuery(skipToken) : useGetCurrentUserTasksQuery();

  const descSort = (a: any, b: any) => new Date(a.deadline).valueOf() - new Date(b.deadline).valueOf();

  useDidUpdate(
    () => {
      const decorated: DecoratedTask[] =
        tasks.data?.map((task) => ({
          ...task,
          deadlineLabel: task.deadline ? DateUtil.toJapaneseYYYYMMDDOrMMDD(new Date(task.deadline)) : '',
        })) ?? [];

      setCommingTasks(decorated.filter((task) => task.deadline).sort(descSort) ?? []);
      setNoDeadlineTasks(decorated.filter((task) => !task.deadline) ?? []);

      const needSort = decorated.reduce(
        (grouped, cur) => ({
          tasks: {
            ...grouped.tasks,
            // @ts-expect-error TS(2339): Property 'group' does not exist on type 'Decorated... Remove this comment to see the full error message
            [cur.group?.id]: [...(grouped.tasks[cur.group?.id] || []), cur],
          },
          groupNames: {
            ...grouped.groupNames,
            // @ts-expect-error TS(2339): Property 'group' does not exist on type 'Decorated... Remove this comment to see the full error message
            [cur.group?.id]: cur.group?.name,
          },
        }),
        { tasks: {}, groupNames: {} }
      ) ?? { tasks: {}, groupNames: {} };

      const flat = Object.keys(needSort.groupNames)
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        .map((key) => [needSort.groupNames[key], needSort.tasks[key]])
        .flat(2);

      // @ts-expect-error TS(2345): Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
      setGroupedTasks(flat);
    },
    [tasks.data],
    true
  );

  return {
    tasks,
    groupedTasks,
    commingTasks,
    noDeadlineTasks,
    groupKeys,
  };
};
