import { Box } from '@gluestack-ui/themed-native-base';
import { useStreamChatAuthContext } from '~/contexts/StreamChatContext';

import { ChatChannelList } from './ChatChannelList';

interface Props {
  farmId: number;
  groupId: number;
  disableVirtializedList?: boolean;
}

export const ChatChannelSummary: React.FC<Props> = ({ farmId, groupId, ...remainingProps }) => {
  const { chatClient } = useStreamChatAuthContext();
  return chatClient.userID ? (
    <Box {...remainingProps}>
      <ChatChannelList farmId={farmId} groupId={groupId} />
    </Box>
  ) : null;
};
