import { useNavigation } from '@react-navigation/native';
import * as React from 'react';

import { useGetAppointmentsQuery } from '~/api/uFeedApi';
import { AppointmentList } from '~/components/AppointmentList';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';

export const AppointmentListScreen: React.FC = () => {
  const navigation = useNavigation();
  const { data, isSuccess, isLoading, isError } = useGetAppointmentsQuery({
    type: 'booked',
  });

  return (
    <BaseScreenBuilder
      title="予約済みスケジュール"
      isError={isError}
      isEmpty={isSuccess && data?.length === 0}
      isLoading={isLoading}
      errorMessage="予約済みスケジュールを取得できませんでした"
      emptyMessage="予約済みスケジュールがありません。メニューの「利用可能なサービス」からサービスを選択して予約を申し込んでください。"
    >
      <AppointmentList slots={data || []} />
    </BaseScreenBuilder>
  );
};
