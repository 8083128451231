import { VITE_API_URL, AUTH0_CLIENT_ID, AUTH0_AUDIENCE, U_MOTION_API_URL } from '@env';
import { FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import {
  createApi,
  fetchBaseQuery,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  BaseQueryApi,
} from '@reduxjs/toolkit/query/react';
// @ts-expect-error TS(2307): Cannot find module '@u-motion-apps/packages/u-moti... Remove this comment to see the full error message
import { setUmotionToken, UmotionSessionState } from '@u-motion-apps/packages/u-motion-api/slices/uMotionSessionSlice';
import * as AuthSession from 'expo-auth-session';
import { RefreshTokenRequestConfig, TokenResponse } from 'expo-auth-session';
import { REHYDRATE } from 'redux-persist';

import { RootState } from '../store';

import { setToken, SessionState } from '~/slices/sessionSlice';

const prepareHeadersUFeed = async (session: SessionState, headers: Headers, endpoint: string) => {
  const tokenResponse = new TokenResponse(JSON.parse(session?.tokenConfigJSON || '{}'));

  if (!['createSession'].includes(endpoint) && session) {
    headers.set('Authorization', `JWT ${tokenResponse?.accessToken}`);
  }
  return headers;
};

const prepareHeadersUMotion = async (uMotionSession: UmotionSessionState, headers: Headers, endpoint: string) => {
  if (!['createSession'].includes(endpoint) && uMotionSession) {
    headers.set('Authorization', `JWT ${uMotionSession?.sessionToken}`);
  }

  return headers;
};

const baseQueryOptions: FetchBaseQueryArgs = {
  baseUrl: VITE_API_URL,
  responseHandler: 'content-type',
  prepareHeaders: async (headers, { getState, endpoint }) => {
    const { session, uMotionSession } = getState() as RootState;

    if (uMotionSession?.sessionToken) {
      return prepareHeadersUMotion(uMotionSession, headers, endpoint);
    } else {
      return prepareHeadersUFeed(session, headers, endpoint);
    }
  },
};

if (/\.chumly\.me|api-dev\.u-feed\.app/.test(VITE_API_URL)) {
  // 新APIサーバーに対してはCookieを送信する
  baseQueryOptions.credentials = 'include';
}
const baseQuery = fetchBaseQuery(baseQueryOptions);

const refreshUFeedToken = async (session: SessionState, api: BaseQueryApi) => {
  const tokenResponse = new TokenResponse(JSON.parse(session?.tokenConfigJSON || '{}'));
  const refreshConfig: RefreshTokenRequestConfig = {
    clientId: AUTH0_CLIENT_ID,
    scopes: ['offline_access openid profile email'],
    extraParams: {
      audience: AUTH0_AUDIENCE,
      access_type: 'offline',
    },
  };
  const endpointConfig: Pick<AuthSession.DiscoveryDocument, 'tokenEndpoint'> = {
    tokenEndpoint: session?.tokenEndpoint,
  };

  try {
    const refreshResponse = await tokenResponse.refreshAsync(refreshConfig, endpointConfig);

    const tokenConfigJSON = JSON.stringify({
      ...refreshResponse?.getRequestConfig(),
      idToken: '',
    });

    await api.dispatch(
      setToken({
        tokenEndpoint: session?.tokenEndpoint,
        tokenConfigJSON,
        isUmotion: false,
      })
    );
  } catch (error) {
    console.error('token refresh error', error, refreshConfig, endpointConfig);
    if (error instanceof AuthSession.TokenError) {
      // Authentication Error
      await api.dispatch(
        setToken({
          tokenEndpoint: undefined,
          tokenConfigJSON: undefined,
          isUmotion: undefined,
        })
      );
    }
    // Network Error
    // FIX: handling
  }
};

const refreshUMotionToken = async (uMotionSession: UmotionSessionState, api: BaseQueryApi) => {
  try {
    const baseQueryWithUmotionRefreshToken = fetchBaseQuery({
      baseUrl: U_MOTION_API_URL,
      prepareHeaders: async (headers, { getState, endpoint }) => {
        const { uMotionSession } = getState() as RootState;

        if (uMotionSession?.refreshToken) {
          headers.set('Authorization', `Bearer ${uMotionSession.refreshToken}, JWT ${uMotionSession.sessionToken}`);

          headers.set('Access-Control-Allow-Methods', 'GET, POST, DELETE');
        }

        return headers;
      },
    });

    const refreshResult = await baseQueryWithUmotionRefreshToken({ url: '/session', method: 'PUT' }, api, {});

    if (!refreshResult) {
      return;
    }

    const { data, error } = refreshResult;

    if (error) throw error;

    // @ts-expect-error TS(2339): Property 'sessionToken' does not exist on type 'un... Remove this comment to see the full error message
    const { sessionToken, refreshToken, expiredAt } = data;

    await api.dispatch(
      setUmotionToken({
        sessionToken,
        refreshToken,
        expiredAt,
      })
    );
  } catch (error) {
    console.error('U-motion refreshToken error; ', error);
    if ((error as FetchBaseQueryError).status === 401) {
      // Authentication failed
      return api.dispatch(
        setUmotionToken({
          sessionToken: null,
          refreshToken: null,
          expiredAt: null,
        })
      );
    }
    // Not authentication error
  }
};

const baseQueryWithSessionRefresh: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const { session, uMotionSession } = api.getState() as RootState;

  const resp = await baseQuery(args, api, extraOptions);

  if (!resp.error) {
    return resp;
  }

  if (resp.error.status === 401) {
    if (session?.tokenConfigJSON) {
      await refreshUFeedToken(session, api);
    } else if (uMotionSession?.refreshToken) {
      await refreshUMotionToken(uMotionSession, api);
    }

    const retryResp = await baseQuery(args, api, extraOptions);

    if (!retryResp.error) {
      return retryResp;
    }

    if (retryResp.error.status === 401) {
      if (session?.tokenConfigJSON) {
        await api.dispatch(
          setToken({
            tokenEndpoint: undefined,
            tokenConfigJSON: undefined,
            isUmotion: undefined,
          })
        );
      } else if (uMotionSession?.refreshToken) {
        await api.dispatch(
          setUmotionToken({
            sessionToken: null,
            refreshToken: null,
            expiredAt: null,
          })
        );
      }
    }

    return retryResp;
  }

  return resp;
};

export const baseApi = createApi({
  baseQuery: baseQueryWithSessionRefresh,
  reducerPath: 'UFeedAPI',
  keepUnusedDataFor: 300,
  endpoints: (builder) => ({}),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === REHYDRATE && action.payload) {
      return action.payload[reducerPath];
    }
  },
});
