import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { Ionicons } from '@expo/vector-icons';
import { Icon } from '@gluestack-ui/themed-native-base';
import { useLinkTo, useRoute } from '@react-navigation/native';
import * as React from 'react';

import { ListItem } from '~/components';
import { ChatChannelListWeb } from '~/components/ChatChannelListWeb';
import { ShowPinMessagesProvider } from '~/contexts/PinMessageContext';
import { useStreamChatContext } from '~/contexts/StreamChatContext';
import { usePinnedChannels } from '~/hooks';

type ChannelListCategory = 'my' | 'pinned' | 'others';

interface Props {
  type: ChannelListCategory;
  disableAddChannel?: boolean;
}

export const ChatChannelListScreen: React.FC<Props> = React.memo(({ type, disableAddChannel }) => {
  const { chatUserId, isUserConnected } = useStreamChatContext();
  const { pinnedChannelsQuery } = usePinnedChannels();
  const route = useRoute();
  const linkTo = useLinkTo();

  useDidUpdate(
    () => {
      if (isUserConnected) {
        pinnedChannelsQuery();
      }
    },
    [isUserConnected, chatUserId],
    true
  );
  // @ts-expect-error TS(2339): Property 'channelId' does not exist on type 'objec... Remove this comment to see the full error message
  const channelId = route?.params?.channelId;
  // @ts-expect-error TS(2339): Property 'messageId' does not exist on type 'objec... Remove this comment to see the full error message
  const messageId = route?.params?.messageId;
  // @ts-expect-error TS(2339): Property 'message' does not exist on type 'object'... Remove this comment to see the full error message
  const sharedText = route?.params?.message;

  return (
    <ShowPinMessagesProvider>
      <ChatChannelListWeb
        disableAddChannel={disableAddChannel}
        // @ts-expect-error TS(2719): Type 'ChannelListCategory' is not assignable to ty... Remove this comment to see the full error message
        type={type}
        channelId={channelId}
        messageId={messageId}
        defaultValue={sharedText}
        addChannel={
          <ListItem
            key="add-button"
            title="チャンネルを追加"
            left={<Icon as={Ionicons} name="add-circle" color="blue.400" size="2xl" />}
            onPress={() => linkTo('/chat/channel/create')}
            link
          />
        }
      />
    </ShowPinMessagesProvider>
  );
});
