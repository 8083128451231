import { Text } from '@gluestack-ui/themed-native-base';
import { GyugunIdou } from './GyugunIdou';
import { PreviewMastitis } from './PreviewMastitis';

export const PreviewEventSuggestion = ({ eventSuggestion }: any) => {
  switch (eventSuggestion.event_type) {
    case 'gyugunidou':
      return <GyugunIdou data={eventSuggestion} />;
    case 'mastitis':
      return <PreviewMastitis data={eventSuggestion} />;
    default:
      return <Text>{eventSuggestion.name}</Text>;
  }
};
