import { Pressable, Box } from '@gluestack-ui/themed-native-base';
import { StyledProps } from 'native-base';
import { ReactNode } from 'react';
interface Props {
  onPress?: () => void;
  title: ReactNode;
  isDisabled?: boolean;
  testID?: string;
  padding?: number;
}

export const ButtonWithIcon: React.FC<Props & StyledProps> = ({
  onPress,
  title,
  isDisabled,
  testID,
  padding = 2,
  ...remainingProps
}) => {
  return (
    <Pressable
      onPress={isDisabled ? null : onPress}
      isDisabled={isDisabled}
      opacity={isDisabled ? 0.4 : 1.0}
      testID={testID}
      {...remainingProps}
    >
      {/* @ts-expect-error */}
      {({ isHovered, isFocused, isPressed }) => {
        return (
          <Box backgroundColor={isPressed ? '#999999' : '#e6e6e6'} padding={padding} borderRadius={30}>
            {title}
          </Box>
        );
      }}
    </Pressable>
  );
};
