import { VStack, FormControl } from '@gluestack-ui/themed-native-base';
import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { useForm } from 'react-hook-form';

import { AlertBox } from '~/components/builder/AlertBox';
import { BaseFormBuilder } from '~/components/builder/BaseFormBuilder';

interface Props {
  error: object;
  isUpdating?: boolean;
  onSubmit: (data: any) => void;
  onUpdate: (data: any) => void;
  fields: {
    key: string;
    label: string;
    type?: 'text' | 'password' | 'email' | 'farmDetails';
  }[];
}

export const CreateFormBuilder: React.FC<Props & React.PropsWithChildren> = ({
  error,
  isUpdating,
  onSubmit,
  fields,
  children,
  onUpdate,
}) => {
  const navigation = useNavigation();

  const { control, setFocus, handleSubmit, getValues } = useForm({
    mode: 'onChange',
  });

  const formConfigArray = fields.map((field) => ({
    ...field,
    type: field?.type || 'text',
    name: field?.key,
  }));

  return (
    <VStack>
      {/* @ts-expect-error TS(2339): Property 'data' does not exist on type 'object'. */}
      {error && !isUpdating ? <AlertBox type="error" message={error.data?.error} /> : null}
      <FormControl isDisabled={isUpdating}>
        <BaseFormBuilder
          control={control}
          setFocus={setFocus}
          // @ts-expect-error TS(2322): Type '{ type: "text" | "password" | "email" | "far... Remove this comment to see the full error message
          formConfigArray={formConfigArray}
          inputSpacing={29}
          onUpdate={onUpdate}
          onSubmit={onSubmit}
          getValues={getValues}
          // @ts-expect-error TS(2322): Type '{ key: string; label: string; type?: "text" ... Remove this comment to see the full error message
          fields={fields}
        />
        {children}
      </FormControl>
    </VStack>
  );
};
