import { ENV_FILE_NAME } from '@env';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

export const firebaseUmotionConfig =
  ENV_FILE_NAME === '.env.preview'
    ? {
        apiKey: 'AIzaSyDp1vWo_spX5mmGbYjGOYWF-HHGAXWDKSQ',
        authDomain: 'u-motion-sand-345b4.firebaseapp.com',
        databaseURL: 'https://u-motion-sand-345b4.firebaseio.com',
        projectId: 'u-motion-sand-345b4',
        storageBucket: 'u-motion-sand-345b4.appspot.com',
        messagingSenderId: '338691532983',
        appId: '1:338691532983:web:18ae57ef2db0c0b7da8532',
      }
    : {
        apiKey: 'AIzaSyDHDp6Ehp0d-MJ6mRk0m7ZtE7slG-nHO5E',
        authDomain: 'u-motion.firebaseapp.com',
        databaseURL: 'https://u-motion.firebaseio.com',
        projectId: 'u-motion',
        storageBucket: 'u-motion.appspot.com',
        messagingSenderId: '605301227923',
        appId: '1:605301227923:web:ff2207ed9d61ee4ee3a866',
        measurementId: 'G-PKZQW79HS6',
      };

const app = initializeApp(firebaseUmotionConfig, 'umotionAuth');
export const umotionAuth = getAuth(app);
