import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { useState } from 'react';

import { useGetAppointmentsServicesByIdQuery, useGetAppointmentsSlotsQuery } from '~/api/uFeedApi';

export const useAppointmentServiceDetail = ({ id }: any) => {
  const [slots, setSlots] = useState([]);
  const appointmentsServiceQuery = useGetAppointmentsServicesByIdQuery({ id });
  const appointmentsSlotsQuery = useGetAppointmentsSlotsQuery();

  useDidUpdate(
    () => {
      if (appointmentsSlotsQuery.isSuccess) {
        const slot = appointmentsSlotsQuery.data?.find((d) => d.id === id);
      }
    },
    [appointmentsSlotsQuery.isSuccess],
    true
  );

  return {
    appointmentsServiceQuery,
    appointmentsSlotsQuery,
    slots,
  };
};
