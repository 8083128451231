import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { U_FEED_URL_BASE } from '@env';
import { Box, Pressable } from '@gluestack-ui/themed-native-base';
import * as Clipboard from 'expo-clipboard';
import { useState, useRef, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { useWindowDimensions } from 'react-native';

import { useMessageContext, ActionsIcon } from 'stream-chat-react';
import { ChatCustomMessage } from '../ChatCustomMessage';
import {
  type Bookmark,
  useGetCurrentUserBookmarksQuery,
  usePostCurrentUserBookmarksMutation,
  useDeleteCurrentUserBookmarksByIdMutation,
} from '~/api/uFeedApi';
import { useStreamChatContext } from '~/contexts/StreamChatContext';
import type { Dispatch, ReactElement, SetStateAction } from 'react';
import type { DefaultStreamChatGenerics, StreamMessage } from 'stream-chat-react';

type ActionProps = {
  message: StreamMessage<DefaultStreamChatGenerics>;
  isRightAlign: boolean;
  isHovered: boolean;
  isActionOpen: boolean;
  setIsHovered: Dispatch<SetStateAction<boolean>>;
  setIsActionOpen: Dispatch<SetStateAction<boolean>>;
};

const ChatCustomParentAction = ({
  message,
  isRightAlign,
  isHovered,
  isActionOpen,
  setIsHovered,
  setIsActionOpen,
}: ActionProps) => {
  const currentUserBookmarks = useGetCurrentUserBookmarksQuery();
  const [post] = usePostCurrentUserBookmarksMutation();
  const [deleteBookMark] = useDeleteCurrentUserBookmarksByIdMutation();
  const bookmarksRef = useRef<Bookmark[]>();
  const bookmarksList: Bookmark[] = bookmarksRef.current ? bookmarksRef.current : [];
  const bookMarkID = bookmarksList.find((b) => b.message_id === message.id)?.id;
  const { appChannel } = useStreamChatContext();
  const { width: windowWidth } = useWindowDimensions();

  const actionButtonRef = useRef<HTMLDivElement>(null);
  const actionContainerRef = useRef<HTMLDivElement>(null);

  useDidUpdate(
    () => {
      if (currentUserBookmarks.data) {
        bookmarksRef.current = currentUserBookmarks.data;
      }
    },
    [currentUserBookmarks.data, message],
    true
  );

  const updateBookMark = () => {
    setIsHovered(false);
    setIsActionOpen(false);
    if (bookMarkID) {
      deleteBookMark({
        id: bookMarkID,
      });
    } else {
      post({
        body: {
          user_bookmark: {
            message_id: message.id,
            bookmark_type: 'chat-message',
          },
        },
      });
    }
  };

  const copyLink = async () => {
    setIsHovered(false);
    setIsActionOpen(false);
    return Clipboard.setStringAsync(`${U_FEED_URL_BASE}/chat/${appChannel?.id}/${message.id}`);
  };

  const copyText = async () => {
    setIsHovered(false);
    setIsActionOpen(false);
    return Clipboard.setStringAsync(`${message.text}`);
  };

  const handleOutsideClick = (e: MouseEvent) => {
    if (actionContainerRef.current && !actionContainerRef.current.contains(e.target as Node)) {
      setIsActionOpen(false);
    }
  };

  const actionLeftPosition = useMemo(() => {
    if (!isRightAlign) {
      return 'auto';
    }

    return (actionButtonRef.current?.getBoundingClientRect().right ?? 0) + 224 > windowWidth ? -200 : 0;
  }, [isRightAlign, windowWidth, actionButtonRef.current]);

  const actionRightPosition = useMemo(() => {
    if (isRightAlign) {
      return 'auto';
    }

    return (actionButtonRef.current?.getBoundingClientRect().right ?? 0) + 144 > windowWidth ? -32 : -168;
  }, [isRightAlign, windowWidth, actionButtonRef.current]);

  useEffect(() => {
    window.addEventListener('click', handleOutsideClick);
    return () => window.removeEventListener('click', handleOutsideClick);
  }, []);

  if (!isHovered) return null;

  return (
    <>
      <Box
        ref={actionButtonRef}
        position="absolute"
        left={isRightAlign ? -32 : 'auto'}
        right={isRightAlign ? 'auto' : -32}
        top={0}
      >
        <Pressable
          onPress={() => setIsActionOpen((val) => !val)}
          px={2}
          py={2}
          rounded="full"
          _hover={{ backgroundColor: '#f4f4f5' }}
        >
          <ActionsIcon />
        </Pressable>
      </Box>
      {isActionOpen && (
        <Box
          ref={actionContainerRef}
          position="absolute"
          top={isRightAlign ? -12 : -32}
          left={actionLeftPosition}
          right={actionRightPosition}
          backgroundColor="#fff"
          rounded="xl"
          shadow="3"
          py={1}
          w={40}
        >
          <Pressable
            onPress={updateBookMark}
            px={4}
            py={2}
            fontSize={16}
            textAlign="left"
            _hover={{ backgroundColor: '#f4f4f4' }}
          >
            {bookMarkID ? 'ブックマーク解除' : 'ブックマークする'}
          </Pressable>
          <Pressable
            onPress={copyLink}
            px={4}
            py={2}
            fontSize={16}
            textAlign="left"
            _hover={{ backgroundColor: '#f4f4f4' }}
          >
            リンクをコピー
          </Pressable>
          <Pressable
            onPress={copyText}
            px={4}
            py={2}
            fontSize={16}
            textAlign="left"
            _hover={{ backgroundColor: '#f4f4f4' }}
          >
            テキストをコピー
          </Pressable>
        </Box>
      )}
    </>
  );
};

const ChatCustomParentMessage = (): ReactElement | null => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isActionOpen, setIsActionOpen] = useState<boolean>(false);

  const { message, isMyMessage } = useMessageContext();

  const actionRef = document.getElementById(`parent-action-${message.id}`);
  const containerRef = useRef<HTMLDivElement>(null);

  const isRightAlign = useMemo(isMyMessage, [isMyMessage]);

  const handleOutsideClick = (e: MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(e.target as Node)) {
      setIsHovered(false);
      setIsActionOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleOutsideClick);
    return () => window.removeEventListener('click', handleOutsideClick);
  }, []);

  return (
    <Box ref={containerRef} onPointerEnter={() => setIsHovered(true)} onPointerLeave={() => setIsHovered(isActionOpen)}>
      <ChatCustomMessage isThreadParent />
      {actionRef &&
        createPortal(
          (
            <ChatCustomParentAction
              message={message}
              isRightAlign={isRightAlign}
              isHovered={isHovered}
              isActionOpen={isActionOpen}
              setIsHovered={setIsHovered}
              setIsActionOpen={setIsActionOpen}
            />
          ) as any,
          actionRef
        )}
    </Box>
  );
};

export default ChatCustomParentMessage;
