import { HStack } from '@gluestack-ui/themed-native-base';
import { Spinner } from 'native-base';

import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useStreamChatAuthContext } from '~/contexts/StreamChatContext';
import { usePinnedChannels } from '~/hooks';
import { StreamChatGenerics } from '~/types';
import { AddChannelColumn, ChannelColumnWithId } from './ChannelColumn';
import { NotificationColumn } from './NotificationColumn';

import type { Channel as StreamChannel } from 'stream-chat';

const COLUMN_WIDTH = 400;

type AllChannelContextType = {
  allChannels: StreamChannel<StreamChatGenerics>[];
};

const AllChannelContext = createContext<AllChannelContextType>({
  allChannels: [],
});

const AllChannelProvider = ({ children }: { children: React.ReactNode }) => {
  const { chatClient, chatUserId } = useStreamChatAuthContext();

  const [allChannels, setAllChannels] = useState<StreamChannel[]>([]);

  useEffect(() => {
    const getAllChannels = async (chatUserId: string) => {
      let readmore = true;
      let results: StreamChannel[] = [];
      let offset = 0;
      const limit = 100;
      while (readmore) {
        const channels = await chatClient.queryChannels({ members: { $in: [chatUserId] } }, {}, { limit, offset });
        results = [...results, ...channels];
        offset += channels.length;
        if (channels.length < limit) {
          readmore = false;
        }
      }
      return results;
    };

    if (!chatUserId) return;
    getAllChannels(chatUserId).then(setAllChannels);
  }, [chatUserId]);

  const contextValue = useMemo(() => ({ allChannels }), [allChannels]);
  return <AllChannelContext.Provider value={contextValue}>{children}</AllChannelContext.Provider>;
};

export const useAllChannel = () => {
  const context = React.useContext(AllChannelContext);
  if (context === undefined) {
    throw new Error('useAllChannel must be used within a AllChannelProvider');
  }
  return context;
};

export const ChatMultiColumnsViewScreen = () => {
  const { isUserConnected } = useStreamChatAuthContext();

  const { pinnedChannels } = usePinnedChannels();

  if (!isUserConnected) return <Spinner />;
  return (
    <AllChannelProvider>
      <HStack gap="2xs" backgroundColor="surface" height="100%" overflowX="scroll">
        <NotificationColumn width={COLUMN_WIDTH} />
        {pinnedChannels.map((channelId, _idx) => (
          <ChannelColumnWithId width={COLUMN_WIDTH} channelId={channelId} key={`${_idx}_${channelId}`} />
        ))}
        <AddChannelColumn />
      </HStack>
    </AllChannelProvider>
  );
};
