import { useRoute, useNavigation, useLinkTo } from '@react-navigation/native';
import * as React from 'react';
import { Platform } from 'react-native';

import { useGetUsersQuery, useGetCurrentUserQuery } from '~/api/uFeedApi';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';
import { ListBuilder } from '~/components/builder/ListBuilder';

export const AdminUserListScreen: React.FC = () => {
  const { params } = useRoute();
  const navigation = useNavigation();
  const queryParams = params ? { ...params } : {};
  // @ts-expect-error TS(2345): Argument of type '{}' is not assignable to paramet... Remove this comment to see the full error message
  const queryResult = useGetUsersQuery(queryParams);
  const currentUser = useGetCurrentUserQuery({});
  const linkTo = useLinkTo();

  return (
    <BaseScreenBuilder title="">
      <ListBuilder
        // @ts-expect-error TS(2322): Type 'UseQueryHookResult<QueryDefinition<void, Bas... Remove this comment to see the full error message
        queryResult={queryResult}
        onSelect={(item) =>
          Platform.OS === 'web'
            ? linkTo(`/admin/users/${item?.id}`)
            : // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
              navigation.navigate('AdminUserDetail', {
                id: item?.id,
              })
        }
        onAddPress={
          currentUser.data?.roles?.some((role) => role === 'account_admin' || role === 'member_admin')
            ? // @ts-expect-error TS(2769): No overload matches this call.
              () => (Platform.OS === 'web' ? linkTo('/admin/users/create') : navigation.navigate('AdminUserCreate'))
            : undefined
        }
        emptyMessage="ユーザーデータがありません"
      />
    </BaseScreenBuilder>
  );
};
