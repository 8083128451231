import { Ionicons } from '@expo/vector-icons';
import { Stack, HStack, Text, Icon, Button } from '@gluestack-ui/themed-native-base';

import { ButtonWithIcon } from '~/components/ButtonWithIcon';
import { DateUtil } from '~/utils/DateUtils';

interface Props {
  beginDate: Date;
  endDate: Date;
  onPressPrev?: () => void;
  onPressNext?: () => void;
  range?: number;
  onRangeChange?: (range: number) => void;
}

export const ChartDateNavi: React.FC<Props> = ({
  beginDate,
  endDate,
  onPressPrev,
  onPressNext,
  range,
  onRangeChange,
  ...remainingProps
}) => {
  const isDisabledNext = (endDate: Date) => {
    const today = new Date();
    today.setDate(today.getDate() - 1);
    return endDate >= today;
  };

  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      alignItems={{ base: 'flex-start', md: 'center' }}
      space={6}
      {...remainingProps}
    >
      <HStack justifyContent="flex-start">
        <Button.Group colorScheme="blue" isAttached size="xs" justifyContent="center">
          <Button variant={range === 30 ? 'solid' : 'outline'} onPress={() => onRangeChange?.(30)} size="md">
            30日
          </Button>
          <Button variant={range === 90 ? 'solid' : 'outline'} onPress={() => onRangeChange?.(90)} size="md">
            90日
          </Button>
        </Button.Group>
      </HStack>
      <HStack justifyContent="center" alignItems="center" space={4}>
        <ButtonWithIcon
          onPress={onPressPrev}
          title={<Icon as={Ionicons} name="chevron-back-outline" size="sm" />}
          isDisabled={!onPressPrev}
          testID="chart-date-prev-button"
          padding={1}
        />
        <Text fontSize="sm">
          <Text>{DateUtil.toJapaneseYYYYMMDD(beginDate)}</Text>〜<Text>{DateUtil.toJapaneseYYYYMMDD(endDate)}</Text>
        </Text>
        <ButtonWithIcon
          onPress={onPressNext}
          title={<Icon as={Ionicons} name="chevron-forward-outline" size="sm" />}
          isDisabled={isDisabledNext(endDate)}
          testID="chart-date-next-button"
          padding={1}
        />
      </HStack>
    </Stack>
  );
};
