import { useMemo, useState } from 'react';
export const useDateByRange = (initialEndDate: Date, initialRange: number = 30) => {
  const [endDate, setEndDate] = useState(initialEndDate);
  const [range, setRange] = useState(initialRange);

  const { beginDate, preEndDate, preBeginDate, nextBeginDate, nextEndDate } = useMemo(() => {
    const beginDate = new Date(endDate.getTime());
    beginDate.setDate(beginDate.getDate() - (range - 1));

    const preEndDate = new Date(beginDate.getTime());
    preEndDate.setDate(preEndDate.getDate() - 1);

    const preBeginDate = new Date(preEndDate.getTime());
    preBeginDate.setDate(preBeginDate.getDate() - (range - 1));

    const nextBeginDate = new Date(endDate.getTime());
    nextBeginDate.setDate(nextBeginDate.getDate() + 1);

    const nextEndDate = new Date(nextBeginDate.getTime());
    nextEndDate.setDate(nextEndDate.getDate() + (range - 1));

    return { beginDate, preEndDate, preBeginDate, nextBeginDate, nextEndDate };
  }, [endDate, range]);

  return {
    beginDate,
    endDate,
    setEndDate,
    preEndDate,
    preBeginDate,
    nextBeginDate,
    nextEndDate,
    range,
    setRange,
  };
};
