import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { useState } from 'react';

import { useGetCurrentUserGroupsQuery } from '~/api/uFeedApi';

const REFETCH_INTERVAL_SECONDS = 60 * 60 * 2;

export const useAccounts = () => {
  const currentUserGroupsQuery = useGetCurrentUserGroupsQuery(undefined, {
    refetchOnMountOrArgChange: REFETCH_INTERVAL_SECONDS,
  });
  const [accounts, setAccounts] = useState({});

  useDidUpdate(
    () => {
      if (currentUserGroupsQuery.isError) {
        return;
      }
      const groups = currentUserGroupsQuery.data || [];
      const users = groups.map((g) => g.approved_users)?.flat();

      setAccounts(
        users.reduce((acc, cur) => {
          return {
            ...acc,
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            [cur.chat_user_id]: cur?.account.name,
          };
        }, {})
      );
    },
    [currentUserGroupsQuery.data],
    true
  );

  return {
    accounts,
  };
};
