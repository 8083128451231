import { Alert as AlertReactNative, Platform } from 'react-native';

// https://github.com/necolas/react-native-web/issues/1026#issuecomment-679102691

const alert = (title: string, description: string, options: any[]) => {
  const result = window.confirm([title, description].filter(Boolean).join('\n'));

  if (result) {
    const confirmOption = options.find(({ style, onPress }) => style !== 'cancel' && onPress);

    confirmOption && confirmOption.onPress();
  } else {
    const cancelOption = options.find(({ style }) => style === 'cancel');
    cancelOption && cancelOption.onPress();
  }
};

export const Alert = Platform.OS === 'web' ? { alert } : AlertReactNative;
