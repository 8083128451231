import { differenceInWeeks } from 'date-fns';
import React, { useMemo } from 'react';
import { MessageTimestampProps } from 'stream-chat-react';

import { useMessageContext, isDate, DefaultStreamChatGenerics, MessageTimestamp } from '~/lib/StreamChatReact';

const UnMemoizedMessageTimestamp = <StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics>(
  props: MessageTimestampProps<StreamChatGenerics>
) => {
  const { message: propMessage } = props;
  const { message: contextMessage } = useMessageContext<StreamChatGenerics>('MessageTimestamp');
  const message = propMessage || contextMessage;

  if (!message.created_at) {
    return;
  }

  const messageCreatedAtLocale = isDate(message.created_at) ? message.created_at.toLocaleString() : message.created_at;
  const messageCreatedAtLocaleWidthoutSeconds = isDate(message.created_at)
    ? message.created_at.toLocaleString('ja-JP', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      })
    : message.created_at;

  const isWeekAgo = useMemo(() => {
    if (!message.created_at) return false;
    const today = new Date();
    const created = isDate(message.created_at) ? message.created_at : new Date(message.created_at);
    return !!differenceInWeeks(today, created);
  }, [message.created_at]);

  return (
    <div title={messageCreatedAtLocale}>
      {isWeekAgo ? (
        <time className="str-chat__message-simple-timestamp">{messageCreatedAtLocaleWidthoutSeconds}</time>
      ) : (
        <MessageTimestamp calendar customClass="str-chat__message-simple-timestamp" />
      )}
    </div>
  );
};

export const CustomMessageTimestamp = React.memo(UnMemoizedMessageTimestamp) as typeof UnMemoizedMessageTimestamp;
