import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '~/store';

export type SessionState = {
  tokenEndpoint?: string;
  tokenConfigJSON?: string;
  isUmotion?: boolean;
};

const slice = createSlice({
  name: 'session',
  initialState: {
    tokenEndpoint: undefined,
    tokenConfigJSON: undefined,
  } as SessionState,
  reducers: {
    setToken: (
      state,
      { payload: { tokenEndpoint, tokenConfigJSON, isUmotion = false } }: PayloadAction<SessionState>
    ) => {
      state.tokenEndpoint = tokenEndpoint;
      state.tokenConfigJSON = tokenConfigJSON;
      state.isUmotion = isUmotion;
    },
  },
});

export const { setToken } = slice.actions;

export const sessionSelector = (state: RootState) => {
  return state.session;
};

export default slice.reducer;
