import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { Text } from '@gluestack-ui/themed-native-base';
import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { useState } from 'react';

import { useGetAccountsQuery, usePutAccountsMutation } from '~/api/uFeedApi';
import { BaseFormBuilder, errorMessageBuilder } from '~/components/builder';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';

export const AdminAccountEditScreen: React.FC = () => {
  const navigation = useNavigation();
  const queryResult = useGetAccountsQuery();
  const [update, { isLoading: isUpdating, data }] = usePutAccountsMutation();

  const [errorMessages, setErrorMessages] = useState([]);
  const [editMassage, setEditMassage] = useState('');

  const fields = [
    {
      key: 'name',
      label: '名前',
      rules: {
        required: {
          value: true,
          message: '名前を入力してください',
        },
      },
    },
  ];

  const onSubmit = (sendingData: any) => {
    setErrorMessages([]);
    setEditMassage('');
    const { name } = sendingData;
    update({
      body: {
        account: {
          // @ts-expect-error TS(2322): Type '{ name: any; }' is not assignable to type 'A... Remove this comment to see the full error message
          name,
        },
      },
    })
      .unwrap()
      .then((resp) => {
        setEditMassage('企業名を更新しました。');
      })
      .catch((error) => {
        setErrorMessages(errorMessageBuilder(error, fields));
        console.error('update error: ', error);
      });
  };

  useDidUpdate(() => {
    // @ts-expect-error TS(2339): Property 'name' does not exist on type 'Account'.
    if (queryResult.data?.name) {
      navigation?.setOptions({
        // @ts-expect-error TS(2339): Property 'name' does not exist on type 'Account'.
        title: queryResult.data?.name,
      });
    }
    // @ts-expect-error TS(2339): Property 'name' does not exist on type 'Account'.
  }, [queryResult.data?.name]);

  return (
    <BaseScreenBuilder>
      <BaseFormBuilder
        // @ts-expect-error TS(2322): Type 'UseQueryHookResult<QueryDefinition<void, Bas... Remove this comment to see the full error message
        queryResult={queryResult}
        updateResult={{ isUpdating, data }}
        emptyMessage="企業データがありません"
        onSubmit={onSubmit}
        errorMessage={errorMessages}
        onCancel={() => navigation.goBack()}
        // @ts-expect-error TS(2322): Type '{ key: string; label: string; rules: { requi... Remove this comment to see the full error message
        fields={fields}
      />
      {editMassage ? (
        <Text fontSize="md" fontWeight="medium" color="success.900">
          {editMassage}
        </Text>
      ) : null}
    </BaseScreenBuilder>
  );
};
