import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { useCallback, useState } from 'react';
import { useStreamChatContext } from '~/contexts/StreamChatContext';
import type { Event } from 'stream-chat';

export const usePinMessageWeb = () => {
  const { chatClient, appChannel } = useStreamChatContext();

  const [pinMessages, setPinMessages] = useState([]);

  const asyncGetPinMessage = useCallback(async (cid?: unknown) => {
    if (!cid || typeof cid !== 'string') {
      return;
    }
    const channelFilters = { cid };

    const messageFilters = { pinned: { $eq: true } };

    await chatClient
      ?.search(channelFilters, messageFilters, { limit: 300, offset: 0, sort: { created_at: -1 } })
      .then((resp) => {
        // @ts-expect-error TS(2345): Argument of type '{ message: MessageResponse<Strea... Remove this comment to see the full error message
        setPinMessages(resp?.results);
      })
      .catch((error) => {
        console.error('message search error: ', error);
      });
  }, []);

  const onMessageUpdate = useCallback((event: Event) => {
    // @ts-expect-error TS(2345): Argument of type '(items: never[]) => Event<Defaul... Remove this comment to see the full error message
    setPinMessages((items) => {
      // @ts-expect-error TS(2339): Property 'message' does not exist on type 'never'.
      const index = items.findIndex((item) => item.message.id === event.message?.id);
      if (index === -1 && event.message?.pinned) {
        return [...items, event];
      } else if (index !== -1 && !event.message?.pinned) {
        // @ts-expect-error TS(2339): Property 'message' does not exist on type 'never'.
        const newPinArr = items.filter((item) => item.message.id !== event.message?.id);
        return [...newPinArr];
      }
      return [...items];
    });
  }, []);

  useDidUpdate(() => {
    appChannel?.watch();
    const listener = appChannel?.on('message.updated', onMessageUpdate);

    return () => {
      listener?.unsubscribe();
    };
  }, [appChannel, onMessageUpdate]);

  useDidUpdate(() => {
    asyncGetPinMessage(appChannel?.data?.cid);
  }, [appChannel?.data?.cid]);

  return { pinMessages };
};
