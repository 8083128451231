import { Divider, VStack } from '@gluestack-ui/themed-native-base';

import { GetAppointmentsApiResponse } from '~/api/uFeedApi';
import { MyAppointmentListItem } from '~/components/MyAppointmentListItem';

interface Props {
  slots: GetAppointmentsApiResponse;
}

export const MyAppointmentList: React.FC<Props> = ({ slots }) => {
  return (
    <VStack space={4} divider={<Divider />}>
      {slots.map((slot) => {
        return <MyAppointmentListItem data={slot} key={slot?.id} />;
      })}
    </VStack>
  );
};
