import { FlatList } from '@gluestack-ui/themed';
import { Center, Divider, Spinner, VStack } from '@gluestack-ui/themed-native-base';
import * as React from 'react';
import { ChatSearchResultItem } from '~/components/ChatSearchResultItem';
import { EmptyMessage } from '~/components/EmptyMessage';
import { useBookmarkQuery } from '~/hooks/useBookmarkQuery';

export const ChatBookmarkListScreen: React.FC = () => {
  const { messages, initialized, bookmarkQuery } = useBookmarkQuery();

  return initialized ? (
    <FlatList
      data={messages}
      // @ts-expect-error
      keyExtractor={(item) => `${item.bookmark_created_at}_${item.message.id}`}
      ItemSeparatorComponent={() => (
        <VStack paddingHorizontal="md">
          <Divider backgroundColor="outline" />
        </VStack>
      )}
      renderItem={({ item }) => (
        <VStack>
          {/* @ts-expect-error */}
          <ChatSearchResultItem message={item.message} />
        </VStack>
      )}
      onEndReached={bookmarkQuery}
      onEndReachedThreshold={0.3}
      ListEmptyComponent={<EmptyMessage emptyMessage="ブックマークがありませんでした" />}
      backgroundColor="white"
    />
  ) : (
    <Center flex={1}>
      <Spinner />
    </Center>
  );
};
