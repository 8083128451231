import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { COMMIT_HASH } from '@env';
import { Switch, Link, useToast, Box } from '@gluestack-ui/themed-native-base';
import { useNavigation } from '@react-navigation/native';
import * as Application from 'expo-application';
import * as Clipboard from 'expo-clipboard';
import * as Device from 'expo-device';
import * as Updates from 'expo-updates';
import * as WebBrowser from 'expo-web-browser';
import * as React from 'react';
import { Platform } from 'react-native';

import { SectionList, Section, ItemList, ListItem } from '~/components';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';
import { useStreamChatAuthContext } from '~/contexts/StreamChatContext';
import { usePushNotification } from '~/hooks';
import { setEnableNetworkLog, settingsSelector } from '~/slices/settingsSlice';
import { useAppSelector, useAppDispatch } from '~/store';
import { isHermes } from '~/utils/isHermes';

export const AboutThisAppScreen: React.FC = () => {
  const dispatch = useAppDispatch();
  const settings = useAppSelector(settingsSelector);
  const navigation = useNavigation();
  const toast = useToast();
  const [pushState, setPushState] = React.useState('');
  const { chatClient, chatUserId } = useStreamChatAuthContext();
  const { pushToken } = usePushNotification();

  useDidUpdate(() => {
    const updatePushState = async () => {
      if (!pushToken) {
        return;
      }

      const resp = await chatClient?.getDevices();
      if (!resp) {
        console.error('getDevices failed');
        setPushState('登録済みデバイス取得エラー');
        return;
      }

      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      const status = resp.devices.find((d) => d.id === pushToken);

      setPushState(
        status ? (status.disabled ? `送信停止中(${status.disabled_reason})` : '正常') : '通知トークンが未登録'
      );
    };
    updatePushState();
  }, [pushToken]);

  const onPressTosAsync = async () => {
    await WebBrowser.openBrowserAsync('https://www.desamis.co.jp/u-feed-terms');
  };

  const onPressPrivacyPolicyAsync = async () => {
    await WebBrowser.openBrowserAsync('https://www.desamis.co.jp/u-feed-privacy-policy');
  };

  const copyToClipboard = async (description: string, text: string) => {
    await Clipboard.setStringAsync(text);
    toast.show({
      description,
      placement: 'top',
    });
  };

  const supportText = `バージョン: ${Application.nativeApplicationVersion}${isHermes() ? 'H' : ''}
コミットID: ${COMMIT_HASH}
メーカー: ${Device.brand}
モデル: ${Device.modelName}
OS: ${Device.osName} ${Device.osVersion}
チャットID: ${chatUserId}
プッシュ通知のステータス: ${pushState}
プッシュ通知トークン: ${pushToken}`;

  const copySupportText = () => {
    return copyToClipboard('サポート情報をクリップボードにコピーしました', supportText);
  };

  return (
    <BaseScreenBuilder title="Chumlyについて">
      <SectionList space={8}>
        {Platform.OS !== 'web' ? (
          <Section title="アプリケーション情報">
            <ItemList>
              {/* @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message */}
              <ListItem title="アプリケーション名" value={Application.applicationName} />
              <ListItem title="バージョン" value={`${Application.nativeApplicationVersion}${isHermes() ? 'H' : ''}`} />
              {/* @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message */}
              <ListItem title="ビルド" value={Application.nativeBuildVersion} />
              {/* @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message */}
              <ListItem title="ID" value={Application.applicationId} />
            </ItemList>
          </Section>
        ) : null}

        {Platform.OS !== 'web' ? (
          <Section title="OTAアップデート">
            <ItemList>
              <ListItem title="OTAチャンネル" value={Updates.channel || '-'} />
              <ListItem title="OTAバージョン" value={Updates.updateId || '-'} />
            </ItemList>
          </Section>
        ) : null}

        {Platform.OS !== 'web' ? (
          <Section title="ネットワーク・ログ">
            <ItemList>
              <ListItem
                title="ネットワーク・ログを有効にする"
                right={
                  <Switch
                    isChecked={settings.enableNetworkLog}
                    // @ts-expect-error
                    onToggle={(value) => {
                      dispatch(setEnableNetworkLog(value));
                    }}
                  />
                }
              />
              {settings.enableNetworkLog ? (
                <ListItem
                  title="ネットワーク・ログ"
                  arrow
                  onPress={() =>
                    // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                    navigation.navigate('NetworkLog')
                  }
                />
              ) : null}
            </ItemList>
          </Section>
        ) : null}
        <Section title="プッシュ通知">
          <ItemList>
            <ListItem title="接続状況" value={pushState} />
          </ItemList>
        </Section>

        <Section title="サポート情報">
          <ItemList>
            {/* @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message */}
            <ListItem title="メーカー" value={Device.brand} />
            {/* @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message */}
            <ListItem title="モデル" value={Device.modelName} />
            <ListItem title="OS" value={`${Device.osName} ${Device.osVersion}`} />
            <ListItem title="チャットID" value={`${chatUserId}`} />
            {COMMIT_HASH && <ListItem title="コミットID" value={COMMIT_HASH} />}
          </ItemList>
          <Box alignItems="center">
            <Link
              onPress={copySupportText}
              _text={{
                fontSize: 'md',
                _light: {
                  color: 'cyan.500',
                },
                color: 'cyan.300',
              }}
            >
              サポート情報をクリップボードにコピー
            </Link>
          </Box>
        </Section>
        <Section title="その他">
          <ItemList>
            <Link onPress={onPressTosAsync}>利用規約</Link>
            <Link onPress={onPressPrivacyPolicyAsync}>プライバシーポリシー</Link>
          </ItemList>
        </Section>
      </SectionList>
    </BaseScreenBuilder>
  );
};
