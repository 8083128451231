import { FormControl, Input, VStack, Button, Hidden } from '@gluestack-ui/themed-native-base';
import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { Controller, Control } from 'react-hook-form';
import { Platform, StyleSheet, View, Dimensions } from 'react-native';

import { FarmDetail } from '~/api/uFeedApi';

import { FormFieldLabel } from './FormFieldLabel';

//import MapView, { Marker, LatLng, Region, PROVIDER_GOOGLE } from 'react-native-maps';

const { useState } = React;

interface Props {
  control: Control;
  index: number;
  update: (index: number, obj: object) => void;
  field: FarmDetail & { id: string };
  formState: object;
}

export const FormFarmDetail: React.FC<Props> = ({ control, index, update, field, formState }) => {
  const navigation = useNavigation();

  // @ts-expect-error TS(2304): Cannot find name 'Region'.
  const region: Region = {
    latitude: field?.latitude,
    longitude: field?.longitude,
    latitudeDelta: 0.046,
    longitudeDelta: 0.026,
  };

  const [detail, setDetail] = useState(field);

  // @ts-expect-error TS(2304): Cannot find name 'LatLng'.
  const onClose = (coordinate: LatLng) => {
    update(index, { ...detail, ...coordinate });
  };

  return (
    <>
      <VStack space={2} flex={1} zIndex={1000}>
        <FormControl
          isRequired
          // @ts-expect-error TS(2339): Property 'errors' does not exist on type 'object'.
          isInvalid={formState.errors?.details?.filter(
            (item: any) => item?.label?.ref?.name == `details.${index}.label`
          )}
        >
          <FormFieldLabel label="ラベル" isRequired />
          <Controller
            name={`details.${index}.label`}
            control={control}
            defaultValue={field?.label}
            rules={{ required: 'ラベルを入力してください' }}
            render={({ field }) => {
              return (
                <Input
                  value={field.value}
                  onBlur={field.onBlur}
                  size="lg"
                  variant="outline"
                  borderColor="black"
                  py={3}
                  testID={`label-input-farm-detail-${index}`}
                  // @ts-expect-error
                  onChangeText={(val) => {
                    setDetail({ ...detail, label: val });
                    field.onChange(val);
                  }}
                />
              );
            }}
          />
          {/* @ts-expect-error TS(2339): Property 'errors' does not exist on type 'object'. */}
          {formState.errors?.details
            ?.filter((item: any) => item?.label?.ref?.name == `details.${index}.label`)
            .map((error: any, i: any) => (
              <FormControl.ErrorMessage key={i}>{error.label.message}</FormControl.ErrorMessage>
            ))}
        </FormControl>
        <FormControl
          // @ts-expect-error TS(2339): Property 'errors' does not exist on type 'object'.
          isInvalid={formState.errors?.details?.filter(
            (item: any) => item?.phone_number?.ref?.name == `details.${index}.phone_number`
          )}
        >
          <FormFieldLabel label="電話番号" />
          <Controller
            name={`details.${index}.phone_number`}
            control={control}
            defaultValue={field?.phone_number}
            rules={{ pattern: { value: /^[0-9\-]*$/, message: '電話番号の形式に誤りがあります。' } }}
            render={({ field }) => {
              return (
                <Input
                  size="lg"
                  variant="outline"
                  borderColor="black"
                  py={3}
                  testID={`phone_number-input-farm-detail-${index}`}
                  // @ts-expect-error
                  onChangeText={(val) => {
                    setDetail({ ...detail, phone_number: val });
                    field.onChange(val);
                  }}
                  value={field.value}
                  onBlur={field.onBlur}
                  maxLength={16}
                />
              );
            }}
          />
          {/* @ts-expect-error TS(2339): Property 'errors' does not exist on type 'object'. */}
          {formState.errors?.details
            ?.filter((item: any) => item?.phone_number?.ref?.name == `details.${index}.phone_number`)
            .map((error: any, i: any) => (
              <FormControl.ErrorMessage key={i}>{error.phone_number.message}</FormControl.ErrorMessage>
            ))}
        </FormControl>
        <FormControl
          // @ts-expect-error TS(2339): Property 'errors' does not exist on type 'object'.
          isInvalid={formState.errors?.details?.filter(
            (item: any) => item?.address?.ref?.name == `details.${index}.address`
          )}
        >
          <FormFieldLabel label="住所" />
          <Controller
            name={`details.${index}.address`}
            control={control}
            defaultValue={field?.address}
            render={({ field }) => {
              return (
                <Input
                  size="lg"
                  variant="outline"
                  borderColor="black"
                  py={3}
                  testID={`address-input-farm-detail-${index}`}
                  // @ts-expect-error
                  onChangeText={(val) => {
                    setDetail({ ...detail, address: val });
                    field.onChange(val);
                  }}
                  value={field.value}
                  onBlur={field.onBlur}
                  maxLength={100}
                />
              );
            }}
          />
          {/* @ts-expect-error TS(2339): Property 'errors' does not exist on type 'object'. */}
          {formState.errors?.details
            ?.filter((item: any) => item?.address?.ref?.name == `details.${index}.address`)
            .map((error: any, i: any) => (
              <FormControl.ErrorMessage key={i}>{error.label.message}</FormControl.ErrorMessage>
            ))}
        </FormControl>
        <Hidden>
          <Controller
            name={`details.${index}.latitude`}
            control={control}
            defaultValue={field?.latitude}
            render={({ field }) => {
              return (
                <Input
                  size="lg"
                  variant="outline"
                  borderColor="black"
                  py={3}
                  value={field.value}
                  onBlur={field.onBlur}
                />
              );
            }}
          />
        </Hidden>
        <Hidden>
          <Controller
            name={`details.${index}.longitude`}
            control={control}
            defaultValue={field?.longitude}
            render={({ field }) => {
              return (
                <Input
                  size="lg"
                  variant="outline"
                  borderColor="black"
                  py={3}
                  value={field.value}
                  onBlur={field.onBlur}
                />
              );
            }}
          />
        </Hidden>
        <FormControl>
          {field?.latitude ? <View style={styles.container} /> : null}
          {Platform.OS !== 'android' ? (
            <Button
              variant="unstyled"
              onPress={() => {
                // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                navigation.navigate('Map', {
                  onClose,
                  coordinate: {
                    latitude: field?.latitude ? field?.latitude : 0,
                    longitude: field?.longitude ? field?.longitude : 0,
                  },
                });
              }}
            >
              地図で場所を選択
            </Button>
          ) : null}
        </FormControl>
      </VStack>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
  },
  map: {
    width: '100%',
    height: Dimensions.get('window').height / 8,
  },
});
