import * as React from 'react';

import { useGetAppointmentsQuery } from '~/api/uFeedApi';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';
import { MyAppointmentList } from '~/components/MyAppointmentList';

export const MyAppointmentListScreen: React.FC = () => {
  const { data, isSuccess, isLoading, isError } = useGetAppointmentsQuery({
    type: 'self',
  });

  return (
    <BaseScreenBuilder
      title="予約済み提供中サービス"
      isError={isError}
      isEmpty={isSuccess && data?.length === 0}
      isLoading={isLoading}
      errorMessage="提供中スケジュールの予約を取得できませんでした"
      emptyMessage="あなたが提供中のスケジュールに予約がありません"
    >
      <MyAppointmentList slots={data || []} />
    </BaseScreenBuilder>
  );
};
