import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { Ionicons } from '@expo/vector-icons';
import { HStack, VStack, Text, Image, Icon } from '@gluestack-ui/themed-native-base';
import { useNavigation, useLinkTo } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useRef } from 'react';
import { Animated, Platform, TouchableOpacity } from 'react-native';
import Swipeable from 'react-native-gesture-handler/Swipeable';
import { StreamMessage } from 'stream-chat-react';
import { Bookmark, useDeleteCurrentUserBookmarksByIdMutation, useGetCurrentUserBookmarksQuery } from '~/api/uFeedApi';

import { CustomMessageText } from '~/components';
import { useAvatars } from '~/hooks';
import { DateUtil } from '~/utils/DateUtils';

interface Props {
  message?: StreamMessage;
  setShowOverlay?: (arg0: boolean) => void;
  handleMessagePress?: () => void;
}

type RootStackParamList = {
  DMNavigator: {
    screen?: string;
    params: {
      channelId: string;
      messageId: string;
    };
  };
  ChatThreadMessages: {
    channelType: string;
    channelId: string;
    messageId: string;
  };
  ChatChannelMessages: {
    channelType: string;
    channelId: string;
    messageId: string;
  };
};

type NavigationProps = NativeStackNavigationProp<RootStackParamList>;

export const ChatSearchResultItem = ({ message, setShowOverlay, handleMessagePress }: Props) => {
  const navigation = useNavigation<NavigationProps>();
  const { avatarsByChatUserId } = useAvatars();
  const linkTo = useLinkTo();
  const currentUserBookmarks = useGetCurrentUserBookmarksQuery();
  const [deleteBookMark] = useDeleteCurrentUserBookmarksByIdMutation();
  const bookmarksRef = useRef(null);
  const bookmarksList: Bookmark[] = bookmarksRef.current ? bookmarksRef.current : [];

  // @ts-expect-error TS(2339): Property 'id' does not exist on type 'object'.
  const bookMarkID = bookmarksList.find((b) => b.message_id === message.id)?.id;

  useDidUpdate(
    () => {
      if (currentUserBookmarks.data) {
        // @ts-expect-error TS(2322): Type 'GetCurrentUserBookmarksApiResponse' is not a... Remove this comment to see the full error message
        bookmarksRef.current = currentUserBookmarks.data;
      }
    },
    [currentUserBookmarks.data],
    true
  );
  const defaultHandleMessagePress = async () => {
    if (Platform.OS === 'web') {
      if (message?.channel?.isDM) {
        navigation.navigate('DMNavigator', {
          screen: 'DirectMessageList',
          params: { channelId: message?.channel.id, messageId: message?.id },
        });
      } else {
        linkTo(`/chat/${message?.channel?.id}/${message?.id}`);
      }
    } else {
      if (message?.channel?.isDM) {
        navigation.navigate(message?.parent_id ? 'ChatThreadMessages' : 'ChatChannelMessages', {
          channelType: message?.channel?.type,
          channelId: message?.channel?.id,
          messageId: message?.id,
        });
      } else {
        if (message) {
          linkTo(`/chat/${message.channel?.id}/${message.id}`);
        }
      }
    }
    if (setShowOverlay) {
      setShowOverlay(false);
    }
  };

  if (!message) {
    return null;
  }
  const deleteBookmark = () => {
    if (bookMarkID) {
      deleteBookMark({
        id: bookMarkID,
      });
    }
  };

  const RightAction = (prog: any, drag: any) => {
    const trans = Animated.add(drag, 65);
    return (
      <TouchableOpacity
        style={{
          justifyContent: 'center',
          transform: [{ translateX: trans }],
        }}
        onPress={deleteBookmark}
      >
        <HStack backgroundColor="negative" paddingHorizontal="lg" height="full" alignItems="center">
          <Text
            style={{
              color: 'white',
            }}
          >
            削除
          </Text>
        </HStack>
      </TouchableOpacity>
    );
  };

  return (
    <Swipeable renderRightActions={RightAction}>
      <TouchableOpacity onPress={handleMessagePress ?? defaultHandleMessagePress} style={{ flex: 1 }}>
        <VStack space="xs" paddingHorizontal="md" padding="xs" height={40} style={{ flex: 1 }}>
          <HStack space="xs">
            <Text fontWeight="normal" color="onSurfaceBright" lineHeight="2xs" fontFamily="Hiragino Sans">
              {message?.channel?.name}
            </Text>
          </HStack>
          <HStack flex={1}>
            {avatarsByChatUserId[message?.user?.id as string] ? (
              <Image
                source={{ uri: avatarsByChatUserId[message?.user?.id as string]?.avatar }}
                size="xs"
                borderRadius="full"
                alt=""
              />
            ) : (
              <Icon as={Ionicons} name="person-circle-outline" size="4xl" />
            )}
            <VStack flex={1} overflow="hidden" marginLeft="xs">
              <HStack alignItems="center" justifyContent="space-between">
                <Text fontSize="md" color="onSurface" bold lineHeight="md" fontFamily="Hiragino Sans">
                  {message?.user?.name}
                </Text>
                <Text color="onSurfaceBright" fontFamily="Hiragino Sans">
                  {DateUtil.toYYYYMMDDOrMMDD(new Date(message?.created_at as string))}{' '}
                  {DateUtil.tohmm(new Date(message?.created_at as string))}
                </Text>
              </HStack>
              <CustomMessageText message={message} />
            </VStack>
          </HStack>
        </VStack>
      </TouchableOpacity>
    </Swipeable>
  );
};
