import { U_MOTION_API_URL } from '@env';
import {
  createApi,
  fetchBaseQuery,
  retry,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { REHYDRATE } from 'redux-persist';

// @ts-expect-error TS(2307): Cannot find module '../../apps/u-feed-app/src/stor... Remove this comment to see the full error message
import { RootState } from '../../apps/u-feed-app/src/store';

import { setUmotionToken } from './slices/uMotionSessionSlice';

const baseQuery = retry(
  fetchBaseQuery({
    baseUrl: U_MOTION_API_URL,
    prepareHeaders: async (headers, { getState }) => {
      const { uMotionSession } = getState() as RootState;

      if (uMotionSession) {
        headers.set('Authorization', `JWT ${uMotionSession.sessionToken}`);
      }

      headers.set('Content-Type', 'application/json');

      return headers;
    },
  }),
  {
    maxRetries: 0,
  }
);

const sessionQuery = retry(
  fetchBaseQuery({
    baseUrl: U_MOTION_API_URL,
    prepareHeaders: async (headers, { getState }) => {
      const { uMotionSession } = getState() as RootState;

      // @ts-expect-error TS(2304): Cannot find name 'session'.
      if (session) {
        headers.set('Authorization', `Bearer ${uMotionSession.refreshToken}, JWT ${uMotionSession.sessionToken}`);
      }

      headers.set('Access-Control-Allow-Methods', 'GET, POST, DELETE');

      return headers;
    },
  }),
  {
    maxRetries: 0,
  }
);

const baseQueryWithSessionRefresh: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const { uMotionSession } = api.getState() as RootState;

  const refreshToken = async () => {
    const refreshResult = await sessionQuery({ url: '/session', method: 'PUT' }, api, extraOptions);

    if (refreshResult.data) {
      const data: any = refreshResult.data;
      api.dispatch(
        setUmotionToken({
          sessionToken: data?.sessionToken,
          refreshToken: data?.refreshToken,
          expiredAt: data?.expiredAt,
        })
      );
    }
  };

  const now = new Date();
  if (now.valueOf() - uMotionSession.expiredAt < 60) {
    await refreshToken();
  }

  const result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    await refreshToken();
    const retryResult = await baseQuery(args, api, extraOptions);
    return retryResult;
  } else {
    return result;
  }
};

export const baseApi = createApi({
  baseQuery: baseQueryWithSessionRefresh,
  reducerPath: 'UmotionApi',
  keepUnusedDataFor: 300,
  endpoints: (builder) => ({}),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === REHYDRATE && action.payload) {
      return action.payload[reducerPath];
    }
  },
});

export const refreshTokenApi = createApi({
  baseQuery: sessionQuery,
  reducerPath: 'UmotionRefreshTokenApi',
  keepUnusedDataFor: 300,
  endpoints: (builder) => ({}),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === REHYDRATE && action.payload) {
      return action.payload[reducerPath];
    }
  },
});
