import { ParamList } from '~/screens';

let data: ParamList = {
  data: [],
  selectedItems: [],
  unchangeableItems: [],
  onConfirm(result: string[] | number[]): void {},
  onQuery(query: string): void {},
  onCancel(): void {},
};

const getData = () => {
  return data;
};

const setData = (value: ParamList) => {
  data = value;
};

const clearData = () => {
  setData({
    data: [],
    selectedItems: [],
    unchangeableItems: [],
    onConfirm(result: string[] | number[]): void {},
    onQuery(query: string): void {},
    onCancel(): void {},
  });
};

export { getData, setData, clearData };
