import { useRoute } from '@react-navigation/native';
import * as React from 'react';

import { useGetGroupUsersByUserIdQuery } from '~/api/uFeedApi';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';
import { DetailsBuilder } from '~/components/builder/DetailsBuilder';

export const GroupUserDetailScreen: React.FC = () => {
  const { params } = useRoute();

  const queryParams = params ? { ...params } : {};
  // @ts-expect-error TS(2345): Argument of type '{}' is not assignable to paramet... Remove this comment to see the full error message
  const queryResult = useGetGroupUsersByUserIdQuery(queryParams);

  return (
    <BaseScreenBuilder title="グループメンバー">
      <DetailsBuilder
        // @ts-expect-error TS(2322): Type 'UseQueryHookResult<QueryDefinition<GetGroupU... Remove this comment to see the full error message
        queryResult={queryResult}
        emptyMessage="グループがありません"
        fields={[
          {
            key: 'user',
            type: 'user',
            label: '名前',
          },
          {
            key: 'user',
            type: 'user-account',
            label: '会社',
          },
          {
            key: 'roles',
            type: 'group-roles',
            label: 'メンバー種別',
          },
        ]}
      />
    </BaseScreenBuilder>
  );
};
