import { Card } from '@gluestack-ui/themed';
import { VStack, HStack, Text, Button } from '@gluestack-ui/themed-native-base';

export const GyugunIdou = ({ data }: any) => {
  return (
    // @ts-expect-error TS(2322): Type 'false' is not assignable to type 'Responsive... Remove this comment to see the full error message
    <Card marginBottom={2} backgroundColor="white" shadow={false}>
      <VStack space={4}>
        <VStack space={1}>
          <HStack>
            <Text>イベント名: </Text>
            <Text bold>牛群移動</Text>
          </HStack>
          <HStack>
            <Text>牛番号: </Text>
            <Text bold>{data.cow_id}</Text>
          </HStack>
          <HStack>
            <Text>移動先牛群: </Text>
            <Text bold>{data.move_to_group_id}</Text>
          </HStack>
        </VStack>
        <HStack>
          <Text>作業者: </Text>
          <Text bold>{data.worker_name}</Text>
        </HStack>
        <HStack>
          <Button variant="link" width="50%">
            修正
          </Button>
          <Button colorScheme="blueGray" variant="solid" padding={1} rounded={20} width="50%">
            登録
          </Button>
        </HStack>
      </VStack>
    </Card>
  );
};
