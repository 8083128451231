import { useRoute, useNavigation, useLinkTo } from '@react-navigation/native';
import * as React from 'react';
import { Platform } from 'react-native';

import { useGetFarmsQuery } from '~/api/uFeedApi';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';
import { ListBuilder } from '~/components/builder/ListBuilder';

export const FarmListScreen: React.FC = () => {
  const { params } = useRoute();
  const navigation = useNavigation();
  // @ts-expect-error TS(2339): Property 'queryParams' does not exist on type 'obj... Remove this comment to see the full error message
  const queryParams = params?.queryParams || {};
  const queryResult = useGetFarmsQuery(queryParams);
  const linkTo = useLinkTo();

  return (
    <BaseScreenBuilder title="">
      <ListBuilder
        // @ts-expect-error TS(2322): Type 'UseQueryHookResult<QueryDefinition<void, Bas... Remove this comment to see the full error message
        queryResult={queryResult}
        onSelect={(item) =>
          Platform.OS === 'web'
            ? linkTo(`/farms/${item?.id}`)
            : // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
              navigation.navigate('FarmDetail', {
                id: item?.id,
              })
        }
        emptyMessage="牧場データがありません"
      />
    </BaseScreenBuilder>
  );
};
