import { Ionicons } from '@expo/vector-icons';
import { VStack, HStack, Text, Icon } from '@gluestack-ui/themed-native-base';
import * as React from 'react';

import { DateUtil } from '~/utils/DateUtils';

interface Props {
  label: string;
  prevMin: number;
  curMin: number;
  labelColor: string;
  labelTextColor?: string;
  isPreview?: boolean;
}

export const DashboardPanel = ({
  label,
  prevMin,
  curMin,
  labelColor,
  labelTextColor = 'white',
  isPreview = false,
}: Props) => {
  const diffMin = curMin - prevMin;

  return (
    <VStack
      padding={3}
      borderWidth={0}
      borderRadius={10}
      backgroundColor={'gray.100' /*value >= -5 ? '#a1fb8d' : value < -10 ? '#f66' : '#f5ff89'*/}
      flex={1}
      space={1}
    >
      <HStack alignItems="center" justifyContent="space-between">
        <HStack alignItems="center" backgroundColor={labelColor} borderRadius={20} paddingX={2} paddingY={1}>
          <Text
            color={labelTextColor}
            bold
            fontSize={{ base: isPreview ? '3xs' : '2xs', md: 'xs' }}
            ellipsizeMode="head"
          >
            {label}
          </Text>
        </HStack>

        <HStack alignItems="center" space={0}>
          {!prevMin || Math.round(Math.abs(diffMin / prevMin) * 100) === 0 ? null : diffMin < 0 ? (
            <Icon as={Ionicons} name="remove-outline" size={isPreview ? '2xs' : 'sm'} />
          ) : (
            <Icon as={Ionicons} name="add-outline" size={isPreview ? '2xs' : 'sm'} />
          )}
          <Text color="black" bold fontSize={isPreview ? '2xs' : 'lg'}>
            {prevMin ? Math.round(Math.abs((diffMin / prevMin) * 100)) : '-'}
            <Text fontSize={isPreview ? '2xs' : 'xs'} alignSelf="center">
              %
            </Text>
          </Text>
        </HStack>
      </HStack>

      <VStack>
        <HStack flex={1}>
          <Text color="black" fontSize={isPreview ? '3xs' : 'xs'} flexGrow={1}>
            ５日間平均
          </Text>
          <Text color="black" fontSize={isPreview ? '3xs' : 'xs'}>
            {DateUtil.minToHours(Math.round(prevMin))}
          </Text>
        </HStack>
        <HStack>
          <Text color="black" fontSize={isPreview ? '3xs' : 'xs'} flexGrow={1}>
            昨日
          </Text>
          <Text color="black" fontSize={isPreview ? '3xs' : 'xs'}>
            {DateUtil.minToHours(curMin)}
          </Text>
        </HStack>
        <HStack flex={1}>
          <Text color="black" fontSize={isPreview ? '3xs' : 'xs'} flexGrow={1}>
            差分
          </Text>
          <HStack alignItems="center" space={0}>
            {Math.round(Math.abs(diffMin / prevMin) * 100) === 0 ? null : diffMin < 0 ? (
              <Icon as={Ionicons} name="remove-outline" size={isPreview ? '2xs' : 'sm'} />
            ) : (
              <Icon as={Ionicons} name="add-outline" size={isPreview ? '2xs' : 'sm'} />
            )}
            <Text color="black" fontSize={isPreview ? '3xs' : 'xs'}>
              {DateUtil.minToHours(diffMin).replaceAll('-', '')}
            </Text>
          </HStack>
        </HStack>
      </VStack>
    </VStack>
  );
};
