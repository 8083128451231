import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { useState } from 'react';

import { usePostUsersMutation } from '~/api/uFeedApi';
import { BaseFormBuilder, errorMessageBuilder } from '~/components/builder/BaseFormBuilder';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';
import { ScreenWidthModal } from '~/components/ScreenWithModal';

export const AdminUserCreateScreen: React.FC = () => {
  const navigation = useNavigation();
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const [update, { isLoading: isUpdating, data, error }] = usePostUsersMutation();

  const onCancel = () => {
    navigation.goBack();
  };

  const onSubmit = (sendingData: any) => {
    setErrorMessages((errorMessages) => []);
    update({
      body: {
        user: {
          name: sendingData.name,
          email: sendingData.email,
          roles: sendingData.roles.includes('member') ? sendingData.roles : [...sendingData.roles, 'member'],
          password: sendingData.password,
        },
      },
    })
      .unwrap()
      .then((resp) => {
        navigation.goBack();
      })
      .catch((error) => {
        setErrorMessages(errorMessageBuilder(error, fields));
        console.error('update error: ', error);
      });
  };

  const fields = [
    {
      key: 'name',
      label: '名前',
      rules: {
        required: {
          value: true,
          message: '名前を入力してください',
        },
      },
    },
    {
      key: 'email',
      label: 'メールアドレス',
      type: 'email',
      rules: {
        required: {
          value: true,
          message: 'メールアドレスを入力してください',
        },
        pattern: {
          value:
            /^([\w!#$%&'*+\-\/=?^`{|}~]+(\.[\w!#$%&'*+\-\/=?^`{|}~]+)*|"([\w!#$%&'*+\-\/=?^`{|}~. ()<>\[\]:;@,]|\\[\\"])+")@(([a-zA-Z\d\-]+\.)+[a-zA-Z]+|\[(\d{1,3}(\.\d{1,3}){3}|IPv6:[\da-fA-F]{0,4}(:[\da-fA-F]{0,4}){1,5}(:\d{1,3}(\.\d{1,3}){3}|(:[\da-fA-F]{0,4}){0,2}))\])$/,
          message: 'メールアドレスの形式に誤りがあります',
        },
      },
    },
    {
      key: 'password',
      label: 'パスワード',
      type: 'password',
      rules: {
        required: {
          value: true,
          message: 'パスワードを入力してください',
        },
        minLength: {
          value: 8,
          message: '8文字以上入力してください',
        },
        pattern: {
          value: /^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/,
          message: 'パスワードは半角英数・記号のみで入力してください',
        },
      },
    },
    {
      key: 'roles',
      label: 'システム権限',
      type: 'sectionedMultiselect',
      options: roleMaster,
    },
  ];

  return (
    <ScreenWidthModal title="ユーザー作成">
      <BaseScreenBuilder title="ユーザー作成">
        <BaseFormBuilder
          isUpdating={isUpdating}
          error={error}
          // @ts-expect-error TS(2322): Type '({ key: string; label: string; rules: { requ... Remove this comment to see the full error message
          fields={fields}
          errorMessage={errorMessages}
          defaultValues={{
            roles: ['member'],
          }}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      </BaseScreenBuilder>
    </ScreenWidthModal>
  );
};

const roleMaster = [
  { id: 'account_admin', name: '企業管理者' },
  { id: 'group_admin', name: 'グループ管理者' },
  { id: 'member_admin', name: 'メンバー管理者' },
];
