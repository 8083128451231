import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { Ionicons } from '@expo/vector-icons';
import { ScrollView, Icon } from '@gluestack-ui/themed-native-base';
import { useLinkTo, useRoute } from '@react-navigation/native';
import { parse, addMilliseconds, format } from 'date-fns';
import * as React from 'react';
import { useState, useMemo } from 'react';
import { CalendarProvider, ExpandableCalendar, CalendarUtils } from 'react-native-calendars';

import { useGetAppointmentsReservableSlotsQuery } from '~/api/uFeedApi';
import { AppointmentSlotList } from '~/components/AppointmentSlotList';
import { APPLICABLE_SLOT_OFFSET_MS } from '~/constants';
import { DateUtil } from '~/utils/DateUtils';
import { generateTimeArray } from '~/utils/generateTimeArray';

const getDate = (date = undefined) => {
  return CalendarUtils.getCalendarDateString(date ? parse(date, 'yyyyMMdd', new Date()) : new Date());
};

const createSlots = (queryData: any, date: any) => {
  const slotsByStartAt =
    queryData?.reduce((acc: any, cur: any) => {
      const start_at = DateUtil.tohmm(new Date(cur?.start_at));

      return {
        ...acc,
        [start_at]: cur,
        date,
      };
    }, {}) || {};

  return generateTimeArray(7, 19, 30).map((time) =>
    slotsByStartAt[time]
      ? {
          ...slotsByStartAt[time],
          start_at: time,
          date,
        }
      : {
          id: undefined,
          start_at: time,
          date,
        }
  );
};

const renderArrow = (direction: any) => {
  return direction === 'left' ? (
    <Icon as={Ionicons} name="chevron-back-outline" fontSize="2xl" />
  ) : (
    <Icon as={Ionicons} name="chevron-forward-outline" fontSize="2xl" />
  );
};

export const AppointmentScheduleApplyScreen: React.FC = () => {
  const route = useRoute();
  // @ts-expect-error TS(2339): Property 'date' does not exist on type 'object'.
  const [currentDate, setCurrentDate] = useState(getDate(route?.params?.date));
  const initialDate = useMemo(() => getDate(), []);
  const linkTo = useLinkTo();

  useDidUpdate(() => {
    // @ts-expect-error TS(2339): Property 'date' does not exist on type 'object'.
    setCurrentDate(getDate(route?.params?.date));
    // @ts-expect-error TS(2339): Property 'date' does not exist on type 'object'.
  }, [route?.params?.date]);

  const getAppointmentsSlotsQuery = useGetAppointmentsReservableSlotsQuery({
    // @ts-expect-error TS(2339): Property 'serviceId' does not exist on type 'objec... Remove this comment to see the full error message
    appointmentServiceId: route?.params?.serviceId,
  });

  const filterSlotsByDate = (slot: any) => {
    const dateStr = DateUtil.toYYYYMMDD(new Date(slot.start_at), '-');
    return dateStr === currentDate;
  };

  const filterSlotsByProviderId = (slot: any) => {
    // @ts-expect-error TS(2339): Property 'providerId' does not exist on type 'obje... Remove this comment to see the full error message
    return slot?.provider_id === route?.params?.providerId;
  };

  const minDate = useMemo(() => {
    return format(addMilliseconds(new Date(), APPLICABLE_SLOT_OFFSET_MS), 'yyyy-MM-dd');
  }, []);

  const slots = useMemo(() => {
    if (!getAppointmentsSlotsQuery.data || !currentDate) {
      return [];
    }
    return createSlots(
      getAppointmentsSlotsQuery.data?.filter(filterSlotsByDate).filter(filterSlotsByProviderId) || [],
      currentDate
    );
  }, [getAppointmentsSlotsQuery.data, currentDate]);

  const onDayPress = React.useCallback(
    (day: any) => {
      // @ts-expect-error TS(2339): Property 'serviceId' does not exist on type 'objec... Remove this comment to see the full error message
      const to = `/appointments/services/${route?.params?.serviceId}/apply/${day.dateString.replaceAll('-', '')}`;
      linkTo(to);
    },
    // @ts-expect-error TS(2339): Property 'serviceId' does not exist on type 'objec... Remove this comment to see the full error message
    [route?.params?.serviceId]
  );

  return (
    <CalendarProvider date={initialDate}>
      <ExpandableCalendar
        firstDay={1}
        minDate={minDate}
        renderArrow={renderArrow}
        markedDates={{
          [currentDate]: {
            selected: true,
          },
        }}
        onDayPress={onDayPress}
      />
      <ScrollView>
        <AppointmentSlotList slots={slots} />
      </ScrollView>
    </CalendarProvider>
  );
};
