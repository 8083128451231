import { useRoute, useNavigation } from '@react-navigation/native';
import * as React from 'react';

import { usePostFarmsByFarmIdGroupsMutation } from '~/api/uFeedApi';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';
import { CreateFormBuilder } from '~/components/builder/CreateFormBuilder';

export const GroupCreateScreen: React.FC = () => {
  const { params } = useRoute();
  const navigation = useNavigation();
  // @ts-expect-error TS(2339): Property 'queryParams' does not exist on type 'obj... Remove this comment to see the full error message
  const queryParams = params?.queryParams || {};

  const [update, { isLoading: isUpdating, data, error }] = usePostFarmsByFarmIdGroupsMutation();

  return (
    <BaseScreenBuilder title="">
      <CreateFormBuilder
        isUpdating={isUpdating}
        // @ts-expect-error TS(2322): Type 'FetchBaseQueryError | SerializedError | unde... Remove this comment to see the full error message
        error={error}
        fields={[
          {
            key: 'name',
            label: '名前',
          },
        ]}
        onSubmit={(sendingData) =>
          update({
            farmId: queryParams?.farmId,
            body: {
              ...sendingData,
              details: [{ cattle_group: null, id: 1, pend: '123' }],
            },
          })
            .unwrap()
            .then((resp) => navigation.goBack())
            .catch((error) =>
              console.error(
                'GroupCreateScreen: update error: sendingData, error',
                {
                  farmId: queryParams?.farmId,
                  groups: {
                    ...sendingData,
                    details: [{ cattle_group: null, id: 1, pend: '123' }],
                  },
                },
                error
              )
            )
        }
      />
    </BaseScreenBuilder>
  );
};
