import { useDidMount } from '@better-typed/react-lifecycle-hooks';
import { Button } from '@gluestack-ui/themed-native-base';
import { useNavigation, useRoute } from '@react-navigation/native';
import * as Location from 'expo-location';
import * as React from 'react';
//import MapView, { Marker, LatLng, Region, PROVIDER_GOOGLE } from 'react-native-maps';
import { useEffect, useState } from 'react';
import { StyleSheet, View, Dimensions } from 'react-native';

import { ScreenWidthModal } from '~/components/ScreenWithModal';

export const MapScreen: React.FC = () => {
  const navigation = useNavigation();
  const { params } = useRoute();

  const [location, setLocation] = useState(null);

  // @ts-expect-error TS(2304): Cannot find name 'Region'.
  const initialRegion: Region = {
    // @ts-expect-error TS(2339): Property 'coordinate' does not exist on type 'obje... Remove this comment to see the full error message
    latitude: params?.coordinate?.latitude,
    // @ts-expect-error TS(2339): Property 'coordinate' does not exist on type 'obje... Remove this comment to see the full error message
    longitude: params?.coordinate?.longitude,
    latitudeDelta: 0.046,
    longitudeDelta: 0.026,
  };

  // @ts-expect-error TS(2339): Property 'coordinate' does not exist on type 'obje... Remove this comment to see the full error message
  const [coordinate, setCoordinate] = React.useState(params?.coordinate);

  useDidMount(() => {
    (async () => {
      const { status } = await Location.requestForegroundPermissionsAsync();
      if (status !== 'granted') {
        return;
      }

      const location = await Location.getLastKnownPositionAsync({});
      // @ts-expect-error TS(2345): Argument of type 'LocationObject | null' is not as... Remove this comment to see the full error message
      setLocation(location);
    })();
  });

  useEffect(() => {
    navigation.setOptions({
      headerLeft: () => (
        <Button
          onPress={() => {
            navigation.goBack();
          }}
          variant="unstyled"
        >
          キャンセル
        </Button>
      ),
      headerRight: () => (
        <Button
          onPress={() => {
            // @ts-expect-error TS(2339): Property 'onClose' does not exist on type 'object'... Remove this comment to see the full error message
            params?.onClose(coordinate);
            navigation.goBack();
          }}
          variant="unstyled"
        >
          選択した位置に決定
        </Button>
      ),
    });
  }, [navigation, coordinate]);

  return (
    <ScreenWidthModal>
      <View style={styles.container} />;
    </ScreenWidthModal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  map: {
    width: Dimensions.get('window').width,
    height: Dimensions.get('window').height,
  },
});
