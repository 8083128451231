import { Box, VStack, HStack, Text } from '@gluestack-ui/themed-native-base';

export const PreviewCattleParams = ({ data }: any) => {
  if (!data) return null;
  return (
    <Box backgroundColor="white" borderRadius={20} padding={4} marginBottom={4}>
      <VStack space={4}>
        {Object.keys(data).map((key) => {
          return (
            <HStack key={key}>
              <Text width="50%" fontWeight="bold">
                {key}
              </Text>
              <Text width="50%">{data[key]}</Text>
            </HStack>
          );
        })}
      </VStack>
    </Box>
  );
};
