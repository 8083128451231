import { VStack, Text } from '@gluestack-ui/themed-native-base';
import React from 'react';

import { MilkAmountChartSwitcher } from '../MilkAmountChartSwitcher';

import { useGetGroupsByGroupIdQuery } from '~/api/uFeedApi';
import { DetailList } from '~/components/builder/DetailList';
import { DetailListItem } from '~/components/builder/DetailListItem';
import { DateUtil } from '~/utils/DateUtils';

interface Props {
  farmId: number;
  groupId: number;
  endDate: string;
  range?: number;
  type?: 'group' | 'all' | 'count' | 'candle';
}

export const PreviewMilkAmountGraphs: React.FC<Props> = React.memo(
  ({ farmId, groupId, endDate, range = 30, type = 'group' }) => {
    const group = useGetGroupsByGroupIdQuery({
      groupId,
    });

    if (group.isLoading || group.isError) {
      return null;
    }

    return (
      <VStack
        marginTop={1}
        marginBottom={2}
        space={0}
        backgroundColor="gray.50"
        borderRadius={6}
        borderWidth={1}
        borderColor="gray.100"
        overflow="hidden"
      >
        <DetailList space={2} padding={2}>
          <Text marginBottom={2}>搾乳量グラフ詳細</Text>
          <DetailListItem title="グループ" value={group.data?.name || ''} />
          <DetailListItem
            title="日付"
            value={`${endDate[0]}${endDate[1]}${endDate[2]}${endDate[3]}/${endDate[4]}${endDate[5]}/${endDate[6]}${endDate[7]}`}
          />
          <DetailListItem title="期間" value={`${range}日`} />
          <DetailListItem
            title="グラフ種別"
            value={MilkAmountGraphType.find((graphType) => graphType.key == type)?.value}
          />
        </DetailList>
        <MilkAmountChartSwitcher
          farmId={farmId}
          groupId={groupId}
          endDate={DateUtil.dateHourToDate(endDate)}
          range={range}
          type={type ? type : 'all'}
          isPreview
        />
      </VStack>
    );
  }
);
const MilkAmountGraphType: { key: 'group' | 'all' | 'count' | 'candle'; value: string }[] = [
  { key: 'group', value: '牛群別' },
  { key: 'all', value: '全て' },
  { key: 'count', value: '産次別' },
  { key: 'candle', value: '変化量' },
];
