import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { useState } from 'react';

import { useGetCurrentUserQuery } from '~/api/uFeedApi';

export const useHelpUrl = () => {
  const currentUser = useGetCurrentUserQuery({});
  const [helpUrl, setHelpUrl] = useState('');

  const generateHelpUrl = () => {
    if (
      currentUser.data?.roles?.includes('account_admin') ||
      currentUser.data?.roles?.includes('group_admin') ||
      currentUser.data?.roles?.includes('member_admin')
    ) {
      setHelpUrl('https://help.chumly.me/admin/');
    } else {
      setHelpUrl('https://help.chumly.me/user/');
    }
  };

  useDidUpdate(
    () => {
      generateHelpUrl();
    },
    [currentUser],
    true
  );

  return { helpUrl };
};
