import { useEffect, useState } from 'react';

/**
 * 指定したインターバルごとに更新される現在時刻を利用
 */
export function useCurrentTime(options: { intervalSec: number }) {
  const [currentTime, setCurrentTime] = useState(Date.now());

  useEffect(() => {
    setInterval(() => {
      setCurrentTime(Date.now());
    }, 1000 * options.intervalSec);
  }, [options.intervalSec]);

  return currentTime;
}
