import { VStack } from '@gluestack-ui/themed-native-base';
import React from 'react';

export const DetailList: React.FC<React.PropsWithChildren & React.ComponentProps<typeof VStack>> = ({
  children,
  ...otherProps
}) => {
  return (
    <VStack space={8} {...otherProps}>
      {children}
    </VStack>
  );
};
