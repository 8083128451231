// Import the functions you need from the SDKs you need
import {
  FIRE_BASE_APIKEY,
  FIRE_BASE_AUTHDOMAIN,
  FIRE_BASE_PROJECTID,
  FIRE_BASE_STORAGEBUCKET,
  FIRE_BASE_MESSAGINGSENDERID,
  FIRE_BASE_APPID,
  FIRE_BASE_MEASUREMENTID,
} from '@env';
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: FIRE_BASE_APIKEY,
  authDomain: FIRE_BASE_AUTHDOMAIN,
  projectId: FIRE_BASE_PROJECTID,
  storageBucket: FIRE_BASE_STORAGEBUCKET,
  messagingSenderId: FIRE_BASE_MESSAGINGSENDERID,
  appId: FIRE_BASE_APPID,
  measurementId: FIRE_BASE_MEASUREMENTID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
