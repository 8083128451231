import { useDidMount, useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { AUTH0_CLIENT_ID, AUTH0_DISCOVERY_URL, AUTH0_AUDIENCE } from '@env';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { skipToken } from '@reduxjs/toolkit/query/react';
import * as AuthSession from 'expo-auth-session';
import { TokenResponse } from 'expo-auth-session';
import * as WebBrowser from 'expo-web-browser';
import { useState } from 'react';
import { Platform } from 'react-native';
import toQueryString from 'to-querystring';

import { baseApi } from '~/api/baseQuery';
import {
  useGetCurrentUserQuery,
  useGetCurrentUserFarmsQuery,
  useDeletePushNotificationsTokenRemoveMutation,
} from '~/api/uFeedApi';
import { useStreamChatAuthContext } from '~/contexts/StreamChatContext';
import { analytics } from '~/lib/ReactNativeFirebase';
import { uMotionSessionSelector, setUmotionToken } from '~/packages/u-motion-api/slices/uMotionSessionSlice';
import { setToken, sessionSelector } from '~/slices/sessionSlice';
import { setRegisteredPushToken } from '~/slices/streamChatStateSlice';
import { useAppSelector, useAppDispatch } from '~/store';
import { isRunningInExpoGo } from '~/utils/isRunningInExpoGo';

const redirectUri = AuthSession.makeRedirectUri({ path: 'callback' });
WebBrowser.maybeCompleteAuthSession();

export const useAuth = () => {
  const dispatch = useAppDispatch();
  const session = useAppSelector(sessionSelector);
  const uMotionSession = useAppSelector(uMotionSessionSelector);
  const tokenResponse = session?.tokenConfigJSON ? new TokenResponse(JSON.parse(session?.tokenConfigJSON)) : undefined;
  const hasSession = tokenResponse?.accessToken || uMotionSession.sessionToken;
  const currentUserQuery = hasSession ? useGetCurrentUserQuery({}) : useGetCurrentUserQuery(skipToken);
  const currentUserFarmsQuery = hasSession ? useGetCurrentUserFarmsQuery() : useGetCurrentUserFarmsQuery(skipToken);
  const isLoggedIn = !!(hasSession && currentUserQuery.isSuccess);
  const isLoading = hasSession && currentUserQuery.isLoading;
  const { logout } = useStreamChatAuthContext();
  const isUmotionUser = !!uMotionSession?.sessionToken;
  const [removeToken] = useDeletePushNotificationsTokenRemoveMutation();
  const [hasCreatedUser, setHasCreatedUser] = useState<boolean>(false);

  useDidMount(() => {
    const init = async () => {
      const hasCreatedUserData = await AsyncStorage.getItem('hasCreatedUser');
      setHasCreatedUser(!!hasCreatedUserData);
    };
    init();
  });

  useDidUpdate(() => {
    if (currentUserQuery.isSuccess && !hasCreatedUser) {
      AsyncStorage.setItem('hasCreatedUser', 'true');
      setHasCreatedUser(true);
    }
    if (Platform.OS !== 'web') {
      if (currentUserQuery.isSuccess && currentUserFarmsQuery.isSuccess && !isRunningInExpoGo) {
        // @ts-expect-error TS(2349): This expression is not callable.
        analytics()?.setUserId(`${currentUserQuery?.data?.id ?? 'unknown'}`);
        // @ts-expect-error TS(2349): This expression is not callable.
        analytics()?.setUserProperties({
          farm: currentUserFarmsQuery?.data?.[0]?.name || 'unknown',
          type: currentUserQuery?.data?.email?.match(/@desamis\.co\.jp/) ? 'dd' : 'user',
        });
      }
    }
  }, [currentUserQuery.isSuccess, currentUserFarmsQuery?.isSuccess]);

  const handleLogin = async () => {
    const discovery = await AuthSession.fetchDiscoveryAsync(AUTH0_DISCOVERY_URL).catch((error) =>
      console.error('fetchDiscoveryAsync() error: ', error)
    );

    if (!discovery) {
      console.error('discovery is empty');
      return;
    }

    const request = new AuthSession.AuthRequest({
      usePKCE: true,
      responseType: AuthSession.ResponseType.Code,
      codeChallengeMethod: AuthSession.CodeChallengeMethod.S256,
      clientId: AUTH0_CLIENT_ID,
      redirectUri,
      scopes: ['offline_access openid profile email'],
      extraParams: {
        audience: AUTH0_AUDIENCE,
        access_type: 'offline',
      },
    });

    const result = await request
      .promptAsync(discovery, {})
      .catch((error) => console.error('promptAsync error: ', error));

    if (result?.type === 'success') {
      const tokenResponse = await AuthSession.exchangeCodeAsync(
        {
          clientId: AUTH0_CLIENT_ID,
          code: result.params.code,
          redirectUri,
          extraParams: {
            // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
            code_verifier: request.codeVerifier,
          },
        },
        discovery
      );
      const tokenConfigJSON = JSON.stringify({
        ...tokenResponse?.getRequestConfig(),
        idToken: '',
      });
      dispatch(
        setToken({
          tokenEndpoint: discovery?.tokenEndpoint,
          tokenConfigJSON,
        })
      );
    } else {
      console.error('promptAsync result not success: ', result);
    }
  };

  const handleLogout = async () => {
    await AsyncStorage.removeItem('hasCreatedUser');
    setHasCreatedUser(false);

    if (uMotionSession?.sessionToken) {
      dispatch(
        setUmotionToken({
          // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
          sessionToken: undefined,
          // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
          refreshToken: undefined,
          // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'number... Remove this comment to see the full error message
          expiredAt: undefined,
        })
      );
      dispatch(baseApi.util.resetApiState());
      return;
    }

    const discovery = await AuthSession.fetchDiscoveryAsync(AUTH0_DISCOVERY_URL).catch((error) =>
      console.error('fetchDiscoveryAsync() error: ', error)
    );

    if (!discovery) {
      console.error('discovery is empty');
      return;
    }

    if (Platform.OS !== 'web') {
      if (session?.tokenConfigJSON) {
        await AuthSession.revokeAsync({ token: session?.tokenConfigJSON }, discovery);
      }
    }

    const logoutOpts = {
      client_id: AUTH0_CLIENT_ID,
      returnTo: redirectUri,
    };
    const logoutUrl = `${AUTH0_DISCOVERY_URL}/v2/logout?` + toQueryString(logoutOpts);

    await WebBrowser.openAuthSessionAsync(logoutUrl, redirectUri);

    dispatch(baseApi.util.resetApiState());

    // await しないとエラーが出るのでこれらの処理を await する
    try {
      await removePushToken();
      await logout();
    } catch (error) {
      console.error('removePushToken() error: ', error);
    }
    dispatch(
      setToken({
        tokenConfigJSON: undefined,
        tokenEndpoint: undefined,
      })
    );
    dispatch(baseApi.util.resetApiState());
  };

  const pushProvider = () => {
    switch (Platform.OS) {
      case 'ios':
      case 'macos':
        return 'apn';
      default:
      case 'android':
        return 'firebase';
    }
  };

  const removePushToken = async () => {
    const oldToken = await AsyncStorage.getItem('@chumly_push_token');
    if (oldToken == null) return;

    removeToken({ body: { push_token: oldToken, push_provider_name: pushProvider() } });
    AsyncStorage.removeItem('@chumly_push_token');
    dispatch(setRegisteredPushToken(null));
  };

  return { handleLogin, handleLogout, isLoggedIn, hasSession, isUmotionUser, isLoading, hasCreatedUser };
};
