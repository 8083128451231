import { VStack, HStack, Text, Avatar, Box } from '@gluestack-ui/themed-native-base';

import { usePatchAppointmentsByAppointmentSlotIdCancelMutation } from '~/api/uFeedApi';
import { Alert } from '~/components/Alert';
import { useCustomToast } from '~/hooks';
import { DateUtil } from '~/utils/DateUtils';

export const MyAppointmentListItem = ({ data }: any) => {
  const [cancel, { isLoading: isLoadingCancel, isSuccess: isCancelSuccess, reset: resetCancel }] =
    usePatchAppointmentsByAppointmentSlotIdCancelMutation();
  const startAt = new Date(data?.start_at);
  const startAtStr = `${DateUtil.toJapaneseYYYYMMDDOrMMDD(startAt)} (${DateUtil.toDayOfWeek(startAt)}) ${DateUtil.tohmm(
    startAt
  )}`;
  const { show } = useCustomToast();

  const onCancelPress = (appointmentSlotId: number) => {
    Alert.alert(
      '確認',
      `予約をキャンセルします。サービス提供者とあなたの連絡用チャンネルにキャンセル通知が送信されます`,
      [
        {
          text: 'キャンセル',
        },
        {
          text: 'OK',
          onPress: async () => {
            cancel({
              appointmentSlotId,
            })
              .unwrap()
              .then(() => {
                resetCancel();
                show('予約をキャンセルしました');
              })
              .catch((error) => {
                const errorMessage = `予約のキャンセルに失敗しました`;
                show(errorMessage);
                console.error(errorMessage, error);
              });
          },
        },
      ]
    );
  };

  return (
    <>
      <HStack space={4}>
        <VStack space={2} flex={1}>
          <Text fontSize="lg" bold>
            {startAtStr}
          </Text>
          <Box backgroundColor="gray.100" borderRadius={10} padding={4}>
            <VStack space={4} flex={1}>
              <VStack space={4}>
                <HStack space={2}>
                  <Text bold flexShrink={1} numberOfLines={2}>
                    {data?.appointment_service?.name}
                  </Text>
                </HStack>
                <HStack space={2}>
                  <Text flexShrink={1} numberOfLines={3}>
                    {data?.appointment_service?.description}
                  </Text>
                </HStack>
                <HStack space={2} alignItems="center">
                  <Avatar source={{ uri: data?.booked_by?.avatar }} size="sm" />
                  <Text>{data?.booked_by?.name}</Text>
                </HStack>
              </VStack>
            </VStack>
          </Box>
        </VStack>
      </HStack>
    </>
  );
};
