import { Ionicons } from '@expo/vector-icons';
import { HStack, Icon, Text } from '@gluestack-ui/themed-native-base';
import { useLinkTo, useNavigation } from '@react-navigation/native';
import React from 'react';
import { Platform, TouchableOpacity } from 'react-native';

import { useStreamChatContext } from '~/contexts/StreamChatContext';

interface Props {
  channel: object;
}

export const UsersChannelItem: React.FC<Props> = ({ channel }) => {
  const navigation = useNavigation();
  const { setAppChannel } = useStreamChatContext();
  const linkTo = useLinkTo();
  return (
    <TouchableOpacity
      onPress={() => {
        if (Platform.OS === 'web') {
          // @ts-expect-error TS(2339): Property 'id' does not exist on type 'object'.
          linkTo(`/chat/${channel.id}`);
        } else {
          // @ts-expect-error TS(2345): Argument of type 'object' is not assignable to par... Remove this comment to see the full error message
          setAppChannel(channel);
          // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
          navigation.navigate('ChatChannelMessages', { channelId: channel.id });
        }
      }}
    >
      <HStack alignItems="center" justifyContent="space-between">
        <HStack alignItems="center" space={2} paddingY={2}>
          <Icon as={<Ionicons name="chatbubble-outline" />} fontWeight={500} />
          <Text fontSize="md" fontWeight={500}>
            {/* @ts-expect-error TS(2339): Property 'data' does not exist on type 'object'. */}
            {channel.data.name}
          </Text>
        </HStack>
      </HStack>
    </TouchableOpacity>
  );
};
