import { Ionicons } from '@expo/vector-icons';
import { Icon } from '@gluestack-ui/themed-native-base';
import * as React from 'react';

interface Props {
  selected?: boolean;
}

export const SelectedIcon: React.FC<Props> = ({ selected }) => {
  return selected ? <Icon as={Ionicons} name="checkmark-outline" size="md" /> : null;
};
