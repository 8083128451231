import { useCallback, useEffect, useMemo, useState } from 'react';

import { useAvatars } from '~/hooks';
import * as Sentry from '~/lib/Sentry';
import type { NativeSyntheticEvent, ImageErrorEventData } from 'react-native';

export const useCustomMessageAvatar = (userId?: string) => {
  const { avatarsByChatUserId, isLoading } = useAvatars();

  const [isLoadError, setLoadError] = useState<boolean>(false);

  const avatar = useMemo(() => {
    if (!userId || isLoading || !Object.keys(avatarsByChatUserId).length) {
      return;
    }
    return avatarsByChatUserId[userId]?.avatar;
  }, [avatarsByChatUserId, userId, isLoading]);

  const onError = useCallback((error: NativeSyntheticEvent<ImageErrorEventData>) => {
    setLoadError(true);
    Sentry.captureException(error);
  }, []);

  const onLoad = useCallback(() => {
    setLoadError(false);
  }, []);

  useEffect(() => {
    setLoadError(false);
  }, [avatar]);

  return {
    isLoadError,
    avatar,
    onError,
    onLoad,
  };
};
