import { VStack, Input, Box } from '@gluestack-ui/themed-native-base';
import * as DocumentPicker from 'expo-document-picker';
import { Logic } from 'native-base-form-builder';
import { useState } from 'react';
import { useController } from 'react-hook-form';
import { TouchableOpacity } from 'react-native';

import { AlertBox } from '~/components/builder/AlertBox';
import { FormFieldLabel } from '~/components/builder/FormFieldLabel';
import { Icon } from '~/components/builder/Icon';

export const FormFiles: React.FC<React.ComponentProps<typeof Logic>> = ({
  label,
  name,
  rules,
  shouldUnregister,
  defaultValue,
  control,
  options,
  isRequired,
}) => {
  const { field } = useController({
    name,
    shouldUnregister,
    defaultValue,
    control,
  });
  const [fileUploadErrorMessage, setFileUplaodErrorMessage] = useState('');
  const [files, setFiles] = useState([]);

  const onRemoveFilePress = (removeFile: any) => {
    // @ts-expect-error TS(2339): Property 'uri' does not exist on type 'never'.
    setFiles(files.filter((file) => file.uri !== removeFile.uri));
    field.onChange([...files]);
  };

  const onFileUploadPress = async () => {
    const result = await DocumentPicker.getDocumentAsync({}).catch((error) =>
      console.error('DocumentPicker.getDocumentAsync() failed: ', error)
    );

    if (!result) {
      setFileUplaodErrorMessage('ファイルの添付時に問題が発生しました');
      return;
    }

    // @ts-expect-error TS(2322): Type 'DocumentPickerResult' is not assignable to t... Remove this comment to see the full error message
    setFiles([...files, result]);
    field.onChange([...files, result]);
  };

  return (
    <VStack space={2} flex={1}>
      {label && <FormFieldLabel label={label} isRequired={isRequired} />}
      {files.map((file, i) => (
        <TouchableOpacity onPress={onFileUploadPress} key={i}>
          <Input
            variant="outline"
            size="lg"
            borderColor="black"
            // @ts-expect-error TS(2339): Property 'assets' does not exist on type 'never'.
            value={file.assets[0]?.name}
            editable={false}
            paddingX={3}
            paddingY={3}
            InputLeftElement={
              <Box padding={2}>
                <Icon name="document-outline" />
              </Box>
            }
            InputRightElement={
              <TouchableOpacity onPress={() => onRemoveFilePress(file)}>
                <Box padding={2}>
                  <Icon name="trash-outline" />
                </Box>
              </TouchableOpacity>
            }
          />
        </TouchableOpacity>
      ))}
      <TouchableOpacity onPress={onFileUploadPress}>
        <Input
          variant="outline"
          size="lg"
          borderColor="black"
          editable={false}
          paddingX={3}
          paddingY={3}
          placeholder="タップしてファイルを追加"
          InputLeftElement={
            <Box padding={2}>
              <Icon name="document-outline" />
            </Box>
          }
          onPressIn={onFileUploadPress}
          onFocus={onFileUploadPress}
        />
      </TouchableOpacity>
      {fileUploadErrorMessage ? <AlertBox type="error" message={fileUploadErrorMessage} /> : null}
    </VStack>
  );
};
