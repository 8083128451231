import { Divider, VStack } from '@gluestack-ui/themed-native-base';

import { GetAppointmentsApiResponse } from '~/api/uFeedApi';
import { AppointmentListItem } from '~/components/AppointmentListItem';

interface Props {
  slots: GetAppointmentsApiResponse;
}

export const AppointmentList: React.FC<Props> = ({ slots }) => {
  return (
    <VStack space={6} divider={<Divider />}>
      {slots.map((slot, index) => {
        return <AppointmentListItem data={slot} testID={`AppointmentListItem-${index}`} key={slot?.id} />;
      })}
    </VStack>
  );
};
