import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { useRoute, useNavigation } from '@react-navigation/native';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import * as React from 'react';
import { useState } from 'react';

import {
  useGetGroupsByGroupIdQuery,
  useGetFarmsByFarmIdUmCattleGroupsQuery,
  usePostFarmsByFarmIdGroupsMutation,
  usePutGroupsByGroupIdMutation,
} from '~/api/uFeedApi';
import { BaseFormBuilder, errorMessageBuilder } from '~/components/builder/BaseFormBuilder';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';
import { ScreenWidthModal } from '~/components/ScreenWithModal';

export const AdminGroupCreateEditScreen: React.FC = () => {
  const { params } = useRoute();
  const navigation = useNavigation();
  const queryParams = params ? { ...params } : {};
  // @ts-expect-error TS(2339): Property 'groupId' does not exist on type '{}'.
  const group = useGetGroupsByGroupIdQuery(queryParams?.groupId ? { groupId: queryParams?.groupId } : skipToken);
  // @ts-expect-error TS(2345): Argument of type '{}' is not assignable to paramet... Remove this comment to see the full error message
  const umCattleGroups = useGetFarmsByFarmIdUmCattleGroupsQuery(queryParams);
  const [post, postResult] = usePostFarmsByFarmIdGroupsMutation();
  const [put, putResult] = usePutGroupsByGroupIdMutation();
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [defaultValues, setDefaultValues] = useState({});
  // @ts-expect-error TS(2339): Property 'groupId' does not exist on type '{}'.
  const isPost = !queryParams?.groupId;

  const updateDefaultValues = () => {
    if (!group.isSuccess) {
      return;
    }
    const { data } = group;

    setDefaultValues({
      //farmId: queryParams?.farmId,
      name: data.name,
      details: data.details,
      creatorInMembers: false,
    });
  };

  useDidUpdate(updateDefaultValues, [group]);

  const onSubmit = (sendingData: any) => {
    setErrorMessages((errorMessages) => []);
    const { name, details, creatorInMembers } = sendingData;
    if (isPost) {
      post({
        // @ts-expect-error TS(2339): Property 'farmId' does not exist on type '{}'.
        farmId: queryParams.farmId,
        body: {
          group: {
            name,
            creator_in_members: creatorInMembers,
            details: details == 'none' ? [] : details ? details : [],
          },
        },
      })
        .unwrap()
        .then((resp) => {
          navigation.goBack();
        })
        .catch((error) => {
          setErrorMessages(errorMessageBuilder(error, fields));
          console.error('update error: ', error);
        });
    } else {
      put({
        // @ts-expect-error TS(2339): Property 'groupId' does not exist on type '{}'.
        groupId: queryParams?.groupId,
        body: {
          group: {
            name,
            details: details == 'none' ? [] : details ? details : [],
          },
        },
      })
        .unwrap()
        .then((result) => navigation.goBack())
        .catch((error) => {
          setErrorMessages(errorMessageBuilder(error, fields));
          console.error('update error: ', error);
        });
    }
  };

  const onCancel = () => {
    navigation.goBack();
  };

  const getOptions = (umCattleGroups: any) => (umCattleGroups ? umCattleGroups : []);

  const fields = [
    {
      key: 'name',
      label: '名前',
      rules: {
        required: {
          value: true,
          message: '名前を入力してください',
        },
      },
    },
    {
      type: 'groupDetails',
      key: 'details',
      label: '詳細',
      options: getOptions(umCattleGroups.data),
      rules: {
        required: {
          value: true,
          message: '詳細を入力してください',
        },
      },
    },
  ];

  if (isPost) {
    // @ts-expect-error TS(2345): Argument of type '{ key: string; label: string; ty... Remove this comment to see the full error message
    fields.push({
      key: 'creatorInMembers',
      label: '自分もメンバーになる',
      type: 'singleCheckbox',
    });
  }

  return (
    <ScreenWidthModal title="管理者用グループ追加">
      <BaseScreenBuilder title="管理者用グループ追加">
        <BaseFormBuilder
          // @ts-expect-error TS(2322): Type 'UseQueryHookResult<QueryDefinition<GetGroups... Remove this comment to see the full error message
          queryResult={group}
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          onCancel={onCancel}
          update={isPost ? post : put}
          //updateResult={{ isUpdating, data }}
          emptyMessage="グループがありません"
          // error={error || umCattleGroups.error}
          // isUpdating={isUpdating}
          errorMessage={errorMessages}
          // @ts-expect-error TS(2322): Type '({ key: string; label: string; rules: { requ... Remove this comment to see the full error message
          fields={fields}
        />
      </BaseScreenBuilder>
    </ScreenWidthModal>
  );
};
