// eslint-disable no-irregular-whitespace
import * as React from 'react';

import { ScreenWidthAdjuster } from '~/components/ScreenWidthAdjuster';

export const TermsOfUseScreen: React.FC = () => {
  return (
    <ScreenWidthAdjuster>
      <div style={{ backgroundColor: 'white' }}>
        <p>
          <span>
            この利用規約（以下、「本規約」といいます。）は、デザミス株式会社（以下、「甲」といいます。）が提供する
            <span>
              <span>Chumly</span>
            </span>
            （以下、「本サービス」といいます。）を利用者（以下、「乙」といいます。）にご利用頂く際の取扱いについて定めたものです。
          </span>
        </p>

        <p>
          <span>本規約に同意がない場合は、本サービスをご利用できませんので、ご注意ください。</span>
        </p>

        <p style={{ fontWeight: 'bold' }}>
          <span>
            <span>第１条</span>
          </span>
          <span>（本サービスの内容）</span>
        </p>

        <p>
          <span>
            <span>１．</span>
          </span>
          <span>
            本サービスの内容は、管理・コミュニケーション用ツール「
            <span>
              <span>Chumly</span>
            </span>
            アプリ」（<span>WEB</span>
            ブラウザ版またはスマートフォン用アプリケーションもしくはその両方のことを指し、以下、「本アプリ」といいます。）を通じて、甲が乙へ提供する一切のサービスとなります。
          </span>
        </p>

        <p>
          <span>
            <span>２．</span>
          </span>
          <span>
            甲は本サービスについて、乙に事前の通知をすることなく、本サービスの内容の全部または一部を変更することができます。
          </span>
        </p>

        <p style={{ fontWeight: 'bold' }}>
          <span>
            <span>第２条</span>
          </span>
          <span>（本契約）</span>
        </p>

        <p>
          <span>
            <span>１．</span>
          </span>
          <span>
            乙が、本アプリに表示される利用開始ボタンを押すことで、本規約に同意したものとされ、甲との本サービス利用契約（以下、「本契約」という。）が成立します。
          </span>
        </p>

        <p>
          <span>
            <span>２．</span>
          </span>
          <span>
            利用料は原則無償とします。ただし、機能追加や今後の情勢等諸般の事情によっては、本サービスの全部または一部を有償とする場合があります。
          </span>
        </p>

        <p>
          <span>
            <span>３．</span>
          </span>
          <span>本契約は、次の各号のいずれかに該当したときに終了するものとします。</span>
        </p>

        <p>
          <span>
            <span>(１)</span>
          </span>
          <span>　乙が本サービスのアカウントを削除したとき</span>
        </p>

        <p>
          <span>
            <span>(２)</span>
          </span>
          <span>　乙が本契約に違反したとき</span>
        </p>

        <p>
          <span>
            <span>(３)</span>
          </span>
          <span>　甲が本サービスの提供を終了、中止したとき</span>
        </p>

        <p>
          <span>
            <span>(４)</span>
          </span>
          <span>　乙が甲所定の手続きにより本契約の解除の申し出をし、甲が承諾したとき</span>
        </p>

        <p style={{ fontWeight: 'bold' }}>
          <span>
            <span>第３条</span>
          </span>
          <span>
            （<span>ID</span>及びパスワード）
          </span>
        </p>

        <p>
          <span>
            <span>１．</span>
          </span>
          <span>
            乙は<span>ID</span>及びパスワードの管理責任を負うものとし、当該<span>ID</span>
            及びパスワードを第三者に知られるような態様で管理してはならないものとします。
          </span>
        </p>

        <p>
          <span>
            <span>２．</span>
          </span>
          <span>
            乙の<span>ID</span>
            及びパスワードの管理不十分、使用上の過誤、第三者の使用等による損害について、甲は一切の責任を負わないものとします。
          </span>
        </p>

        <p>
          <span>
            <span>３．</span>
          </span>
          <span>
            乙は<span>ID</span>
            及びパスワードを甲が許可した第三者以外の者に利用させ、または、貸与、譲渡、売買、質入等を行うことはできないものとします。
          </span>
        </p>

        <p>
          <span>
            <span>４．</span>
          </span>
          <span>
            甲は、一定回数以上にわたりログインに失敗したなど、乙の<span>ID</span>
            またはパスワードに不正利用の可能性があると認めた場合には、甲の判断によって、当該<span>ID</span>
            及びパスワードの利用を停止できるものとします。その場合、乙は甲が定める手続に従い、利用停止の解除を行うことができます。なお、当該利用停止措置により、乙が
            <span>ID</span>及びパスワードを使用できず損害が生じても、甲は一切の責任を負わないものとします。
          </span>
        </p>

        <p>
          <span>
            <span>５．</span>
          </span>
          <span>
            乙は、<span>ID</span>
            またはパスワードが、甲が許可した第三者以外の者に利用されていることを発見した場合、乙は直ちにその旨を甲に通知するとともに、甲からの指示に従うものとします。
          </span>
        </p>

        <p style={{ fontWeight: 'bold' }}>
          <span>
            <span>第４条</span>
          </span>
          <span>（招待ユーザー）</span>
        </p>

        <p>
          <span>
            <span>１．</span>
          </span>
          <span>
            乙は、甲所定の手続きに従って、招待ユーザーを設定し、自己のグループに招待することができます。招待ユーザーとは、「ゲスト」と「パートナー」の２種類に分けることができ、それぞれの定義は以下の通りです。
          </span>
        </p>

        <p>
          <span>
            <span>(１)</span>
          </span>
          <span>ゲスト</span>
        </p>

        <p>
          <span>
            乙が招待する第三者のうち、乙の管理のもと本サービスの一部を利用する第三者を指します。ゲストが本サービスに関連して行った行為の責任は乙に帰属します。
          </span>
        </p>

        <p>
          <span>
            <span>(２)</span>
          </span>
          <span>パートナー</span>
        </p>

        <p>
          <span>
            乙が招待する第三者のうち、別途甲と
            <span>
              <span>Chumly</span>
            </span>
            利用契約を締結しており、かつゲストではない者を指します。パートナーが本サービスに関連して行った行為の責任は当該パートナーに帰属します。
          </span>
        </p>

        <p>
          <span>
            <span>２．</span>
          </span>
          <span>
            乙は、ゲストに本規約を遵守させる義務を負い、ゲストの行為によって甲に損害が発生した場合は、乙の責任においてその賠償を行うものとします。
          </span>
        </p>

        <p style={{ fontWeight: 'bold' }}>
          <span>
            <span>第５条</span>
          </span>
          <span>（禁止事項）</span>
        </p>

        <p>
          <span>
            <span>１．</span>
          </span>
          <span>
            {/* @ts-expect-error TS(2322): Type '{ children: string; name: string; }' is not ... Remove this comment to see the full error message */}
            乙は、本サービスの利&#12132;にあたり、<a name="_Hlk142059260">次の各号のいずれかに該当</a>
            する事項（以下、「禁&#12108;事項」といいます。）を&#12175;ってはならないものとします。乙が禁&#12108;事項を&#12175;ったことに起因して乙に損害が&#12131;じたとしても、甲は&#12032;切の責任を負わないものとし、甲が損害を被ったときは、甲は乙にその賠償を求めることができるものとします。
          </span>
        </p>

        <p>
          <span>
            <span>(１)</span>
          </span>
          <span>　甲&#12060;は第三者の知的財産権、プライバシー権その他の権利等を侵害する&#12175;為</span>
        </p>

        <p>
          <span>
            <span>(２)</span>
          </span>
          <span>　甲又は第三者の財産を侵害する&#12175;為、又は侵害するおそれのある行為</span>
        </p>

        <p>
          <span>
            <span>(３)</span>
          </span>
          <span>　第三者を差別&#12060;は誹謗中傷する&#12175;為</span>
        </p>

        <p>
          <span>
            <span>(４)</span>
          </span>
          <span>　甲&#12060;は第三者の信&#12132;若しくは名誉を毀損する&#12175;為</span>
        </p>

        <p>
          <span>
            <span>(５)</span>
          </span>
          <span>
            　ウィルス・プログラム、その他の有害プログラム等を送信&#12060;は使用する&#12175;為及びそれらを誘発する行為
          </span>
        </p>

        <p>
          <span>
            <span>(６)</span>
          </span>
          <span>
            　甲&#12060;は第三者の機器、設備等若しくは本サービス&#12132;の設備の利&#12132;&#12060;は設置・管理に&#12096;障を及ぼす&#12175;為、その他本サービスの正常な運営を妨げ、&#12060;は&#12096;障を及ぼす&#12175;為
          </span>
        </p>

        <p>
          <span>
            <span>(７)</span>
          </span>
          <span>　本規約等&#12060;は法令若しくは公序良俗に違反する&#12175;為</span>
        </p>

        <p>
          <span>
            <span>(８)</span>
          </span>
          <span>　甲&#12060;は第三者に不利益若しくは損害を与える&#12175;為</span>
        </p>

        <p>
          <span>
            <span>(９)</span>
          </span>
          <span>
            　犯罪&#12175;為&#12060;は犯罪&#12175;為に結びつく&#12175;為若しくはこれを助&#11985;する&#12175;為
          </span>
        </p>

        <p>
          <span>
            <span>(１０)</span>
          </span>
          <span>第三者になりすます&#12175;為</span>
        </p>

        <p>
          <span>
            <span>(１１)</span>
          </span>
          <span>甲のサーバーやシステム、セキュリティへの攻撃</span>
        </p>

        <p>
          <span>
            <span>(１２)</span>
          </span>
          <span>本サービスの他の利用者の情報の収集</span>
        </p>

        <p>
          <span>
            <span>(１３)</span>
          </span>
          <span>その他、上記各号の&#12175;為に準ずる&#12175;為&#12060;は該当するおそれのある&#12175;為</span>
        </p>

        <p>
          <span>
            <span>２．</span>
          </span>
          <span>
            前項の禁&#12108;&#12175;為によって&#12131;じた第三者との紛争については、すべて乙の責任と費&#12132;により解決するものとし、甲は&#12032;切の責任を負わないものとします。
          </span>
        </p>

        <p style={{ fontWeight: 'bold' }}>
          <span>
            <span>第６条</span>
          </span>
          <span>（免責事項）</span>
        </p>

        <p>
          {/* @ts-expect-error TS(2322): Type '{ children: Element[]; name: string; }' is n... Remove this comment to see the full error message */}
          <a name="_Hlk114065424">
            <span>
              <span>１．</span>
            </span>
            <span>本アプリの修正、仕様変更及びバージョンアップ等の対応は、甲の独自の判断により行われます。</span>
          </a>
        </p>

        <span />

        <p>
          <span>
            <span>２．</span>
          </span>
          <span>
            甲は、本サービスの利用の遅滞、停止、中止、廃止、または登録、提供される情報・データの喪失、流出に関連して発生した乙の損害について、本規約に定められた事項以外、一切の賠償をしないこととし、乙は甲に当該賠償を請求しないものとします。ただし、甲の責に帰すべき事由により生じた損害についてはこの限りではありません。
          </span>
        </p>

        <p>
          <span>
            <span>３．</span>
          </span>
          <span>
            インターネットなどの通信回線等については乙が自己の責任において環境を整えるものとし、当該通信回線等について障害等の問題が発生した場合、甲は本サービスの全部又は一部を停止又は中断することができるものとします。この場合において、甲は当該停止又は中断にかかる責任を負わないものとします。
          </span>
        </p>

        <p>
          <span>
            <span>４．</span>
          </span>
          <span>
            乙が、本サービスの利用に関連して、第三者に対し損害を与えた場合、乙は自己の費用と責任においてその賠償をするものとします。
          </span>
        </p>

        <p>
          <span>
            ５．乙と他の利用者との間の紛争及びトラブルについて、甲は一切責任を負わないものとし、両当事者の責任で解決するものとします。なお、両当事者は、甲にそのトラブルに関して一切の請求をしないものとします。
          </span>
        </p>

        <p style={{ fontWeight: 'bold' }}>
          <span>
            <span>第７条</span>
          </span>
          <span>（データの保管）</span>
        </p>

        <p>
          <span>
            <span>１．</span>
          </span>
          <span>
            甲は、
            <span>
              <span>Chumly</span>
            </span>
            から得られるデータ（以下、「本データ」という。）を厳重に管理し、保持するものとします。
          </span>
        </p>

        <p>
          <span>
            <span>２．</span>
          </span>
          <span>
            本サービスのうちチャット機能に入力された情報についてはシンガポールのサーバーに保管されることに、乙は予め承諾するものとします。（詳しくは、
            <span>
              <span>Chumly</span>
            </span>
            プライバシーポリシーの外部ツールの条項をご確認ください。）
          </span>
        </p>

        <p style={{ fontWeight: 'bold' }}>
          <span>
            <span>第８条</span>
          </span>
          <span>（利用者情報の取り扱い）</span>
        </p>

        <p>
          <span>
            甲は、利用者情報について、本規約に定めた取り扱いの他、
            <span>
              <span>Chumly</span>
            </span>
            プライバシーポリシーに則り適正に取り扱います。
          </span>
        </p>

        <p style={{ fontWeight: 'bold' }}>
          <span>
            <span>第９条</span>
          </span>
          <span>（外部連携・共同利用）</span>
        </p>

        <p>
          <span>
            本サービスと連動・連携するサービスを提供している事業者に対して、乙の事前の同意がある場合に限り、甲は利用者情報を提供及び共同利用します。同意の方法及び提供並びに共同利用する利用者情報については、連動開始時に本アプリ上または書面等でお知らせします。
          </span>
        </p>

        <p style={{ fontWeight: 'bold' }}>
          <span>
            <span>第１０条</span>
          </span>
          <span>（提供データの取り扱い）</span>
        </p>

        <p>
          <span>
            <span>１．</span>
          </span>
          <span>
            乙は、本サービス上で甲から提供されるデータ（外部連携により取得したデータも含まれます。）を善良な管理者の注意をもって管理し、次の各号以外の目的のために利用してはならないものとします。
          </span>
        </p>

        <p>
          <span>
            <span>(１)</span>
          </span>
          <span>　取得元となる外部連携先ユーザーとの業務を円滑に行うため</span>
        </p>

        <p>
          <span>
            <span>(２)</span>
          </span>
          <span>
            　取得元となる外部連携先ユーザーに対して牛の飼育その他関連する事項について助言または提案を行うため
          </span>
        </p>

        <p>
          <span>
            <span>(３)</span>
          </span>
          <span>　乙のサービスの品質向上のため</span>
        </p>

        <p style={{ fontWeight: 'bold' }}>
          <span>
            <span>第１１条</span>
          </span>
          <span>（知的財産権）</span>
        </p>

        <p>
          <span>
            本サービスに関する知的財産権等は、すべて甲または正当な権限を有する第三者に帰属し、本契約締結によって乙に権利が移転することはなく、乙には利用権のみが付与されます。
          </span>
        </p>

        <p style={{ fontWeight: 'bold' }}>
          <span>
            <span>第１２条</span>
          </span>
          <span>（秘密保持）</span>
        </p>

        <p>
          <span>
            <span>１．</span>
          </span>
          <span>
            機密情報とは、本契約に関連して相手方から資料、電磁的記録媒体、その他の有形・無形問わず開示された技術上、営業上その他業務上の情報であって、相手方から機密である旨を表示して提供された情報のことをいいます。
          </span>
        </p>

        <p>
          <span>
            <span>２．</span>
          </span>
          <span>前項にかかわらず、次の各号の情報は機密情報として扱わないものとします。</span>
        </p>

        <p>
          <span>
            <span>(１)</span>
          </span>
          <span>　第三者から機密保持義務を負うことなく正当に入手した情報</span>
        </p>

        <p>
          <span>
            <span>(２)</span>
          </span>
          <span>　開示されたとき既に保有していた情報</span>
        </p>

        <p>
          <span>
            <span>(３)</span>
          </span>
          <span>　相手方から開示された機密情報によらずに独自に開発した情報</span>
        </p>

        <p>
          <span>
            <span>(４)</span>
          </span>
          <span>　公知の情報、または相手方から得た後自己の責めによらずに公知となった情報</span>
        </p>

        <p>
          <span>
            <span>３．</span>
          </span>
          <span>
            甲および乙は、相手方から提供を受けた機密情報について、善良なる管理者の注意をもって機密として管理し、以下に定める場合を除いては第三者に開示せず、また本契約の目的以外に使用してはならないものとします。
          </span>
        </p>

        <p>
          <span>
            <span>(１)</span>
          </span>
          <span>　相手方の事前の書面による承諾がある場合</span>
        </p>

        <p>
          <span>
            <span>(２)</span>
          </span>
          <span>　裁判所、検察庁、警察またはこれらに準じた権限を有する機関から法令に基づき開示を求められた場合</span>
        </p>

        <p>
          <span>
            <span>(３)</span>
          </span>
          <span>
            　弁護士、公認会計士、税理士、司法書士、アドバイザーおよびコンサルタント等で、法令上または契約上において機密保持義務を負う者に対して、合理的に必要な範囲内において開示する場合
          </span>
        </p>

        <p>
          <span>
            <span>４．</span>
          </span>
          <span>
            甲および乙は、機密情報を開示する対象者を本業務の遂行上知る必要のある自己の役員および従業員（以下、「従業員等」という。）に限定するとともに、本契約における機密保持義務を当該従業員等に遵守させるものとします。
          </span>
        </p>

        <p>
          <span>
            <span>５．</span>
          </span>
          <span>
            甲および乙は、自己の責に帰すべき事由により機密情報を第三者に漏洩し、または本契約の目的以外に使用したことにより、相手方が損害を被った場合は、相手方に対して当該損害を賠償するものとします。
          </span>
        </p>

        <p>
          <span>
            <span>６．</span>
          </span>
          <span>
            甲は、乙より提供を受けた機密情報以外の情報についても、第三者へ複写・複製により開示する場合には、事前に書面によって乙の承諾を得なければならないものとします。
          </span>
        </p>

        <p style={{ fontWeight: 'bold' }}>
          <span>
            <span>第１３条</span>
          </span>
          <span>（解除）</span>
        </p>

        <p>
          <span>
            <span>１．</span>
          </span>
          <span>
            甲は、乙が次の各号の一に該当するときは、本契約の全部もしくは一部を、何らの催告なしに解除することができるものとします。
          </span>
        </p>

        <p>
          <span>
            <span>(１)</span>
          </span>
          <span>　仮差押、仮処分、差押、租税公課滞納処分、競売その他の公権力の処分を受けたとき</span>
        </p>

        <p>
          <span>
            <span>(２)</span>
          </span>
          <span>
            　民事再生手続開始、会社更生手続開始、破産の申し立てを受け、または自ら整理、民事再生手続開始、会社更生手続開始、破産もしくは特定調停の申し立てをしたとき
          </span>
        </p>

        <p>
          <span>
            <span>(３)</span>
          </span>
          <span>　行政庁より営業停止または営業免許もしくは営業登録の取消処分を受けたとき</span>
        </p>

        <p>
          <span>
            <span>(４)</span>
          </span>
          <span>　その振出し、引受けた手形、小切手の不渡り、支払停止その他財産状態が悪化したと認められるとき</span>
        </p>

        <p>
          <span>
            <span>(５)</span>
          </span>
          <span>　解散・連絡不能または営業の全部もしくは重要な一部を第三者に譲渡しようとしたとき</span>
        </p>

        <p>
          <span>
            <span>(６)</span>
          </span>
          <span>　本契約の履行に関して、自己または自己の従業員等もしくは代理人等に重大な不正な行為があったとき</span>
        </p>

        <p>
          <span>
            <span>２．</span>
          </span>
          <span>
            甲は、乙が次の各号の一に該当し、書面により催告した日の翌日から起算して<span>30</span>
            日以内にこれを是正しないときは、本契約の全部もしくは一部を解除することができるものとします。
          </span>
        </p>

        <p>
          <span>
            <span>(１)</span>
          </span>
          <span>
            　正当な理由によらないで、本契約の全部もしくは一部を履行しないとき、または履行の見込みがないと明らかに認められるとき。
          </span>
        </p>

        <p>
          <span>
            <span>(２)</span>
          </span>
          <span>　前号に定める場合のほか本契約の全部もしくは一部の義務に違反したとき。</span>
        </p>

        <p style={{ fontWeight: 'bold' }}>
          <span>
            <span>第１４条</span>
          </span>
          <span>（不可抗力）</span>
        </p>

        <p>
          <span>
            甲または乙が、天災、地変、火災、戦争・内乱・暴動、法令の改廃・制定、公権力による命令・処分、労働争議、輸送機関・情報通信設備・通信回線の事故、為替の大幅な変動、その他当事者の責めに帰すことのできない不可抗力（以下、「不可抗力事由」という。）によって契約の全部または一部につき履行遅滞、履行不能または不完全履行に陥った場合には、当該当事者はこれらに基づく責任を負わないものとします。ただし、当該当事者は、不可抗力事由の発生を速やかに相手方に通知しなければなりません。
          </span>
        </p>

        <p>
          <span>
            <span>第１５条</span>
          </span>
          <span>（</span>権利・義務の譲渡の禁止
          <span>）</span>
        </p>

        <p>
          <span>
            <span>１．</span>
          </span>
          <span>
            乙は、本契約上の地位または本契約一切の権利もしくは義務を、甲の書面または電磁的方法による事前の同意無く、第三者に譲渡しまたは担保に供してはなりません。
          </span>
        </p>

        <p>
          <span>
            <span>２．</span>
          </span>
          <span>
            前項の規定は、乙が第三者との合併により、その地位が第三者に包括的に継承される場合には適用されないものとします。
          </span>
        </p>

        <p>
          <span>
            <span>３．</span>
          </span>
          <span>
            乙は、甲が本サービスを継続する目的で本契約に基づく権利もしくは義務または本契約上の地位を第三者に譲渡する場合があることを予め承諾します。
          </span>
        </p>

        <p style={{ fontWeight: 'bold' }}>
          <span>
            <span>第１６条</span>
          </span>
          <span>（反社会的勢力の排除）</span>
        </p>

        <p>
          <span>
            <span>１．</span>
          </span>
          <span>
            甲および乙は、この契約の締結日現在および将来にわたって、自己又は自己の役員（取締役、監査役、執行役、執行役員及びこれに準ずる者をいう）が①暴力団、暴力団員、暴力団員でなくなった時から
            <span>5</span>
            年を経過しない者、暴力団準構成員、暴力団関係企業、暴力団関係団体、総会屋、社会運動等標ぼうゴロ、特殊知能暴力集団等、その他これらに準ずるもの（以下、総称して「暴力団員等」という。）に該当しないこと、
            <span>②</span>
            暴力団員等が自己の経営に関与していないこと、暴力団員等との社会的に非難される関係を有しないこと、暴力団員等を利用してまたは自ら社会的に非難される行為を行わないこと、③暴力的要求行為、不当要求行為、脅迫的言動、暴力行為、風説の流布・偽計による信用毀損行為、業務妨害行為、その他これらに準ずる行為を行わないこと（第三者を利用して行う場合を含む。）を表明し、確約するものとします。
          </span>
        </p>

        <p>
          <span>
            <span>２．</span>
          </span>
          <span>
            甲または乙は、相手方が前項の一つでも違反した場合、何らの通知催告なく本契約を解除または解約し、被った損害の賠償を請求することができるものとします。
          </span>
        </p>

        <p>
          <span>
            <span>３．</span>
          </span>
          <span>
            甲または乙が前項の規定により本契約を解除または解約した場合には、相手方に対し損害を賠償する責を負わないものとします。
          </span>
        </p>

        <p style={{ fontWeight: 'bold' }}>
          <span>
            <span>第１７条</span>
          </span>
          <span>（損害賠償）</span>
        </p>

        <p>
          <span>
            甲は、乙が本契約に違反したことにより損害を被った場合、乙に対して損害賠償を請求することができるものとします。
          </span>
        </p>

        <p style={{ fontWeight: 'bold' }}>
          <span>
            <span>第１８条</span>
          </span>
          <span>（通知義務）</span>
        </p>

        <p>
          <span>
            <span>１．</span>
          </span>
          <span>
            乙は、甲に提供した自己に関する情報に変更があった場合、甲に対し、直ちにその旨を甲所定の方法により通知しなければなりません。
          </span>
        </p>

        <p>
          <span>
            <span>２．</span>
          </span>
          <span>
            乙が通知を怠ったことにより自己又は第三者に損害が発生したとしても、甲はその責を負わないものとします。
          </span>
        </p>

        <p style={{ fontWeight: 'bold' }}>
          <span>
            <span>第１９条</span>
          </span>
          <span>（規約の変更）</span>
        </p>

        <p>
          <span>
            <span>１．</span>
          </span>
          <span>甲は、いつでも、本規約の内容を改定することができるものとします。</span>
        </p>

        <p>
          <span>
            <span>２．</span>
          </span>
          <span>甲は、本規約を改定したときは、その内容について適切な方法により乙に通知するものとします。</span>
        </p>

        <p>
          <span>
            <span>３．</span>
          </span>
          <span>改定後乙が本アプリにログインした時点で、乙は本改定に同意したものとみなします。</span>
        </p>

        <p>
          <span>
            <span>４．</span>
          </span>
          <span>乙は、本規約の改定に同意しない場合、本サービスの全部又は一部を利用できないことがあります。</span>
        </p>

        <p style={{ fontWeight: 'bold' }}>
          <span>
            <span>第２０条</span>
          </span>
          <span>（協議）</span>
        </p>

        <p>
          <span>本規約に定めなき事項または甲乙間で紛争が生じた場合は、甲乙協議の上、解決するものとします。</span>
        </p>

        <p style={{ fontWeight: 'bold' }}>
          <span>
            <span>第２１条</span>
          </span>
          <span>（準拠法及び合意管轄）</span>
        </p>

        <p>
          <span>
            <span>１．</span>
          </span>
          <span>本契約の準拠法は日本法とします。</span>
        </p>

        <p>
          <span>
            <span>２．</span>
          </span>
          <span>
            協議による解決が困難な紛争が生じた場合は、東京地方裁判所又は東京簡易裁判所を第一審の専属的合意管轄裁判所とします。
          </span>
        </p>

        <p>以上</p>

        <p>
          <span>2023</span>
          <span>
            年<span>8</span>月　改定
          </span>
        </p>
      </div>
    </ScreenWidthAdjuster>
  );
};
