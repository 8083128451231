import { useRoute, useNavigation, useLinkTo } from '@react-navigation/native';
import * as React from 'react';
import { Platform } from 'react-native';

import { useGetGroupsByGroupIdTasksQuery } from '~/api/uFeedApi';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';
import { ListBuilder } from '~/components/builder/ListBuilder';

export const GroupTaskListScreen: React.FC = () => {
  const { params } = useRoute();
  const navigation = useNavigation();
  // @ts-expect-error TS(2339): Property 'queryParams' does not exist on type 'obj... Remove this comment to see the full error message
  const queryParams = params?.queryParams || {};
  const queryResult = useGetGroupsByGroupIdTasksQuery(queryParams);
  const linkTo = useLinkTo();

  return (
    <BaseScreenBuilder
      title=""
      headerRightLabel="追加"
      onHeaderRightPress={() =>
        // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        Platform.OS === 'web' ? linkTo(`/tasks/create_edit/mytask`) : navigation.navigate('TaskCreateEdit', {})
      }
    >
      <ListBuilder
        // @ts-expect-error TS(2322): Type 'UseQueryHookResult<QueryDefinition<GetGroups... Remove this comment to see the full error message
        queryResult={queryResult}
        onSelect={(item) =>
          Platform.OS === 'web'
            ? linkTo(`/farm/${queryParams.farmId}/group/${queryParams.groupId}/tasks/${queryParams.id}/mytask`)
            : // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
              navigation.navigate('TaskDetail', {
                queryParams: {},
              })
        }
        emptyMessage="タスクデータがありません"
      />
    </BaseScreenBuilder>
  );
};
