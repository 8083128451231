import { Button, useTheme, Text, Input, Box } from '@gluestack-ui/themed-native-base';
import { Formik } from 'formik';
import React from 'react';
import { SafeAreaView, View } from 'react-native';

import { useNetworkStatus } from '../../hooks/useNetworkStatus';
import { LogoImageDark } from '../UmotionLoginModalScreen/LogoImageDark';
import { LogoImageDefault } from '../UmotionLoginModalScreen/LogoImageDefault';

import { Alert } from '~/components/Alert';
import { ScreenWidthAdjuster } from '~/components/ScreenWidthAdjuster';
import { usePostResetPasswordMutation } from '~/packages/u-motion-api/uMotionApi';

import { validationSchema } from './validationSchema';

export const UmotionResetPasswordScreen = ({ route, navigation }: any) => {
  const [resetPassword] = usePostResetPasswordMutation();
  const [isLoading, setIsLoading] = React.useState(false);
  const { isConnectedToInternet } = useNetworkStatus();
  const theme = useTheme();

  function showAlertSuccess() {
    Alert.alert('完了', 'パスワード変更のためのメールを送信しました。', [
      {
        text: 'OK',
        onPress: () => {
          navigation?.navigate('Login');
        },
      },
    ]);
  }

  const onSubmit = (values: any, actions: any) => {
    if (!isConnectedToInternet) {
      Alert.alert('エラー', 'インターネットを有効にしてください', [{ text: 'OK' }]);
    } else {
      setIsLoading(true);
      resetPassword({ email: values.email })
        .then((reset) => {
          // @ts-expect-error TS(2339): Property 'data' does not exist on type '{ data: un... Remove this comment to see the full error message
          switch (reset.data) {
            case 200: //Success
              showAlertSuccess();
              break;
            case 'Request failed with status code 400': //Network error
              actions.setFieldError('email', '正しいメールアドレスを入力してください');
              break;
            default:
              Alert.alert('エラー', 'パスワードを変更できませんでした。', [{ text: 'OK' }]);
          }
        })
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <SafeAreaView
      style={{
        flex: 1,
        backgroundColor: theme?.dark ? 'black' : 'white',
      }}
    >
      <ScreenWidthAdjuster web={500}>
        <Box alignItems="center" mt={12} backgroundColor={theme?.dark ? 'black' : 'white'}>
          {theme?.dark ? <LogoImageDark /> : <LogoImageDefault />}
          <Text fontSize="md" paddingTop={3} paddingBottom={3} color={theme?.dark ? 'white' : 'black'}>
            パスワード再設定手続き
          </Text>
          <Box width="100%" backgroundColor={theme?.dark ? 'black' : 'gray.100'} p={6}>
            <Formik
              initialValues={{ email: route.params['resetEmail'] }}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            >
              {(formikProps) => {
                const emailErrors = formikProps?.touched['email'] && formikProps?.errors['email'];
                const isButtonDisabled = isLoading || formikProps?.errors?.email;
                return (
                  <>
                    <View style={{ paddingBottom: 32 }}>
                      <Text fontSize="md" mt={2} mb={1} color={theme?.dark ? 'white' : 'black'}>
                        メールアドレス
                      </Text>
                      <Box mt={4} mb={4}>
                        <Input
                          type="text"
                          fontSize="sm"
                          bg="white"
                          borderWidth={0}
                          padding={4}
                          placeholder="メールアドレス"
                          onChangeText={formikProps?.handleChange('email')}
                          onBlur={formikProps?.handleBlur('email')}
                          autoFocus
                          autoCapitalize="none"
                          isDisabled={isLoading}
                          borderColor={emailErrors ? 'red.500' : 'white'}
                        />
                        <Text style={{ color: 'red' }}>
                          <>{emailErrors}</>
                        </Text>
                      </Box>
                      <Button
                        onPress={formikProps?.handleSubmit}
                        color="white"
                        loading={isLoading}
                        disabled={isButtonDisabled}
                        backgroundColor={isButtonDisabled ? 'cyan.800' : 'cyan.600'}
                      >
                        パスワードを再設定
                      </Button>
                    </View>
                  </>
                );
              }}
            </Formik>
          </Box>
          <Button margin={4} variant="link" onPress={() => navigation?.navigate('UmotionLogin')}>
            ログイン画面に戻る
          </Button>
        </Box>
      </ScreenWidthAdjuster>
    </SafeAreaView>
  );
};
