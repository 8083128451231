import { Dispatch, createContext, useReducer } from 'react';
import { match } from 'ts-pattern';

export type ShowPinMessagesAction = { type: 'switch' } | { type: 'close' };
type ShowPinMessagesDispatch = Dispatch<ShowPinMessagesAction>;

export const ShowPinMessagesContext = createContext<boolean | undefined>(undefined);
export const ShowPinMessagesDispatchContext = createContext<ShowPinMessagesDispatch | undefined>(undefined);

export const showPinMessagesReducer = (showPinMessages: boolean, action: ShowPinMessagesAction) => {
  return match(action)
    .with({ type: 'switch' }, () => !showPinMessages)
    .with({ type: 'close' }, () => false)
    .otherwise(() => {
      throw Error('Unknown action: ' + action.type);
    });
};

export const ShowPinMessagesProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [showPinMessages, dispatch] = useReducer(showPinMessagesReducer, false);

  return (
    <ShowPinMessagesContext.Provider value={showPinMessages}>
      <ShowPinMessagesDispatchContext.Provider value={dispatch}>{children}</ShowPinMessagesDispatchContext.Provider>
    </ShowPinMessagesContext.Provider>
  );
};
