import { Text } from '@gluestack-ui/themed-native-base';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { Platform, TouchableOpacity } from 'react-native';

import { renderHeaderLeft } from './index';
import { ScreenWidthAdjuster } from '~/components';
import { useStreamChatAuthContext } from '~/contexts/StreamChatContext';
import { streami18n } from '~/hooks/useStreamChat';
import { Chat } from '~/lib/StreamChatReact';
import {
  NotificationsScreen,
  ChatChannelMessagesScreen,
  ChatThreadMessagesScreen,
  ChatMemberListScreen,
  ChatChannelOverviewEditScreen,
} from '~/screens';

import { AppHeader } from './AppHeader';
import { commonModalScreens } from './BottomTabNavigator';

const Stack = createNativeStackNavigator();

export const NotificationsNavigator = () => {
  const { chatClient, isUserConnected } = useStreamChatAuthContext();

  if (!chatClient) {
    return null;
  }

  return (
    <Chat client={chatClient} i18nInstance={streami18n}>
      {Platform.OS === 'web' ? <AppHeader /> : null}
      <ScreenWidthAdjuster>
        <Stack.Navigator
          // @ts-expect-error TS(2322): Type '({ navigation }: { route: RouteProp<ParamLis... Remove this comment to see the full error message
          screenOptions={({ navigation }) => ({
            headerBackTitle: '戻る',
            title: '',
            headerTitleStyle: {
              fontWeight: 'bold',
            },
            headerStyle:
              Platform.OS === 'web'
                ? {
                    borderBottomWidth: 0,
                  }
                : {},
            headerLeft: () => {
              return (
                <Text fontSize="xl" bold fontFamily="Hiragino Sans" color="onSurface">
                  通知
                </Text>
              );
            },
            headerRight: () => {
              return renderHeaderLeft(navigation);
            },
          })}
        >
          <Stack.Screen
            name="Notifications"
            component={NotificationsScreen}
            options={{
              headerShown: Platform.OS !== 'web',
            }}
          />
          <Stack.Screen name="ChatChannelMessages" component={ChatChannelMessagesScreen} />
          <Stack.Screen name="ChatThreadMessages" component={ChatThreadMessagesScreen} />
          <Stack.Screen
            name="ChatMemberList"
            component={ChatMemberListScreen}
            options={{
              headerShown: true,
            }}
          />
          <Stack.Screen name="ChatChannelOverviewEdit" component={ChatChannelOverviewEditScreen} />
          <Stack.Group
            screenOptions={({ navigation }) => ({
              presentation: 'modal',
              headerLeft: () => {
                return (
                  <TouchableOpacity onPress={navigation.goBack}>
                    <Text color="blue.500" bold fontSize="lg">
                      閉じる
                    </Text>
                  </TouchableOpacity>
                );
              },
            })}
          >
            {commonModalScreens}
          </Stack.Group>
        </Stack.Navigator>
      </ScreenWidthAdjuster>
    </Chat>
  );
};
