import { useRoute, useNavigation, useLinkTo } from '@react-navigation/native';
import * as React from 'react';
import { Platform } from 'react-native';

import { useGetUsersByIdQuery, useDeleteUsersByIdMutation, useGetCurrentUserQuery } from '~/api/uFeedApi';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';
import { DetailsBuilder } from '~/components/builder/DetailsBuilder';

export const AdminUserDetailScreen: React.FC = () => {
  const { params } = useRoute();
  const navigation = useNavigation();

  const queryParams = params ? { ...params } : {};
  const [deleteUserById, deleteUserResp] = useDeleteUsersByIdMutation();
  const currentUser = useGetCurrentUserQuery({});
  const linkTo = useLinkTo();

  // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
  const id = queryParams.id;

  return (
    <BaseScreenBuilder>
      <DetailsBuilder
        // @ts-expect-error TS(2322): Type 'UseQueryHookResult<QueryDefinition<GetUsersB... Remove this comment to see the full error message
        queryResult={useGetUsersByIdQuery(queryParams)}
        emptyMessage="ユーザーデータがありません"
        fields={[
          {
            key: 'name',
            label: '名前',
          },
          {
            key: 'email',
            label: 'メールアドレス',
          },
          {
            key: 'roles',
            label: 'システム権限',
            type: 'key-value-array',
            // @ts-expect-error TS(2322): Type '{ key: string; label: string; type: string; ... Remove this comment to see the full error message
            options: roleMatser,
          },
          {
            key: 'user_account_type',
            label: 'アカウント種別',
            type: 'key-value',
            // @ts-expect-error TS(2322): Type '{ key: string; label: string; type: string; ... Remove this comment to see the full error message
            options: {
              'u-motion': 'U-motion ID',
              chumly: 'Chumly ID',
            },
          },
        ]}
        // @ts-expect-error TS(2322): Type '(() => MutationActionCreatorResult<MutationD... Remove this comment to see the full error message
        onDeletePress={
          currentUser.data?.roles?.some((role) => role === 'account_admin' || role === 'member_admin')
            ? () => {
                return deleteUserById({
                  id,
                });
              }
            : undefined
        }
        onEditPress={
          currentUser.data?.roles?.some((role) => role === 'account_admin' || role === 'member_admin')
            ? () =>
                Platform.OS === 'web'
                  ? linkTo(`/admin/users/${id}/edit`)
                  : // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                    navigation.navigate('AdminUserEdit', { ...queryParams })
            : undefined
        }
      />
    </BaseScreenBuilder>
  );
};

const roleMatser = [
  { key: 'account_admin', value: '企業管理者' },
  { key: 'member', value: '一般ユーザー' },
  { key: 'group_admin', value: 'グループ管理者' },
  { key: 'member_admin', value: 'メンバー管理者' },
];
