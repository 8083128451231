export const generateTimeArray = (startHour: number, endHour: number, gapMinutes: number) => {
  const result = [];
  for (let hour = startHour; hour <= endHour; hour++) {
    for (let minute = 0; minute < 60; minute += gapMinutes) {
      const hourStr = hour.toString();
      const minuteStr = minute.toString().padStart(2, '0');
      result.push(`${hourStr}:${minuteStr}`);
    }
  }
  return result;
};
