import { Ionicons } from '@expo/vector-icons';
import { HStack, Input, Flex, Pressable, Icon, Text } from '@gluestack-ui/themed-native-base';
import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { TouchableOpacity } from 'react-native';
import { setData } from '~/utils/multiSelectInputDataHelper';

type option = {
  id: number | string;
  name: string;
};
interface Props {
  options: option[];
  selectedItems: string[] | number[];
  unchangeableItems: string[] | number[];
  onSelectedItemsChange: (items: string[] | number[]) => void;
  filterFunction?: (query: string) => Promise<option[]>;
}

export const MultiSelectInput: React.FC<Props> = ({
  options,
  selectedItems,
  unchangeableItems,
  onSelectedItemsChange,
  filterFunction,
}: Props) => {
  const navigation = useNavigation();

  const defaultFilterFunction = async (query: string) => {
    return options.filter((option) => {
      return option.name.toLowerCase().includes(query.toLowerCase());
    });
  };

  const filterOption = filterFunction ?? defaultFilterFunction;

  const openMultiSelectScreen = async () => {
    const filteredOptions = await filterOption('');
    if (!filteredOptions.length) {
      return;
    }
    setData({
      data: filteredOptions,
      selectedItems,
      unchangeableItems,
      onQuery: filterOption,
      onConfirm: (result: any) => {
        onSelectedItemsChange(result);
      },
    });
    // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    navigation.navigate('MultiSelect');
  };

  return (
    <>
      <TouchableOpacity onPress={openMultiSelectScreen}>
        <HStack alignItems="center">
          <Input
            size="lg"
            variant="unstyled"
            borderColor="black"
            placeholder={
              !selectedItems || selectedItems?.length == 0
                ? '選択してください'
                : `${selectedItems?.length}アイテム選択中`
            }
            isReadOnly
            onPressIn={openMultiSelectScreen}
            InputRightElement={<Icon as={Ionicons} name="chevron-down-outline" size="md" />}
          />
        </HStack>
      </TouchableOpacity>
      <Flex direction="row" flexWrap="wrap">
        {selectedItems?.map((item) => (
          <HStack
            key={item}
            borderWidth={1}
            margin={1}
            padding={2}
            color="gray.400"
            borderColor="gray.400"
            borderRadius={40}
          >
            <Text marginX={2}>
              {options?.find((option) => option.id == item)?.name}
              {/* @ts-expect-error TS(2345): Argument of type 'string | number' is not assignab... Remove this comment to see the full error message */}
              {unchangeableItems?.includes(item) ? null : (
                <Pressable
                  onPress={() => {
                    // @ts-expect-error TS(2349): This expression is not callable.
                    onSelectedItemsChange(selectedItems?.filter((select: any) => select !== item));
                  }}
                >
                  <Icon as={Ionicons} name="close-outline" marginLeft={2} />
                </Pressable>
              )}
            </Text>
          </HStack>
        ))}
      </Flex>
    </>
  );
};
