import { Box } from '@gluestack-ui/themed-native-base';
import React, { useContext } from 'react';
import { ShowPinMessagesDispatchContext } from '~/contexts/PinMessageContext';

import { useChannelActionContext } from '~/lib/StreamChatReact';

import { ChatSearchResultItem } from './ChatSearchResultItem';

interface Props {
  item: object;
  index: number;
}

export const PinMassageListItem = ({ item, index }: Props) => {
  const { jumpToMessage } = useChannelActionContext();
  const dispatch = useContext(ShowPinMessagesDispatchContext);
  const closePinMessage = () => {
    dispatch && dispatch({ type: 'close' });
  };
  return (
    <Box margin={2} padding={2} backgroundColor="white" borderRadius={10}>
      <ChatSearchResultItem
        // @ts-expect-error TS(2339): Property 'message' does not exist on type 'object'... Remove this comment to see the full error message
        message={item.message}
        // @ts-expect-error TS(2339): Property 'message' does not exist on type 'object'... Remove this comment to see the full error message
        key={`${item.message.id}${index}`}
        handleMessagePress={() => {
          closePinMessage();
          // @ts-expect-error TS(2339): Property 'message' does not exist on type 'object'... Remove this comment to see the full error message
          jumpToMessage(item.message.id);
        }}
      />
    </Box>
  );
};
