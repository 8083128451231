import { useDidMount, useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { ENV_FILE_NAME } from '@env';
import { useRef, useCallback, useState } from 'react';
import { Event } from 'stream-chat';

import { usePostUConciergeMutation, useGetCurrentUserFarmsQuery } from '~/api/uFeedApi';
import { useStreamChat } from '~/hooks/useStreamChat';

export const useUConciergeNotify = () => {
  const { chatClient } = useStreamChat();
  const listenerRef = useRef<Event>();
  const [postUConcierge] = usePostUConciergeMutation();
  const [isUConciergeInitialized, setIsUConciergeInitialized] = useState(false);

  const { isSuccess: isGetCurrentUserFarmsSuccess, data: currentUserFarmsData } = useGetCurrentUserFarmsQuery();

  useDidUpdate(
    () => {
      if (isGetCurrentUserFarmsSuccess) {
        // 社内運用用に決め打ちで「デザミステスト牧場」に所属しているかをチェックしています
        if (currentUserFarmsData?.find((farm) => farm?.id === 38 || ENV_FILE_NAME !== '.env.production')) {
          setIsUConciergeInitialized(true);
        }
      }
    },
    [isGetCurrentUserFarmsSuccess],
    true
  );

  const messageNewHandler = useCallback(async (event: Event) => {
    const message = event.message;

    if (event.type !== 'message.new') {
      return;
    }

    if (message?.user?.id !== chatClient?.user?.id) {
      return;
    }

    await postUConcierge({
      body: {
        // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
        message_id: message?.id,
      },
    })
      .catch((error) => {
        console.error('post /u_concierge error: ', error);
      })
      .finally(() => {
        unsubscribeEventListener();
      });
  }, []);

  const subscribeEventListener = () => {
    if (!listenerRef.current) {
      // @ts-expect-error TS(2322): Type '{ unsubscribe: () => void; } & { unsubscribe... Remove this comment to see the full error message
      listenerRef.current = chatClient.on('message.new', messageNewHandler);
    }
  };

  const unsubscribeEventListener = () => {
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    listenerRef.current?.unsubscribe();
    listenerRef.current = undefined;
  };

  useDidMount(() => {
    return unsubscribeEventListener;
  });

  return {
    subscribeEventListener,
    unsubscribeEventListener,
    isUConciergeInitialized,
  };
};
