import { ScrollView, FormControl, HStack, Checkbox } from '@gluestack-ui/themed-native-base';
import { Logic } from 'native-base-form-builder';
import { useController } from 'react-hook-form';

import { FormFieldLabel } from './FormFieldLabel';

export const FormSingleCheckbox: React.FC<React.ComponentProps<typeof Logic>> = ({
  name,
  rules,
  shouldUnregister,
  defaultValue,
  control,
  label,
}) => {
  const { field, formState } = useController({
    name,
    rules,
    shouldUnregister,
    defaultValue,
    control,
  });

  const isRequired = !!rules?.required;
  const errorMessage = formState.errors?.[field.name]?.message;

  return (
    <FormControl isRequired={isRequired} isInvalid={errorMessage}>
      <HStack space="2" alignItems="center">
        {/* @ts-expect-error */}
        <Checkbox value="dummy" onChange={(e) => field.onChange(e)} size="md" />
        {errorMessage && (
          <ScrollView horizontal showsHorizontalScrollIndicator={false} bounces={false}>
            <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
          </ScrollView>
        )}
        <FormFieldLabel label={label} isRequired={isRequired} />
      </HStack>
    </FormControl>
  );
};
