import { Skeleton, Text, Alert } from '@gluestack-ui/themed-native-base';
import { useNavigation, useRoute } from '@react-navigation/native';
import { StyledProps } from 'native-base';
import * as React from 'react';

import { useGetGroupsByGroupIdRemindersQuery } from '~/api/uFeedApi';

import { ItemList } from './ItemList';
import { ListItem } from './ListItem';
import { Section } from './Section';

interface Props {
  farmId: number;
  groupId: number;
  max?: number;
}

const MAX_NUMBER_OF_ITEMS = 5;

export const ReminderCard: React.FC<Props & StyledProps> = React.memo(
  ({ farmId, groupId, max = MAX_NUMBER_OF_ITEMS, ...remainingProps }) => {
    const { params } = useRoute();
    // @ts-expect-error TS(2339): Property 'queryParams' does not exist on type '{}'... Remove this comment to see the full error message
    const { queryParams } = params || { queryParams: { groupId, farmId } };
    const navigation = useNavigation();

    const reminders = useGetGroupsByGroupIdRemindersQuery(queryParams);

    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    const sorted = reminders.data ? [...reminders.data].sort((a, b) => a?.id - b.id).slice(0, MAX_NUMBER_OF_ITEMS) : [];

    return (
      <>
        {/* @ts-expect-error */}
        <Section {...remainingProps}>
          {reminders?.isLoading ? <Skeleton.Text height="12" borderRadius={10} /> : null}
          <ItemList>
            <ListItem
              key="add-button"
              title="申し送りを追加"
              leftIconName="add-circle"
              onPress={() =>
                // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                navigation.navigate('ReminderCreate', {
                  queryParams,
                })
              }
              link
            />

            {reminders?.isSuccess ? (
              sorted?.length > 0 ? (
                sorted?.map((reminder) => (
                  <ListItem
                    key={reminder?.id}
                    // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                    title={reminder?.memo}
                    arrow
                    onPress={() =>
                      // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                      navigation.navigate('ReminderDetail', {
                        queryParams: { farmId, groupId, reminderId: reminder.id },
                      })
                    }
                  />
                ))
              ) : (
                <Text>申し送りがありません</Text>
              )
            ) : null}
          </ItemList>

          {reminders?.isError ? (
            <Alert status="error">
              {/* @ts-expect-error TS(2339): Property 'error' does not exist on type 'FetchBase... Remove this comment to see the full error message */}
              <Text>エラーが発生しました: {reminders?.error?.error}</Text>
            </Alert>
          ) : null}
        </Section>
      </>
    );
  }
);
