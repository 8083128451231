import { useState, useCallback } from 'react';

export const useShouldSutmit = () => {
  const [isMetaPressed, setIsMetaPressed] = useState(false);

  const shouldSubmit = useCallback(
    (event: any) => {
      if (event.key === 'Meta' || event.key === 'Control') {
        setIsMetaPressed(true);
        return false;
      }
      if (event.key === 'Enter' && !event.shiftKey && !event.nativeEvent.isComposing && isMetaPressed) {
        return true;
      } else {
        setIsMetaPressed(false);
        return false;
      }
    },
    [isMetaPressed]
  );

  return { shouldSubmit };
};
