import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { VStack, HStack, Text, FormControl } from '@gluestack-ui/themed-native-base';
import { Logic } from 'native-base-form-builder';
import { useState } from 'react';
import { useController } from 'react-hook-form';
import DropDownPicker from 'react-native-dropdown-picker';

export const FormMultiSelect: React.FC<React.ComponentProps<typeof Logic>> = ({
  label,
  name,
  rules,
  shouldUnregister,
  defaultValue,
  control,
  options,
  isRequired,
}) => {
  const { field, formState } = useController({
    name,
    shouldUnregister,
    defaultValue,
    control,
  });
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(field.value);
  const errorMessage = formState.errors?.[field.name]?.message;

  useDidUpdate(() => setValue(field.value), [field.value]);

  return (
    <FormControl isRequired={isRequired} isInvalid={errorMessage}>
      <VStack space={2} flex={1} zIndex={1000}>
        <HStack space={0} alignItems="center">
          <Text fontSize="xs" fontWeight="medium" color="gray.500">
            {label}
          </Text>
          {/* @ts-expect-error TS(2339): Property 'value' does not exist on type 'string | ... Remove this comment to see the full error message */}
          {rules?.required?.value ? (
            <Text fontSize="md" fontWeight="medium" color="red.400">
              *
            </Text>
          ) : null}
        </HStack>
        <DropDownPicker
          // @ts-expect-error TS(2339): Property 'isMultiple' does not exist on type 'Omit... Remove this comment to see the full error message
          multiple={rules?.isMultiple}
          open={open}
          value={value}
          items={options || []}
          setOpen={setOpen}
          setValue={(newValue) => {
            setValue(newValue);
          }}
          onChangeValue={(newValue: any) => {
            field.onChange(value);
          }}
          listMode="SCROLLVIEW"
          min={0}
          max={5}
          translation={{
            PLACEHOLDER: `${label}を選択してください`,
            SEARCH_PLACEHOLDER: '検索',
            SELECTED_ITEMS_COUNT_TEXT: '{count} 件選択済み', // See below for advanced options
            NOTHING_TO_SHOW: '項目がありせん',
          }}
          dropDownDirection="AUTO"
        />
        {errorMessage ? <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage> : null}
      </VStack>
    </FormControl>
  );
};
