import { useIsFocused } from '@react-navigation/native';
import { useState, useEffect, MutableRefObject } from 'react';
import { TextInput } from 'react-native';

export const useInputFocus = (textInputRef: MutableRefObject<TextInput | null>) => {
  const isFocused = useIsFocused();

  const [inputFocus, setInputFocus] = useState(false);

  useEffect(() => {
    if (isFocused) {
      // Keyboard doesn't show up when use textInputRef.current.focus(), should use setTimeout --> https://github.com/software-mansion/react-native-screens/issues/472
      setTimeout(() => textInputRef.current?.focus(), 0);
      setInputFocus(true);
    }
  }, [isFocused]);

  return {
    inputFocus,
    setInputFocus,
  };
};
