import { VStack } from '@gluestack-ui/themed-native-base';
import { StyledProps } from 'native-base';
import React from 'react';

import { SectionTitle } from '~/components';

interface Props {
  title?: string;
  moreLabel?: string;
  onMorePress?: (item: any) => void;
  fontSize?: '' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
}

export const Section: React.FC<Props & React.PropsWithChildren & StyledProps> = ({
  children,
  title,
  moreLabel,
  onMorePress,
  fontSize = 'xl',
  ...remainingProps
}) => {
  return (
    <VStack space={4} {...remainingProps}>
      {/* @ts-expect-error */}
      <SectionTitle title={title} moreLabel={moreLabel} onMorePress={onMorePress} fontSize={fontSize} />
      <VStack>{children}</VStack>
    </VStack>
  );
};
