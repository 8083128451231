import * as React from 'react';
import { StyleSheet } from 'react-native';
import { WebView } from 'react-native-webview';

import { useHelpUrl } from '~/hooks/useHelpUrl';

export const HelpScreen: React.FC = () => {
  const { helpUrl } = useHelpUrl();
  return <WebView style={{ ...styles.container }} source={{ uri: helpUrl }} />;
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
});
