import { memo, useEffect } from 'react';
import {
  UnreadMessagesNotification as StreamUnreadMessagesNotification,
  useChannelActionContext,
} from 'stream-chat-react';
import type { UnreadMessagesNotificationProps } from 'stream-chat-react';

const UnmemoizedUnreadMessageNotification = ({ ...props }: UnreadMessagesNotificationProps) => {
  const { markRead } = useChannelActionContext();

  useEffect(() => {
    markRead();
  }, [markRead]);

  return <StreamUnreadMessagesNotification {...props} />;
};

export const UnreadMessageNotification = memo(UnmemoizedUnreadMessageNotification);
