import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '~/store';

export type UmotionSessionState = {
  sessionToken: string | null;
  refreshToken: string | null;
  expiredAt: number | null;
};

const slice = createSlice({
  name: 'uMotionSession',
  initialState: { sessionToken: null, refreshToken: null, expiredAt: null } as UmotionSessionState,
  reducers: {
    setUmotionToken: (
      state,
      {
        payload: { sessionToken, refreshToken, expiredAt },
      }: PayloadAction<{ sessionToken: string; refreshToken: string; expiredAt: number }>
    ) => {
      state.sessionToken = sessionToken;
      state.refreshToken = refreshToken;
      state.expiredAt = expiredAt;
    },
  },
});

export const { setUmotionToken } = slice.actions;
export const uMotionSessionSelector = (state: RootState) => {
  return state.uMotionSession;
};

export default slice.reducer;
