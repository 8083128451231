import { useDidMount } from '@better-typed/react-lifecycle-hooks';
import { Center, Text, Button, Input, VStack, Link } from '@gluestack-ui/themed-native-base';
import { signInWithPhoneNumber, RecaptchaVerifier } from 'firebase/auth';
import { Formik } from 'formik';
import { useRef, useState } from 'react';
import { Platform } from 'react-native';
import * as yup from 'yup';

import { useNetworkStatus } from '../../hooks/useNetworkStatus';

import { Alert } from '~/components/Alert';
import FirebaseRecaptchaVerifierModal from '~/lib/expo-firebase-recaptcha/FirebaseRecaptchaVerifierModal';
import { firebaseUmotionConfig, umotionAuth } from '~/lib/ReactNativeFirebaseUmotion';
import { setUmotionToken } from '~/packages/u-motion-api/slices/uMotionSessionSlice';
import { usePostSessionMutation, useSignInSmsMutation, AuthArg } from '~/packages/u-motion-api/uMotionApi';
import {
  setPhoneNumber,
  setIsSmsSent,
  setIsRecaptchaSolved,
  setConfirmCode,
  phoneLoginSelector,
} from '~/slices/phoneLoginSlice';
import { useAppSelector, useAppDispatch } from '~/store';

export const UmotionPhoneLoginScreen: React.FC = () => {
  const [signInSms, signInSmsResult] = useSignInSmsMutation();
  const dispatch = useAppDispatch();
  const { confirmCode, isSmsSent, isRecaptchaSolved, phoneNumber } = useAppSelector(phoneLoginSelector);
  const recaptchaVerifierRef = useRef();
  const [postSession, { data, isSuccess }] = usePostSessionMutation();
  const [attempts, setAttempts] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { isConnectedToInternet } = useNetworkStatus();
  const [confirm, setConfirm] = useState<any>();

  const resetState = (actions: any) => {
    actions.resetForm();
  };

  useDidMount(() => {
    const initWebRecaptcha = () => {
      try {
        // @ts-expect-error TS(2339): Property 'recaptchaVerifier' does not exist on typ... Remove this comment to see the full error message
        window.recaptchaVerifier = new RecaptchaVerifier(umotionAuth, 'sign-in-button', {
          size: 'invisible',
          callback: (response: any) => {
            dispatch(setIsRecaptchaSolved(true));
          },
        });
      } catch (error) {
        console.error('new RecaptchaVerifier failed', error);
      }
    };

    if (Platform.OS === 'web') {
      initWebRecaptcha();
    }
  });

  const onPhoneNumberSubmit = async (values: any, actions: any) => {
    if (!isConnectedToInternet) {
      Alert.alert('エラー', 'インターネットを有効にしてください', [{ text: 'OK' }]);
    } else {
      setIsLoading(true);
      try {
        const result = await signInWithPhoneNumber(
          umotionAuth,
          `+81${values.phoneNumber}`,
          // @ts-expect-error TS(2339): Property 'recaptchaVerifier' does not exist on typ... Remove this comment to see the full error message
          Platform.OS === 'web' ? window.recaptchaVerifier : recaptchaVerifierRef?.current
        );

        dispatch(setPhoneNumber(values.phoneNumber));
        dispatch(setIsSmsSent(true));
        setConfirm(result);
      } catch (error) {
        console.error('signInWithPhoneNumber failed:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const onConfirmPress = (values: any, actions: any) => {
    confirm
      ?.confirm(values.confirmCode)
      .then((result: any) => {
        const params: Pick<AuthArg, 'uid' | 'token'> = {
          uid: result.user.uid,
          token: result.user.stsTokenManager.refreshToken,
        };

        setIsLoading(true);
        signInSms(params)
          .unwrap()
          .then((result) => {
            const { sessionToken, refreshToken, expiredAt } = result;

            dispatch(
              setUmotionToken({
                sessionToken,
                refreshToken,
                expiredAt,
              })
            );
            resetState(actions);
            localStorage.setItem('LastLoginMethodStorageKey', 'phone');
          })
          .catch((error) => {
            console.error(error);
            actions.setFieldError('confirmCode', `無効なU-motionユーザーです`);
          })
          .finally(() => setIsLoading(false));
      })
      .catch((error: any) => {
        console.error(error);
        actions.setFieldError('confirmCode', 'コードが正しくありません');
      });
  };

  const onCancelConfirmationPress = () => {
    dispatch(setIsSmsSent(false));
    dispatch(setConfirmCode(''));
  };

  const onResendPress = () => {
    onPhoneNumberSubmit({ phoneNumber }, {});
  };

  return (
    <>
      {Platform.OS !== 'web' ? (
        <FirebaseRecaptchaVerifierModal
          // @ts-expect-error TS(2769): No overload matches this call.
          ref={recaptchaVerifierRef}
          firebaseConfig={firebaseUmotionConfig}
          attemptInvisibleVerification
        />
      ) : null}
      {isSmsSent ? (
        <Formik
          initialValues={{ confirmCode }}
          enableReinitialize
          onSubmit={onConfirmPress}
          validationSchema={validationSchemaConfirmCode}
        >
          {(formikProps) => (
            <VStack space={6} justifyItems="center" flex={1} justifyContent="center">
              <Text bold fontSize="lg" textAlign="center">
                電話番号の確認
              </Text>
              <VStack>
                <Text color="#005fff">+81{phoneNumber}</Text>
                <Text>に送信された6桁のコードを入力してください。</Text>
              </VStack>
              <Input
                value={formikProps.values['confirmCode']}
                onChangeText={formikProps.handleChange('confirmCode')}
                placeholder="6桁のコード"
                width="310px"
                fontSize="sm"
                bg="white"
                borderWidth={0}
                padding={4}
                inputMode="numeric"
              />
              {formikProps.touched['confirmCode'] && formikProps.errors['confirmCode'] ? (
                <Text style={{ color: 'red' }}> {formikProps.errors['confirmCode']}</Text>
              ) : null}
              <VStack space={4}>
                <Center>
                  <Button
                    variant="submit"
                    size="lg"
                    width="280px"
                    onPress={formikProps.handleSubmit}
                    isDisabled={isLoading || !formikProps.isValid}
                  >
                    {isLoading ? '確認中...' : '続行'}
                  </Button>
                </Center>
                <Center>
                  <Button variant="link" size="lg" width="280px" onPress={onCancelConfirmationPress}>
                    キャンセル
                  </Button>
                </Center>
                <Center>
                  <Button variant="link" size="lg" width="280px" onPress={onResendPress}>
                    コードを再送信
                  </Button>
                </Center>
              </VStack>
            </VStack>
          )}
        </Formik>
      ) : (
        <Formik
          initialValues={{ phoneNumber }}
          enableReinitialize
          onSubmit={onPhoneNumberSubmit}
          validationSchema={validationSchemaPhoneNumber}
        >
          {(formikProps) => (
            <VStack space={8} justifyItems="center" flex={1}>
              <Input
                value={formikProps.values['phoneNumber']}
                onChangeText={formikProps.handleChange('phoneNumber')}
                placeholder="電話番号"
                width="310px"
                fontSize="sm"
                bg="white"
                borderWidth={0}
                padding={4}
                inputMode="numeric"
              />

              {formikProps.touched['phoneNumber'] && formikProps.errors['phoneNumber'] ? (
                <Text style={{ color: 'red' }}> {formikProps.errors['phoneNumber']}</Text>
              ) : null}

              <Center>
                <Button
                  variant="submit"
                  size="lg"
                  width="280px"
                  onPress={() => {
                    formikProps.handleSubmit();
                  }}
                  isDisabled={isLoading || !formikProps.isValid}
                  id="sign-in-button"
                >
                  {isLoading ? '確認中...' : '確認'}
                </Button>
              </Center>
              <Text>
                「確認」をタップすると
                <Link href="https://www.desamis.co.jp/u-feed-terms" isExternal>
                  利用規約
                </Link>
                と
                <Link href="https://www.desamis.co.jp/u-feed-privacy-policy" isExternal>
                  プライバシーポリシー
                </Link>
                に同意したことになり、SMSが送信されます。データ通信量がかかることがあります。
              </Text>
            </VStack>
          )}
        </Formik>
      )}
    </>
  );
};

const validationSchemaPhoneNumber = yup.object().shape({
  phoneNumber: yup
    .string()
    .matches(/^\d{10,11}$/, '11桁の携帯電話番号を数字のみで入力してください')
    .label('電話番号')
    .required('電話番号を入力してください'),
});

const validationSchemaConfirmCode = yup.object().shape({
  confirmCode: yup
    .string()
    .matches(/^\d{6}$/, '6桁の確認コードを数字のみで入力してください')
    .label('確認コード')
    .required('確認コードを入力してください'),
});
