import { SESSION_TOKEN_KEY } from '@env';
import { configureStore } from '@reduxjs/toolkit';
import { useSelector as rawUseSelector, TypedUseSelectorHook, useDispatch } from 'react-redux';
import { persistStore, persistCombineReducers } from 'redux-persist';
import thunkMiddleware from 'redux-thunk';

import { uFeedApi } from '~/api/uFeedApi';
import { createSecureStorage } from '~/lib/ExpoSecureStore';
import uMotionSession from '~/packages/u-motion-api/slices/uMotionSessionSlice';
import { uMotionApi } from '~/packages/u-motion-api/uMotionApi';
import { uMotionRefreshTokenApi } from '~/packages/u-motion-api/uMotionRefreshTokenApi';
import avatarsCache from '~/slices/avatarsCacheSlice';
import overlayReaction from '~/slices/overlayReactionSlice';
import phoneLogin from '~/slices/phoneLoginSlice';
import session from '~/slices/sessionSlice';
import settings from '~/slices/settingsSlice';
import streamChat from '~/slices/streamChatStateSlice';

const secureStorage = createSecureStorage();

const reducer = persistCombineReducers(
  {
    key: SESSION_TOKEN_KEY,
    storage: secureStorage,
    whitelist: ['session', 'settings', 'uMotionSession', 'streamChat', 'avatars'],
  },
  {
    [uFeedApi.reducerPath]: uFeedApi.reducer,
    [uMotionApi.reducerPath]: uMotionApi.reducer,
    [uMotionRefreshTokenApi.reducerPath]: uMotionRefreshTokenApi.reducer,
    session,
    uMotionSession,
    settings,
    streamChat,
    phoneLogin,
    overlayReaction,
    avatarsCache,
  }
);

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST'],
      },
    }).concat(uFeedApi.middleware, uMotionRefreshTokenApi.middleware, uMotionApi.middleware, thunkMiddleware),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useSelector: TypedUseSelectorHook<RootState> = rawUseSelector;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
