import { HStack, VStack, Text, Box } from '@gluestack-ui/themed-native-base';
import { useLinkTo, useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { Pressable, Platform } from 'react-native';
import { Channel } from 'stream-chat';
import { useStreamChatContext } from '~/contexts/StreamChatContext';

import { StreamChatGenerics } from '~/types';
import { ChatCustomChannelAvatar } from './ChatCustomChannelAvatar';

interface Props {
  channel: Channel<StreamChatGenerics>;
}

type RootStackParamList = {
  DMNavigator: {
    screen: string;
    params: { channelId?: string };
  };
  ChatChannelMessages: { channelId?: string };
};

type NavigationProps = NativeStackNavigationProp<RootStackParamList>;

export const ChannelSearchResultItem: React.FC<Props> = ({ channel }) => {
  const navigation = useNavigation<NavigationProps>();
  const { setAppChannel } = useStreamChatContext();
  const linkTo = useLinkTo();
  return (
    <Pressable
      onPress={() => {
        if (Platform.OS === 'web') {
          if (channel.data?.isDM) {
            navigation.navigate('DMNavigator', {
              screen: 'DirectMessageList',
              params: { channelId: channel.id },
            });
          } else {
            linkTo(`/chat/${channel.id}`);
          }
        } else {
          setAppChannel(channel);
          navigation.navigate('ChatChannelMessages', { channelId: channel.id });
        }
      }}
    >
      <VStack space={2} margin={2}>
        <HStack space={2}>
          <Text fontWeight={500} color="gray.400">
            {channel.data?.member_count}名のメンバー
          </Text>
        </HStack>
        <HStack space={2}>
          <VStack space={1}>
            <HStack w="310">
              <Box padding={2}>
                <ChatCustomChannelAvatar channel={channel} />
              </Box>
              <VStack>
                <Text fontSize="md" adjustsFontSizeToFit padding={1}>
                  {channel.data?.name}
                </Text>
                <Text fontSize="xs" adjustsFontSizeToFit padding={1}>
                  {channel.state.messages.length > 0 && channel.state.messages[channel.state.messages.length - 1].text}
                </Text>
              </VStack>
            </HStack>
          </VStack>
        </HStack>
      </VStack>
    </Pressable>
  );
};
