import Ionicons from '@expo/vector-icons/Ionicons';
import { HStack, Text, useTheme, VStack, Icon } from '@gluestack-ui/themed-native-base';
import { ReactNode } from 'react';
import { TouchableOpacity } from 'react-native';

interface Props {
  title: string;
  value?: string;
  left?: ReactNode;
  right?: ReactNode;
  description?: string | null;
  onPress?: () => void;
  arrow?: boolean;
  DescriptionComponent?: React.ReactNode;
  bold?: boolean;
  link?: boolean;
  leftIconName?: string;
}

export const ListItem: React.FC<Props> = ({
  // @ts-expect-error TS(2339): Property 'children' does not exist on type 'Props'... Remove this comment to see the full error message
  children,
  title = '',
  left,
  right,
  description,
  value,
  onPress = () => {},
  arrow,
  DescriptionComponent,
  bold,
  link,
  leftIconName,
}) => {
  const theme = useTheme();

  // Note: TouchableOpacityで囲ってあると left right 内のコンポーネントのTestIDがマッチしなくなるのでListItem内のonPressはテストできない
  return (
    <TouchableOpacity onPress={onPress}>
      <HStack alignItems="center" space={4} justifyContent="space-between">
        <HStack alignItems="center" space={2}>
          {leftIconName ? <Icon as={Ionicons} name={leftIconName} color={link ? 'cyan.600' : 'black'} /> : null}
          {left ? left : null}
          <VStack flexShrink={1}>
            <Text fontSize="md" fontWeight={bold ? 'medium' : 'normal'} color={link ? 'cyan.600' : 'black'}>
              {title}
            </Text>
            {description ? (
              <Text fontSize="md" color="muted.400">
                {description}
              </Text>
            ) : null}
            {DescriptionComponent ? DescriptionComponent : null}
          </VStack>
        </HStack>
        <HStack>
          {value ? (
            <Text fontSize="md" px="0" color="muted.500">
              {value}
            </Text>
          ) : null}

          {right ? right : null}
          {arrow ? (
            <VStack alignItems="center">
              <Ionicons name="chevron-forward" size={theme?.fontSizes['2xl']} color={theme?.colors['muted']?.['400']} />
            </VStack>
          ) : null}
        </HStack>
      </HStack>
    </TouchableOpacity>
  );
};
