import { VStack, HStack, Text, Avatar, Box, Button, Spinner } from '@gluestack-ui/themed-native-base';

import { usePatchAppointmentsByAppointmentSlotIdCancelMutation } from '~/api/uFeedApi';
import { Alert } from '~/components/Alert';
import { useCustomToast } from '~/hooks';
import { DateUtil } from '~/utils/DateUtils';

interface Props {
  data: object;
  testID: string;
}

export const AppointmentListItem: React.FC<Props> = ({ data, testID }) => {
  const [cancel, { isLoading: isLoadingCancel, isSuccess: isCancelSuccess, reset: resetCancel }] =
    usePatchAppointmentsByAppointmentSlotIdCancelMutation();
  // @ts-expect-error TS(2339): Property 'start_at' does not exist on type 'object... Remove this comment to see the full error message
  const startAt = new Date(data?.start_at);
  const startAtStr = `${DateUtil.toJapaneseYYYYMMDDOrMMDD(startAt)} (${DateUtil.toDayOfWeek(startAt)}) ${DateUtil.tohmm(
    startAt
  )}`;
  const { show } = useCustomToast();

  const onCancelPress = (appointmentSlotId: number) => {
    Alert.alert(
      '確認',
      `予約をキャンセルします。サービス提供者とあなたの連絡用チャンネルにキャンセル通知が送信されます`,
      [
        {
          text: 'キャンセル',
        },
        {
          text: 'OK',
          onPress: async () => {
            cancel({
              appointmentSlotId,
            })
              .unwrap()
              .then(() => {
                resetCancel();
                show('予約をキャンセルしました');
              })
              .catch((error) => {
                const errorMessage = `予約のキャンセルに失敗しました`;
                show(errorMessage);
                console.error(errorMessage, error);
              });
          },
        },
      ]
    );
  };

  return (
    <>
      <HStack space={4} testID={testID}>
        <VStack space={2} flex={1}>
          <Text fontSize="lg" bold>
            {startAtStr}
          </Text>
          <Box backgroundColor="gray.100" borderRadius={10} padding={4}>
            <VStack space={4} flex={1}>
              <VStack space={4}>
                <HStack space={2}>
                  <Text bold flexShrink={1} numberOfLines={2}>
                    {/* @ts-expect-error TS(2339): Property 'appointment_service' does not exist on t... Remove this comment to see the full error message */}
                    {data?.appointment_service?.name}
                  </Text>
                </HStack>
                <HStack space={2}>
                  <Text flexShrink={1} numberOfLines={3}>
                    {/* @ts-expect-error TS(2339): Property 'appointment_service' does not exist on t... Remove this comment to see the full error message */}
                    {data?.appointment_service?.description}
                  </Text>
                </HStack>
                <HStack space={2} alignItems="center">
                  {/* @ts-expect-error TS(2339): Property 'provider' does not exist on type 'object... Remove this comment to see the full error message */}
                  <Avatar source={{ uri: data?.provider?.avatar }} size="sm" />
                  {/* @ts-expect-error TS(2339): Property 'provider' does not exist on type 'object... Remove this comment to see the full error message */}
                  <Text>{data?.provider?.name}</Text>
                </HStack>
              </VStack>
              {isLoadingCancel ? (
                <HStack alignItems="center" space={2} flex={1} justifyContent="center">
                  <Spinner />
                  <Button disabled variant="link" padding={0}>
                    キャンセル処理中
                  </Button>
                </HStack>
              ) : (
                <Button
                  onPress={() => {
                    // @ts-expect-error TS(2339): Property 'id' does not exist on type 'object'.
                    onCancelPress(data?.id);
                  }}
                  variant="link"
                  padding={0}
                >
                  予約をキャンセルする
                </Button>
              )}
            </VStack>
          </Box>
        </VStack>
      </HStack>
    </>
  );
};
