import { useDidMount } from '@better-typed/react-lifecycle-hooks';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Platform } from 'react-native';

import {
  BookmarkStackNavigator,
  SearchNavigator,
  ChatStackNavigator,
  TaskSummaryNavigator,
} from './BottomTabNavigator';
import { DMStackNavigator } from './DMStackNavigator';
import { DrawerContent } from './DrawerContent';
import { MenuNavigator } from './MenuNavigator';
import { NotificationsNavigator } from './NotificationNavigator';

const Drawer = createDrawerNavigator();

const ChatStackRedirector = () => {
  const navigation = useNavigation();
  const { params } = useRoute();
  useDidMount(() => {
    // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    navigation.navigate('Chat', {
      screen: 'ChatTabbedChannelList',
      params,
    });
  });

  return <></>;
};

export const DrawerNavigator = () => {
  return (
    <Drawer.Navigator
      screenOptions={{
        headerShown: false,
        headerTitleStyle: {
          fontWeight: 'bold',
        },
        headerStyle:
          Platform.OS === 'web'
            ? {
                borderBottomWidth: 0,
              }
            : {},
      }}
      drawerContent={DrawerContent}
    >
      <Drawer.Screen name="Chat" component={ChatStackNavigator} />
      <Drawer.Screen name="ChatChannelMessages" component={ChatStackRedirector} />
      <Drawer.Screen name="TaskSummaryNavigator" component={TaskSummaryNavigator} />
      <Drawer.Screen name="Search" component={SearchNavigator} />
      <Drawer.Screen name="NotificationsNavigator" component={NotificationsNavigator} />
      <Drawer.Screen name="MenuNavigator" component={MenuNavigator} />
      <Drawer.Screen name="DMNavigator" component={DMStackNavigator} />
      <Drawer.Screen name="BookmarkNavigator" component={BookmarkStackNavigator} />
    </Drawer.Navigator>
  );
};
