import { Ionicons } from '@expo/vector-icons';
import { Icon as NativebaseIcon } from '@gluestack-ui/themed-native-base';

interface Props {
  name: string;
}

export const Icon: React.FC<Props> = ({ name, ...otherProps }) => {
  return <NativebaseIcon as={Ionicons} name={name} alignSelf="center" fontSize="lg" {...otherProps} />;
};
