import { Image, Button, Text, VStack } from '@gluestack-ui/themed-native-base';
import { useRoute, useNavigation } from '@react-navigation/native';
import { ImageEditor } from 'expo-image-editor';
import { manipulateAsync, SaveFormat } from 'expo-image-manipulator';

import * as ImagePicker from 'expo-image-picker';
import * as React from 'react';
import { Platform } from 'react-native';

import { useGetCurrentUserQuery, usePutUsersByIdMutation } from '~/api/uFeedApi';
import { Alert } from '~/components/Alert';

import '@expo/match-media';

import { DetailList, errorMessageBuilder, BaseScreenBuilder, BaseFormBuilder } from '~/components/builder';
import { ScreenWidthModal } from '~/components/ScreenWithModal';
import { useAuth } from '~/hooks';

const { useState, useRef } = React;

export const UserSettingsEditScreen: React.FC = () => {
  const { params } = useRoute();
  const navigation = useNavigation();
  const queryParams = params ? { ...params } : {};
  const queryResult = useGetCurrentUserQuery(queryParams);
  const [update, { isLoading: isUpdating, data }] = usePutUsersByIdMutation();

  const [shouldShowEditor, setShouldShowEditor] = useState(false);
  const [imageUri, setImageUri] = useState('');
  const [resultImageUri, setResultImageUri] = useState('');
  const { isUmotionUser } = useAuth();

  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const formRef = useRef();

  const selectPhoto = async () => {
    const response = await ImagePicker.requestMediaLibraryPermissionsAsync();

    if (response.granted) {
      const pickerResult = await ImagePicker.launchImageLibraryAsync();
      // @ts-expect-error TS(2551): Property 'cancelled' does not exist on type 'Image... Remove this comment to see the full error message
      if (!pickerResult.cancelled) {
        // @ts-expect-error TS(2339): Property 'uri' does not exist on type 'ImagePicker... Remove this comment to see the full error message
        setImageUri(pickerResult.uri);
        setShouldShowEditor(true);
      }
    } else {
      // @ts-expect-error TS(2554): Expected 3-4 arguments, but got 1.
      Alert.alert('システムの設定からこのアプリケーションが写真にアクセスする許可をしてください。');
    }
  };

  const onEditingComplete = async (cropResult: any) => {
    const resizeResult = await manipulateAsync(
      cropResult.uri,
      [
        {
          resize: {
            width: 200,
            height: 200,
          },
        },
      ],
      { compress: 1, format: SaveFormat.JPEG }
    );
    setResultImageUri(resizeResult.uri);
  };

  const fields = isUmotionUser
    ? [
        {
          key: 'yomi',
          label: '名前（カナ）',
          rules: {
            required: {
              value: true,
              message: '名前（カナ）を入力してください',
            },
            pattern: {
              value: /^[ァ-ンヴー\s]*$/,
              message: '全角カタカナで入力してください',
            },
          },
        },
      ]
    : [
        {
          key: 'name',
          label: '名前',
          rules: {
            required: {
              value: true,
              message: '名前を入力してください',
            },
          },
        },

        {
          key: 'yomi',
          label: '名前（カナ）',
          rules: {
            required: {
              value: true,
              message: '名前（カナ）を入力してください',
            },
            pattern: {
              value: /^[ァ-ンヴー\s]*$/,
              message: '全角カタカナで入力してください',
            },
          },
        },

        {
          key: 'email',
          label: 'メールアドレス',
          rules: {
            required: {
              value: true,
              message: 'メールアドレスを入力してください',
            },
            pattern: {
              value:
                /^([\w!#$%&'*+\-\/=?^`{|}~]+(\.[\w!#$%&'*+\-\/=?^`{|}~]+)*|"([\w!#$%&'*+\-\/=?^`{|}~. ()<>\[\]:;@,]|\\[\\"])+")@(([a-zA-Z\d\-]+\.)+[a-zA-Z]+|\[(\d{1,3}(\.\d{1,3}){3}|IPv6:[\da-fA-F]{0,4}(:[\da-fA-F]{0,4}){1,5}(:\d{1,3}(\.\d{1,3}){3}|(:[\da-fA-F]{0,4}){0,2}))\])$/,
              message: 'メールアドレスの形式に誤りがあります',
            },
          },
        },
      ];

  return (
    <ScreenWidthModal title="ユーザー設定">
      <BaseScreenBuilder title="ユーザー設定">
        <BaseFormBuilder
          // @ts-expect-error TS(2322): Type 'UseQueryHookResult<QueryDefinition<GetCurren... Remove this comment to see the full error message
          queryResult={queryResult}
          updateResult={{ isUpdating, data }}
          ref={formRef}
          emptyMessage="ユーザーデータがありません"
          // @ts-expect-error TS(2322): Type '({ key: string; label: string; rules: { requ... Remove this comment to see the full error message
          fields={fields}
          errorMessage={errorMessages}
          onCancel={() => navigation.goBack()}
          onSubmit={(sendingData) => {
            setErrorMessages((errorMessages) => []);
            const formData = new FormData();

            if (sendingData.name) {
              formData.append('user[name]', sendingData.name);
            }

            if (sendingData.yomi) {
              formData.append('user[yomi]', sendingData.yomi);
            }

            if (sendingData.email) {
              formData.append('user[email]', sendingData.email);
            }

            if (resultImageUri) {
              if (Platform.OS === 'web') {
                // ファイルをバイナリ化
                const bin = atob(resultImageUri.replace(/^.*,/, ''));
                // バイナリデータに変換する
                const buffer = new Uint8Array(bin.length);
                for (let i = 0; i < bin.length; i++) {
                  buffer[i] = bin.charCodeAt(i);
                }
                // Fileオブジェクトを生成
                const image_file = new File([buffer.buffer], 'profile_image', { type: 'image/jpeg' });

                formData.append('user[avatar]', image_file);
              } else {
                // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to parameter of type 'Blob'.
                formData.append('user[avatar]', {
                  type: 'image/jpeg',
                  name: 'profile_image',
                  uri: resultImageUri,
                });
              }
            }

            update({
              // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
              id: queryResult.data?.id,
              // @ts-expect-error TS(2741): Property 'user' is missing in type 'FormData' but ... Remove this comment to see the full error message
              body: formData,
            })
              .unwrap()
              .then((resp) => {
                navigation.goBack();
              })
              .catch((error) => {
                setErrorMessages(errorMessageBuilder(error, fields));
                console.error('update error: ', error);
              });
          }}
        >
          <DetailList>
            <VStack space={4}>
              <Text bold>プロフィール画像</Text>
              {queryResult?.data?.avatar && !resultImageUri ? (
                <Image
                  source={{ uri: queryResult?.data?.avatar }}
                  size="lg"
                  borderRadius={60}
                  alt={queryResult?.data?.avatar}
                />
              ) : null}
              {resultImageUri ? (
                <Image source={{ uri: resultImageUri }} size="lg" borderRadius={60} alt="編集済み画像" />
              ) : null}
              <Button variant="link" onPress={selectPhoto}>
                プロフィール画像を変更
              </Button>
            </VStack>
          </DetailList>
        </BaseFormBuilder>
        <ImageEditor
          visible={shouldShowEditor}
          onCloseEditor={() => setShouldShowEditor(false)}
          imageUri={imageUri}
          fixedCropAspectRatio={1}
          lockAspectRatio
          minimumCropDimensions={{
            width: 100,
            height: 100,
          }}
          onEditingComplete={onEditingComplete}
          throttleBlur={false}
          mode="crop-only"
        />
      </BaseScreenBuilder>
    </ScreenWidthModal>
  );
};
