import { useDidMount, useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import data from '@emoji-mart/data';
import { MaterialIcons } from '@expo/vector-icons';
import { FlatList } from '@gluestack-ui/themed';
import {
  Alert,
  Center,
  Divider,
  HStack,
  Spinner,
  VStack,
  Box,
  Input,
  Icon,
  Text,
  ScrollView,
  Stack,
} from '@gluestack-ui/themed-native-base';
import { Properties } from 'csstype';
import { init, SearchIndex } from 'emoji-mart';
import debounce from 'lodash/debounce';
import normalizeUrl from 'normalize-url';
import { ComponentType, ForwardRefExoticComponent, useCallback, useContext, useState } from 'react';
import { Dimensions } from 'react-native';
import { Message } from 'stream-chat';
import { renderText, ScrollToBottomButton } from 'stream-chat-react';

import { match } from 'ts-pattern';

import { gluestackUIConfig } from '~/config/gluestack-ui.config';
import { ShowPinMessagesContext, ShowPinMessagesDispatchContext } from '~/contexts/PinMessageContext';
import { useStreamChatContext } from '~/contexts/StreamChatContext';
import { useDraft } from '~/hooks/useDraft.web';
import { usePinMessageWeb } from '~/hooks/usePinMessageWeb';
import { usePinnedChannels } from '~/hooks/usePinnedChannels';
import { useShouldSutmit } from '~/hooks/useShouldSutmit';
import {
  Attachment,
  Channel,
  ChannelList,
  MessageInput,
  MessageList,
  Thread,
  Window,
  Modal,
  ModalGallery,
} from '~/lib/StreamChatReact';

import type {
  AttachmentProps,
  ChannelPreviewUIComponentProps,
  CustomMessageActionsListProps,
  DefaultStreamChatGenerics,
  MessageInputProps,
  ReactionSelectorProps,
  SuggestionItemProps,
  SuggestionListProps,
  UnreadMessagesNotificationProps,
} from '~/lib/StreamChatReact';
import { ChannelSwitcher } from './ChannelSwitcher';
import { ChatCustomThreadInput } from './ChatCustomInput.web';
import { ChatCustomMessage } from './ChatCustomMessage';
import ChatCustomParentMessage from './ChatCustomParentMessage';
import ChatCustomReactionSelectorWeb from './ChatCustomReactionSelectorWeb';
import ChatCustomReactionsListWeb from './ChatCustomReactionsListWeb';
import { anchorComponent } from './CustomAnchorComponent.web';
import { CustomSuggestionItem } from './CustomAutoCompleteTextArea/CustomSuggestionItem';
import { CustomSuggestionList } from './CustomAutoCompleteTextArea/CustomSuggestionList';
import { CustomChannelPreviewMessenger } from './CustomChannelPreviewMessenger.web';
import { CustomDateSeparator } from './CustomDateSeparator';
import { CustomHeader } from './CustomHeader';
import { CustomMessageActionsListWeb } from './CustomMessageActionsListWeb';
import { CustomMessageAvatar } from './CustomMessageAvatar';
import CustomMessageOptions from './CustomMessageOptions.web';
import { CustomMessageTimestamp } from './CustomMessageTimestamp.web';
import { EmptyMessage } from './EmptyMessage';
import InfoChannel from './InforChannel';
import { PinMassageListItem } from './PinMassageListItem';
import { UnreadMessageNotification } from './UnreadMessageNotificationWeb';
import type { Channel as StreamChannel, DefaultGenerics, ChannelFilters, ChannelSort } from 'stream-chat';
init({ data });

const CHANNEL_LIST_WIDTH = 500;

interface Props {
  type: ChannelListCategory;
  disableAddChannel?: boolean;
  messageId?: string;
  channelId?: string;
  addChannel?: React.ReactNode;
  defaultValue?: string;
  isDM?: boolean;
}

interface CustomCSSProperties extends Properties<string | number> {
  '--original-height'?: string;
  '--original-width'?: string;
}

type ChannelListCategory = 'my' | 'dm';

const sort: ChannelSort<DefaultGenerics> = { last_message_at: -1 };

const ChannelWindow: React.FC<{
  defaultValue: string;
  pinMessages: Message[];
}> = ({ defaultValue, pinMessages }) => {
  const { overrideSubmitHandler, isCheckedRef, toggleCheckedRef, uncheckedRef } = useDraft();
  const { shouldSubmit } = useShouldSutmit();
  const showPinMessages = useContext(ShowPinMessagesContext);

  const customRenderText = useCallback(
    (text: any, mentionedUsers: any) => {
      return renderText(text, mentionedUsers, {
        customMarkDownRenderers: { a: anchorComponent },
      });
    },
    [anchorComponent]
  );

  const CustomThreadInputWrapper = useCallback(
    () => (
      <ChatCustomThreadInput
        isChecked={isCheckedRef.current}
        toggleChecked={toggleCheckedRef}
        unchecked={uncheckedRef}
        isThread
      />
    ),
    [isCheckedRef, toggleCheckedRef]
  );
  return (
    <>
      <Window hideOnThread={false}>
        <>
          {showPinMessages ? (
            <Box height="100%">
              <CustomHeader />
              <FlatList
                data={pinMessages}
                keyExtractor={(item, index) => `${(item as Message).id}:${index}`}
                ItemSeparatorComponent={() => <Divider borderColor="gray.200" />}
                renderItem={({ item, index }) => <PinMassageListItem index={index} item={item as object} />}
                ListEmptyComponent={<EmptyMessage emptyMessage="ピンどめされたメッセージはありません" />}
                marginTop={50}
              />
            </Box>
          ) : (
            <>
              <MessageList
                groupStyles={() => 'single'}
                renderText={customRenderText}
                messageLimit={20}
                additionalMessageInputProps={{ mentionQueryParams: { options: { limit: 100 } } }}
              />
              <MessageInput
                overrideSubmitHandler={overrideSubmitHandler}
                shouldSubmit={shouldSubmit}
                grow
                mentionQueryParams={{
                  options: { limit: 100 },
                }}
              />
            </>
          )}
        </>
      </Window>
      <Thread
        Input={CustomThreadInputWrapper}
        additionalMessageInputProps={{
          overrideSubmitHandler,
          shouldSubmit,
          grow: true,
          mentionQueryParams: {
            options: { limit: 100 },
          },
        }}
        additionalParentMessageProps={{
          Message: ChatCustomParentMessage,
        }}
      />
    </>
  );
};

export const ChatChannelListWeb: React.FC<Props> = ({
  disableAddChannel,
  channelId,
  messageId,
  type = 'my',
  addChannel,
  defaultValue,
  isDM,
}) => {
  const { chatUserId, isUserConnected, appChannel } = useStreamChatContext();
  const { pinnedChannels } = usePinnedChannels();
  const [messageAreaWidth, setMessageAreaWidth] = useState(Dimensions.get('window').width - CHANNEL_LIST_WIDTH);
  const { pinMessages } = usePinMessageWeb();
  const [index, setIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpenInfo, setIsOpenInfo] = useState(false);

  useDidMount(() => {
    const handleWindowResize = debounce(
      () => {
        setMessageAreaWidth(Dimensions.get('window').width - CHANNEL_LIST_WIDTH);
      },
      200,
      { maxWait: 1000 }
    );
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  const dispatch = useContext(ShowPinMessagesDispatchContext);

  const closePinMessage = () => {
    dispatch && dispatch({ type: 'close' });
  };

  useDidUpdate(
    () => {
      closePinMessage();
    },
    [appChannel],
    true
  );

  const channelFilters = match<ChannelListCategory>(type)
    .with('my', () => ({
      members: { $in: [chatUserId] },
      isDM: {
        $nin: [true],
      },
    }))
    .with('dm', () => ({
      members: {
        $in: [chatUserId],
      },
      isDM: {
        $eq: true,
      },
      type: {
        $eq: 'messaging',
      },
    }))
    .exhaustive();

  const emptyAlertMessage = match<ChannelListCategory>(type)
    .with('my', () => '参加しているチャンネル一覧が表示されます。')
    .with('dm', () => '参加中のダイレクト・メッセージが表示されます。')
    .exhaustive();

  const channelRenderFilterFn = (channels: StreamChannel[]) => {
    const pinned: StreamChannel[] = [];
    const others: StreamChannel[] = [];
    const pinnedChannelSet = new Set(pinnedChannels);

    channels
      .filter((channel) => (isDM ? channel.data?.isDM : !channel.data?.isDM))
      .forEach((channel) => {
        // 削除済み・削除予定は取り除く
        if (channel.disconnected || channel.data?.to_be_deleted) return;
        // メンバーIDがchatUserIdに一致するかチェック
        if (chatUserId && channel.state.members[chatUserId]) {
          // ピン留めされたチャンネルかどうかをチェック
          if (pinnedChannelSet.has(channel.cid)) {
            pinned.push(channel);
          } else {
            others.push(channel);
          }
        }
      });

    return [...pinned, ...others];
  };

  const CustomEditInputWrapper = useCallback(() => <ChatCustomThreadInput isEdit />, []);

  const renderAttachment = useCallback((props: AttachmentProps) => {
    // Remove duplicate attachments by title and title_link
    const uniqueAttachments = props.attachments.filter(
      (item, index, self) =>
        !item.og_scrape_url || // affects only OGAttachment
        index ===
          self.findIndex(
            (t) =>
              !!t.og_scrape_url && // comparison target must be OGAttachment
              t.title === item.title &&
              (t.title_link ? normalizeUrl(t.title_link) : '') ===
                (item.title_link ? normalizeUrl(item.title_link) : '')
          )
    );

    const checkLink = uniqueAttachments.some((item) => item.type === 'image' && item.hasOwnProperty('title_link'));
    const styles: CustomCSSProperties = {
      '--original-height': '1000000',
      '--original-width': '1000000',
    };

    if (!checkLink) {
      if (props.attachments.length <= 4 && props.attachments.length > 2) {
        return (
          <div className="str-chat__attachment-list">
            <div className="str-chat__message-attachment str-chat__message-attachment-dynamic-size str-chat__message-attachment--gallery">
              <div className="str-chat__gallery str-chat__gallery--square str-chat__gallery-two-rows">
                {uniqueAttachments.map((attachment, index) => (
                  <button
                    className="str-chat__gallery-image"
                    data-testid="gallery-image"
                    key={`gallery-image-${index}`}
                    onClick={() => {
                      setIsModalOpen(true);
                      setIndex(index);
                    }}
                  >
                    <img
                      data-testid="str-chat__base-image"
                      alt={attachment.fallback}
                      src={attachment.image_url}
                      title={attachment.fallback}
                      className="str-chat__base-image"
                      style={styles}
                    />
                  </button>
                ))}
                <Modal
                  className="str-chat__gallery-modal"
                  onClose={() => setIsModalOpen((isModalOpen) => !isModalOpen)}
                  open={isModalOpen}
                >
                  <ModalGallery images={props.attachments} index={index} />
                </Modal>
              </div>
            </div>
          </div>
        );
      } else {
        return <Attachment {...props} attachments={uniqueAttachments} />;
      }
    } else {
      return <Attachment {...props} attachments={uniqueAttachments} />;
    }
  }, []);

  const renderPreview = useCallback(
    (props: ChannelPreviewUIComponentProps) => {
      return <CustomChannelPreviewMessenger {...props} isDM={isDM} />;
    },
    [isDM]
  );
  const renderUnreadMessagesSeparatorEmpty = useCallback(() => {
    return null;
  }, []);

  const renderMessageActionList = useCallback(
    (props: CustomMessageActionsListProps) => {
      return <CustomMessageActionsListWeb {...props} isDM={isDM} />;
    },
    [isDM]
  );

  const renderUnreadMessageNotification = useCallback((props: UnreadMessagesNotificationProps) => {
    if (!props.unreadCount || props.unreadCount <= 0) {
      return null;
    }
    return <UnreadMessageNotification {...props} />;
  }, []);

  return isUserConnected ? (
    <HStack backgroundColor="surfaceBrightest" height="100%" overflowX="hidden">
      <VStack
        maxWidth="300px"
        borderRightWidth={gluestackUIConfig.tokens.borderWidths.medium}
        borderColor="outline"
        backgroundColor="surfaceBrightest"
      >
        {/* {!disableAddChannel && (
          <HStack paddingX={5} paddingY={4} width="100%">
            {addChannel}
          </HStack>
        )} */}
        <Box m="sm" px="sm" backgroundColor="surface" borderRadius={gluestackUIConfig.tokens.radii.full}>
          <Input
            variant="container"
            p="sm"
            placeholder="検索"
            color="onSurfaceBright"
            fontSize="sm"
            borderWidth={gluestackUIConfig.tokens.borderWidths[0]}
            InputLeftElement={<Icon as={MaterialIcons} name="search" size="md" color="onSurfaceBright" />}
          />
        </Box>
        <HStack px="sm" gap="sm">
          <HStack
            px="2xs"
            py="sm"
            borderBottomWidth={gluestackUIConfig.tokens.borderWidths.thick}
            borderColor="primary"
            gap="2xs"
          >
            <Text color="onSurface" fontSize="md" fontWeight="bold">
              すべて
            </Text>
          </HStack>
          <HStack px="2xs" py="sm" gap="2xs">
            <Text color="onSurfaceBrightest" fontSize="md" fontWeight="bold">
              ルーム
            </Text>
            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
              <circle cx="3" cy="3" r="3" fill={gluestackUIConfig.tokens.colors.primary} />
            </svg>
          </HStack>
          <HStack px="2xs" py="sm" gap="2xs">
            <Text color="onSurfaceBrightest" fontSize="md" fontWeight="bold">
              DM
            </Text>
            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
              <circle cx="3" cy="3" r="3" fill={gluestackUIConfig.tokens.colors.primary} />
            </svg>
          </HStack>
          <Box px="2xs" py="sm" gap="2xs">
            <Text color="onSurfaceBrightest" fontSize="md" fontWeight="bold">
              フォルダ1
            </Text>
          </Box>
        </HStack>
        <ScrollView>
          <ChannelList
            filters={channelFilters as ChannelFilters<DefaultGenerics>}
            sort={sort}
            channelRenderFilterFn={channelRenderFilterFn}
            EmptyStateIndicator={() => (
              <Alert status="info" margin={4}>
                {emptyAlertMessage}
              </Alert>
            )}
            Preview={renderPreview as ComponentType<ChannelPreviewUIComponentProps<DefaultGenerics>>}
          />
        </ScrollView>
      </VStack>
      <Stack flexGrow={1}>
        <Channel
          channel={appChannel}
          channelQueryOptions={{
            messages: {
              limit: 20,
            },
          }}
          Avatar={CustomMessageAvatar}
          EditMessageInput={CustomEditInputWrapper}
          ReactionSelector={
            ChatCustomReactionSelectorWeb as ForwardRefExoticComponent<ReactionSelectorProps<DefaultStreamChatGenerics>>
          }
          ReactionsList={ChatCustomReactionsListWeb}
          AutocompleteSuggestionList={CustomSuggestionList as unknown as ComponentType<SuggestionListProps>}
          AutocompleteSuggestionItem={CustomSuggestionItem as unknown as ComponentType<SuggestionItemProps>}
          Message={ChatCustomMessage}
          MessageNotification={ScrollToBottomButton}
          CustomMessageActionsList={renderMessageActionList}
          MessageTimestamp={CustomMessageTimestamp}
          MessageOptions={CustomMessageOptions}
          Input={ChatCustomThreadInput as ComponentType<MessageInputProps>}
          Attachment={renderAttachment}
          HeaderComponent={() => <CustomHeader handleOpenInfo={() => setIsOpenInfo(!isOpenInfo)} />}
          emojiSearchIndex={SearchIndex}
          DateSeparator={CustomDateSeparator}
          UnreadMessagesSeparator={appChannel?.countUnread() == 0 ? renderUnreadMessagesSeparatorEmpty : undefined}
          UnreadMessagesNotification={renderUnreadMessageNotification}
        >
          <ChannelSwitcher
            channelId={channelId}
            messageId={messageId}
            channelType={
              type === 'dm' || channelId?.match(/^chumly-self-.+/) || channelId?.match(/^user-channel-secret-.+/)
                ? 'messaging'
                : 'team'
            }
          />
          <HStack width="100%">
            <ChannelWindow defaultValue={defaultValue ?? ''} pinMessages={pinMessages} />
          </HStack>
        </Channel>
      </Stack>
      <InfoChannel open={isOpenInfo} onClose={() => setIsOpenInfo(!isOpenInfo)} appChannel={appChannel} />
    </HStack>
  ) : (
    <Center flex={1}>
      <Spinner />
    </Center>
  );
};
