import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { useRoute, useNavigation } from '@react-navigation/native';
import * as React from 'react';

import { useGetGroupsByGroupIdQuery, usePutGroupsByGroupIdMutation } from '~/api/uFeedApi';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';
import { EditFormBuilder } from '~/components/builder/EditFormBuilder';
import { ScreenWidthModal } from '~/components/ScreenWithModal';

export const GroupEditScreen: React.FC = () => {
  const { params } = useRoute();
  const navigation = useNavigation();
  // @ts-expect-error TS(2339): Property 'queryParams' does not exist on type 'obj... Remove this comment to see the full error message
  const queryParams = params?.queryParams || {};
  const queryResult = useGetGroupsByGroupIdQuery(queryParams);
  const [update, { isLoading: isUpdating, data }] = usePutGroupsByGroupIdMutation();

  useDidUpdate(() => {
    if (queryResult.data?.name) {
      navigation?.setOptions({
        title: queryResult.data?.name,
      });
    }
  }, [queryResult.data?.name]);

  return (
    <ScreenWidthModal>
      <BaseScreenBuilder>
        <EditFormBuilder
          // @ts-expect-error TS(2322): Type 'UseQueryHookResult<QueryDefinition<GetGroups... Remove this comment to see the full error message
          queryResult={queryResult}
          // @ts-expect-error TS(2322): Type 'MutationTrigger<MutationDefinition<PutGroups... Remove this comment to see the full error message
          update={update}
          updateResult={{ isUpdating, data }}
          emptyMessage="指定されたグループがありません"
          fields={[
            {
              key: 'name',
              label: '名前',
            },
          ]}
        />
      </BaseScreenBuilder>
    </ScreenWidthModal>
  );
};
