import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { useState } from 'react';

import { useGetCurrentUserSettingQuery, usePutCurrentUserSettingMutation } from '~/api/uFeedApi';

export const usePinnedChannels = () => {
  const userSetting = useGetCurrentUserSettingQuery();
  const [put] = usePutCurrentUserSettingMutation();

  const [pinnedChannels, setPinnedChannels] = useState<string[]>([]);

  useDidUpdate(
    () => {
      if (userSetting.data?.details?.pinnedChannels) {
        pinnedChannelsQuery();
      }
    },
    [userSetting.data],
    true
  );

  const pinnedChannelsQuery = async () => {
    if (!userSetting.data || !userSetting.data?.details?.pinnedChannels) {
      setPinnedChannels([]);
      return;
    }

    setPinnedChannels([...userSetting.data?.details.pinnedChannels]);
  };

  const addPinnedChannel = async (channelId: string) => {
    const channelSet = new Set(userSetting.data?.details?.pinnedChannels ?? []);
    channelSet.add(channelId);
    setPinnedChannels(Array.from(channelSet));
    await put({
      body: {
        user_setting: {
          details: {
            ...userSetting.data?.details,
            pinnedChannels: Array.from(channelSet),
          },
        },
      },
    }).unwrap();
  };

  const removePinnedChannel = async (channelId: string) => {
    const channelSet = new Set(userSetting.data?.details?.pinnedChannels ?? []);
    channelSet.delete(channelId);
    setPinnedChannels(Array.from(channelSet));
    await put({
      body: {
        user_setting: {
          details: {
            ...userSetting.data?.details,
            pinnedChannels: Array.from(channelSet),
          },
        },
      },
    }).unwrap();
  };

  return {
    addPinnedChannel,
    removePinnedChannel,
    pinnedChannels,
    pinnedChannelsQuery,
  };
};
