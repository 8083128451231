import { VStack } from '@gluestack-ui/themed-native-base';
import React from 'react';

interface Props {
  space?: number;
}

export const SectionList: React.FC<Props & React.PropsWithChildren> = ({ children, space = 6 }) => {
  return <VStack space={space}>{children}</VStack>;
};
