import { Ionicons } from '@expo/vector-icons';
import { Fab, Icon, Skeleton, StatusBar, VStack } from '@gluestack-ui/themed-native-base';
import { useRoute, useNavigation, useLinkTo } from '@react-navigation/native';
import * as React from 'react';
import { Dimensions, Platform } from 'react-native';
import { SceneMap, TabView, TabBar } from 'react-native-tab-view';

import { TaskList } from '~/components';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';

import { useTasks } from './useTasks';

const initialLayout = {
  width: Dimensions.get('window').width,
};

const { useState, useMemo } = React;

export const TaskSummaryScreen: React.FC = () => {
  const { params } = useRoute();
  const queryParams = params ? { ...params } : {};
  const navigation = useNavigation();
  const linkTo = useLinkTo();

  const { tasks, groupedTasks, commingTasks, noDeadlineTasks, groupKeys } = useTasks();
  const [index, setIndex] = useState(0);
  const [routes] = useState([
    {
      key: 'my',
      title: '期限日',
    },
    {
      key: 'grouped',
      title: 'グループ別',
    },
  ]);
  const my = React.memo(() => {
    const { tasks, commingTasks, noDeadlineTasks } = useTasks();
    const taskWithLabel =
      commingTasks.length > 0 && noDeadlineTasks.length > 0
        ? ['期限日順', ...commingTasks, '期限なし', ...noDeadlineTasks]
        : commingTasks.length > 0 && noDeadlineTasks.length == 0
          ? ['期限日順', ...commingTasks]
          : commingTasks.length == 0 && noDeadlineTasks.length > 0
            ? ['期限なし', ...noDeadlineTasks]
            : [];
    return (
      <BaseScreenBuilder noScroll flex={1}>
        {tasks.isLoading ? (
          <VStack space={6} flex={1}>
            {[...new Array(10)].map((_, index) => (
              <Skeleton.Text key={`my-task-skeleton-${index}`} />
            ))}
          </VStack>
        ) : (
          <TaskList tasks={taskWithLabel} />
        )}
      </BaseScreenBuilder>
    );
  });

  const grouped = React.memo(() => {
    const { tasks, groupedTasks } = useTasks();
    return (
      <BaseScreenBuilder noScroll flex={1}>
        {tasks.isLoading ? (
          <VStack space={6} flex={1}>
            {[...new Array(10)].map((item, index) => (
              <Skeleton.Text key={`grouped-task-skeleton-${index}`} />
            ))}
          </VStack>
        ) : (
          <TaskList tasks={groupedTasks} showAssignedUser />
        )}
      </BaseScreenBuilder>
    );
  });

  const renderScene = useMemo(() => {
    return SceneMap({
      my,
      grouped,
    });
  }, []);

  const renderTabBar = (props: any) => {
    return (
      <TabBar
        {...props}
        index={index}
        setIndex={setIndex}
        indicatorStyle={{ backgroundColor: '#0EC9E5' }}
        style={{ backgroundColor: 'white', color: 'black' }}
        activeColor="black"
        inactiveColor="#bbb"
      />
    );
  };

  return (
    <>
      <TabView
        navigationState={{
          index,
          routes,
        }}
        onIndexChange={setIndex}
        renderScene={renderScene}
        renderTabBar={renderTabBar}
        initialLayout={initialLayout}
        style={{
          // @ts-expect-error TS(2339): Property 'currentHeight' does not exist on type 'F... Remove this comment to see the full error message
          marginTop: StatusBar.currentHeight,
        }}
        keyboardDismissMode={Platform.OS === 'web' ? 'none' : 'auto'}
        lazy
      />
      <Fab
        onPress={() => {
          Platform.OS === 'web'
            ? linkTo(`/farms/${undefined}/groups/${undefined}/tasks/create_edit/mytask`)
            : // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
              navigation.navigate('TaskCreateEdit', {
                ...queryParams,
              });
        }}
        placement="bottom-right"
        bottom={5}
        right={5}
        size="lg"
        backgroundColor="#0EC9E5"
        icon={<Icon as={Ionicons} name="add-outline" backgroundColor="#0EC9E5" size="md" />}
        renderInPortal={false}
      />
    </>
  );
};
