import { HStack, VStack, Text, Center, Alert } from '@gluestack-ui/themed-native-base';
import { ReactNode } from 'react';
interface Props {
  emptyMessage: string | ReactNode;
}

export const EmptyMessage: React.FC<Props> = ({ emptyMessage }) => {
  return (
    <Center paddingX={6} paddingY={4}>
      <Alert maxWidth={400}>
        <VStack flexShrink={1} width="100%">
          <HStack alignItems="center" justifyContent="space-between" space={2} flexShrink={1}>
            <Alert.Icon />
            {emptyMessage ? (
              typeof emptyMessage === 'string' ? (
                <Text flexShrink={1} fontSize="sm">
                  {emptyMessage}
                </Text>
              ) : (
                { emptyMessage }
              )
            ) : null}
          </HStack>
        </VStack>
      </Alert>
    </Center>
  );
};
