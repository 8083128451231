import { VStack, Text } from '@gluestack-ui/themed-native-base';
import React from 'react';

import { useGetTasksByIdQuery } from '~/api/uFeedApi';
import { DetailsBuilder } from '~/components/builder/DetailsBuilder';

interface Props {
  id: number;
}

export const PreviewTask: React.FC<Props> = React.memo(({ id }) => {
  const task = useGetTasksByIdQuery({ id });

  if (task.isLoading || task.isError) {
    return null;
  }

  return (
    <VStack
      marginTop={1}
      marginBottom={2}
      space={0}
      padding={2}
      backgroundColor="gray.50"
      borderRadius={6}
      borderWidth={1}
      borderColor="gray.100"
      overflow="hidden"
    >
      <Text marginBottom={2}>タスク</Text>
      <DetailsBuilder
        // @ts-expect-error TS(2322): Type '({ data?: undefined; error?: undefined; fulf... Remove this comment to see the full error message
        queryResult={task}
        emptyMessage="タスクデータがありません"
        space={2}
        fields={[
          {
            key: 'content',
            label: '内容',
          },
          {
            key: 'status',
            label: 'ステータス',
            type: 'key-value',
            // @ts-expect-error TS(2322): Type '{ key: string; label: string; type: string; ... Remove this comment to see the full error message
            options: {
              open: '未着手',
              'in progress': '作業中',
              closed: '完了',
            },
          },
          {
            key: 'deadline',
            type: 'date',
            label: '締め切り',
          },
          {
            key: 'assigned_user',
            label: '担当者',
            type: 'user',
          },
        ]}
      />
    </VStack>
  );
});
