import { MantineProvider } from '@mantine/core';
import { PushNotificationHandler } from '~/components/PushNotificationHandler';
import { StreamChatContextProvider } from '~/contexts/StreamChatContext';
import { useAvatars } from '~/hooks';
import { DrawerNavigator } from './DrawerNavigator';

export const ChatApplication = () => {
  // 初回起動時にuseAvatars()が複数回呼ばれて /current_user/groups へのアクセスが回数分発生するため、ここでキャッシュを作っておく
  useAvatars();

  return (
    <MantineProvider>
      <StreamChatContextProvider>
        <DrawerNavigator />
        <PushNotificationHandler />
      </StreamChatContextProvider>
    </MantineProvider>
  );
};
