import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { useCallback, useState } from 'react';

import { useGetCurrentUserGroupsQuery } from '~/api/uFeedApi';
import { katakanaToHiragana } from '~/utils/string';

export type YomiInfo = {
  userId?: number;
  chatUserId?: string;
  name?: string;
  hiraYomi?: string;
  kanaYomi?: string;
};

export const useYomi = () => {
  const [yomiList, setYomiList] = useState<YomiInfo[]>([]);
  const currentUserGroupsQuery = useGetCurrentUserGroupsQuery();

  useDidUpdate(
    () => {
      if (!currentUserGroupsQuery.isSuccess) {
        return;
      }
      const groups = currentUserGroupsQuery.data || [];
      const users = groups
        .map((g) => g.approved_users)
        ?.flat()
        .map((u) => {
          return {
            userId: Number(u?.id),
            chatUserId: u?.chat_user_id,
            name: u?.name,
            hiraYomi: katakanaToHiragana(u?.yomi || ''),
            kanaYomi: u?.yomi,
          };
        }) as YomiInfo[];

      setYomiList(users);
    },
    [currentUserGroupsQuery.data],
    true
  );

  const searchYomi = useCallback(
    (query: string) => {
      const uncapitalizedQuery = query.toLocaleLowerCase();
      return yomiList.filter(
        (yomi) =>
          yomi.hiraYomi?.toLocaleLowerCase().includes(uncapitalizedQuery) ||
          yomi.kanaYomi?.toLocaleLowerCase().includes(uncapitalizedQuery) ||
          yomi.name?.toLocaleLowerCase().includes(uncapitalizedQuery)
      );
    },
    [yomiList]
  );

  return {
    yomiList,
    searchYomi,
  };
};
