import { Ionicons } from '@expo/vector-icons';
import { VStack, Icon, View, Center, HStack, Text } from '@gluestack-ui/themed-native-base';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import * as React from 'react';
import { TouchableOpacity } from 'react-native';
import { Calendar } from 'react-native-calendars';

import { BaseScreenBuilder } from '~/components/builder';
import { ScreenWidthModal } from '~/components/ScreenWithModal';

interface Data {
  label: string;
  value: string;
}

type ParamList = {
  Detail: {
    field: any;
    rules: any;
  };
};

const renderArrow = (direction: any) => {
  return direction === 'left' ? (
    <Icon as={Ionicons} name="chevron-back-outline" size="md" />
  ) : (
    <Icon as={Ionicons} name="chevron-forward-outline" size="md" />
  );
};

export const CalendarScreen: React.FC = () => {
  const navigation = useNavigation();
  const { params } = useRoute<RouteProp<ParamList, 'Detail'>>();

  const onClear = () => {
    params.field.onChange('');
    navigation.goBack();
  };

  return (
    <ScreenWidthModal title="カレンダー">
      <BaseScreenBuilder title="カレンダー">
        <View width="100%">
          <VStack>
            <Calendar
              selected={params.field.value}
              current={params.field.value}
              key={params.field.value}
              onDayPress={(date) => {
                params.field.onChange(date.dateString);
                navigation.goBack();
              }}
              monthFormat="yyyy年 MM月"
              enableSwipeMonths
              markingType="period"
              markedDates={
                params.field.value
                  ? {
                      [params.field.value]: {
                        selected: true,
                        color: '#0EC9E5',
                        startingDay: true,
                        endingDay: true,
                      },
                    }
                  : {}
              }
              maxDate={params.rules?.max}
              renderArrow={renderArrow}
            />
            <Center>
              <TouchableOpacity onPress={() => (params.field.value ? onClear() : navigation.goBack())}>
                <HStack space={2} alignItems="center" padding={2}>
                  <Icon as={Ionicons} name="close-outline" />
                  <Text>{params.field.value ? 'クリア' : '閉じる'}</Text>
                </HStack>
              </TouchableOpacity>
            </Center>
          </VStack>
        </View>
      </BaseScreenBuilder>
    </ScreenWidthModal>
  );
};
