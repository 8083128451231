import { useRef, useState } from 'react';
import { ChannelMemberResponse } from 'stream-chat';

import { useStreamChatContext } from '~/contexts/StreamChatContext';

export const useMessageQuery = () => {
  const [messages, setMessages] = useState([]);
  const [isReachingEnd, setIsReachingEnd] = useState<boolean>(false);
  const [isPagingEnd, setIsPagingEnd] = useState<boolean>(false);
  const massageOffsetRef = useRef(0);
  const { chatUserId, chatClient } = useStreamChatContext();

  const messagelimit = 300;

  const resetMessages = () => {
    setMessages([]);
    massageOffsetRef.current = 0;
  };

  const messageQuery = async (
    messageQuery: string,
    user: ChannelMemberResponse | null = null,
    cidChannel: string | null = null,
    isPageing = false
  ): Promise<number | undefined> => {
    if (isPageing && isReachingEnd && isPagingEnd) return;

    if (!isPageing) {
      setIsReachingEnd(false);
    }

    const channelFilters = {
      ...(cidChannel && { cid: { $eq: cidChannel } }),
      members: {
        $in: [chatUserId],
      },
    };

    massageOffsetRef.current = massageOffsetRef.current + messagelimit;

    const resp = await chatClient
      .search(
        // @ts-expect-error TS(2345): Argument of type '{ members: { $in: (string | unde... Remove this comment to see the full error message
        channelFilters,
        {
          ...(messageQuery && { text: { $autocomplete: messageQuery } }),
          ...(user && { 'user.id': { $eq: user.user_id } }),
        },
        {
          limit: messagelimit,
          offset: isPageing ? massageOffsetRef.current - messagelimit : 0,
          sort: [{ created_at: -1 }],
        }
      )
      .catch((error) => {
        console.error('message search error: ', error);
      });

    const listWard = messageQuery.split(/\s+/);

    const filtedMessage =
      resp?.results.filter((m) =>
        listWard.every((w) => m?.message?.text?.toLocaleLowerCase().includes(w.toLocaleLowerCase()))
      ) ?? [];

    if (resp?.results.length === 0) {
      setIsPagingEnd(true);
    }
    if (filtedMessage.length === 0) {
      setIsReachingEnd(true);
      return 0;
    }

    // @ts-expect-error TS(2345): Argument of type '{ message: MessageResponse<Strea... Remove this comment to see the full error message
    setMessages(isPageing ? [...messages, ...filtedMessage] : [...filtedMessage]);
    return filtedMessage.length;
  };

  const pagingMessageQuery = async (
    query: string,
    user: ChannelMemberResponse | null = null,
    cidChannel: string | null = null,
    isPageing = false
  ) => {
    let count = 0;
    let messageCount: number | undefined = 0;

    while (!messageCount && count < 5) {
      count++;
      messageCount = await messageQuery(query, user, cidChannel, isPageing);
    }
  };

  return {
    isReachingEnd,
    messageQuery,
    messages,
    resetMessages,
    massageOffsetRef,
    pagingMessageQuery,
  };
};
