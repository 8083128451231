import { useDidMount } from '@better-typed/react-lifecycle-hooks';
import * as Network from 'expo-network';
import { useState } from 'react';

export const useNetworkStatus = () => {
  const [isConnectedToInternet, setIsConnectedToInternet] = useState(false);

  useDidMount(() => {
    Network.getNetworkStateAsync()
      .then((result) => setIsConnectedToInternet((result?.isConnected && result?.isInternetReachable) ?? false))
      .catch((error) => console.error(error));
  });

  return { isConnectedToInternet };
};
