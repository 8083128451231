import { refreshTokenApi } from './uMotionBaseQuery';

export const addTagTypes = [] as const;

const injectedRtkRefreshTokenApi = refreshTokenApi
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      refreshToken: build.mutation<RefreshTokenApiResponse, RefreshTokenApiArg>({
        query: (queryArg) => ({
          url: `/session`,
          method: 'PUT',
          body: queryArg.body,
        }),
      }),
    }),
    overrideExisting: false,
  });

export { injectedRtkRefreshTokenApi as uMotionRefreshTokenApi };

export type RefreshTokenApiResponse = /** status 200 Successful login */ {
  sessionToken?: string;
  refreshToken?: string;
  expiredAt?: string;
};

export type RefreshTokenApiArg = {
  body: {
    refreshToken: string;
  };
};

export const { useRefreshTokenMutation } = injectedRtkRefreshTokenApi;
