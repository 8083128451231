import { memo } from 'react';

import { MessageOptions, useMessageContext } from '~/lib/StreamChatReact';
import type { DefaultStreamChatGenerics, StreamMessage } from '~/lib/StreamChatReact';

import type { ReactElement } from 'react';

type Props = {
  message: StreamMessage<DefaultStreamChatGenerics>;
};

const CustomMessageOptionsWithContext = ({ message }: Props): ReactElement | null => {
  return <MessageOptions displayReplies={!message.show_in_channel} />;
};

const MemorizedCustomMessageOptions = memo(
  CustomMessageOptionsWithContext,
  (prev, next) => prev.message.show_in_channel === next.message.show_in_channel
);

const CustomMessageOptions = (): ReactElement | null => {
  const { message } = useMessageContext();
  return <MemorizedCustomMessageOptions message={message} />;
};

export default CustomMessageOptions;
