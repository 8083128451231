import { Button, Divider, Input, Text, VStack } from '@gluestack-ui/themed-native-base';
import { useRoute, useNavigation, useLinkTo } from '@react-navigation/native';
import * as React from 'react';
import { Platform } from 'react-native';

import {
  useGetGroupsByGroupIdQuery,
  useGetFarmsByFarmIdUmCattleGroupsQuery,
  useDeleteGroupsByGroupIdMutation,
} from '~/api/uFeedApi';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';
import { DetailsBuilder } from '~/components/builder/DetailsBuilder';
import { GroupMemberCard } from '~/components/GroupMemberCard';
import { SectionList } from '~/components/SectionList';

export const AdminGroupDetailScreen: React.FC = () => {
  const { params } = useRoute();
  const navigation = useNavigation();

  const queryParams = params ? { ...params } : {};
  // @ts-expect-error TS(2339): Property 'groupId' does not exist on type '{}'.
  const queryResult = useGetGroupsByGroupIdQuery({ groupId: queryParams?.groupId });

  // @ts-expect-error TS(2345): Argument of type '{}' is not assignable to paramet... Remove this comment to see the full error message
  const umCattleGroups = useGetFarmsByFarmIdUmCattleGroupsQuery(queryParams);
  const [deleteFarms, deleteFarmsResult] = useDeleteGroupsByGroupIdMutation();
  const linkTo = useLinkTo();

  return (
    <BaseScreenBuilder title="">
      <SectionList>
        <DetailsBuilder
          // @ts-expect-error TS(2322): Type 'UseQueryHookResult<QueryDefinition<GetGroups... Remove this comment to see the full error message
          queryResult={queryResult}
          // @ts-expect-error TS(2322): Type '() => MutationActionCreatorResult<MutationDe... Remove this comment to see the full error message
          onDeletePress={() => {
            return deleteFarms({
              // @ts-expect-error TS(2339): Property 'groupId' does not exist on type '{}'.
              groupId: queryParams.groupId,
            });
          }}
          emptyMessage="グループがありません"
          fields={[
            {
              key: 'name',
              label: '名前',
            },
            // {
            //   key: 'details',
            //   label: '詳細',
            // },
          ]}
        />
        <VStack space={1}>
          <Text fontWeight="bold" color="gray.400">
            詳細
          </Text>
          {/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
          {queryResult.data?.details.map((datail) => (
            <Input
              type="text"
              value={
                datail.hasOwnProperty('month_age')
                  ? `月齢:${datail.month_age?.from}〜${datail.month_age?.to}`
                  : datail.um_cattle_group?.hasOwnProperty('pen')
                    ? `牛群（牛房）:${
                        umCattleGroups.data?.find((group) => group.id === datail.um_cattle_group?.id)?.name
                      }（${datail.um_cattle_group?.pen}）`
                    : `牛群:${umCattleGroups.data?.find((group) => group.id === datail.um_cattle_group?.id)?.name}`
              }
              fontSize="md"
              fontWeight="bold"
              borderWidth={0}
              padding={0}
              editable={false}
            />
          ))}
        </VStack>
        <Button
          variant="outline"
          onPress={() =>
            Platform.OS === 'web'
              ? // @ts-expect-error TS(2339): Property 'farmId' does not exist on type '{}'.
                linkTo(`/admin/group/create_edit/farms/${queryParams?.farmId}/groups/${queryParams?.groupId}`)
              : // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                navigation.navigate('AdminGroupCreateEdit', {
                  ...queryParams,
                })
          }
        >
          編集
        </Button>
        <Divider />
        {/* @ts-expect-error TS(2339): Property 'groupId' does not exist on type '{}'. */}
        <GroupMemberCard groupId={queryParams?.groupId} admin />
      </SectionList>
    </BaseScreenBuilder>
  );
};
