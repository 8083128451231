import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { Box, Skeleton, VStack } from '@gluestack-ui/themed-native-base';
import { StyledProps } from 'native-base';
import * as React from 'react';

import { useActivitiesByRange } from '~/hooks/useActivitiesByRange';
import { VictoryBar, VictoryChart, VictoryTheme, VictoryStack, VictoryAxis, VictoryLegend } from '~/lib/Victory';
import { DateUtil } from '~/utils/DateUtils';

import { GraphAlertBox } from './GraphAlertBox';
import { TableChart } from './TableChart';

interface Props {
  endDate: Date;
  range: number;
  chatChannelId?: string;
  groupId?: number;
  farmId?: number;
  isPreview?: boolean;
  width?: number;
  height?: number;
  showTable?: boolean;
  shareButton?: React.ReactElement;
}

export const ActivityStackChart: React.FC<Props & StyledProps> = React.memo(
  ({
    endDate,
    range,
    groupId,
    farmId,
    shareButton,
    isPreview = false,
    width = 400,
    height = 400,
    showTable = false,
  }) => {
    // @ts-expect-error
    const { setEndDate, setRange, activities, range: currentRange } = useActivitiesByRange(groupId, endDate, range);
    const barWidth = currentRange === 30 ? 8 : 3;

    useDidUpdate(
      () => {
        setEndDate(endDate);
      },
      [endDate],
      true
    );

    useDidUpdate(
      () => {
        setRange(range);
      },
      [range],
      true
    );

    const stackedBarChart = React.useMemo(
      () => (
        <VictoryChart
          width={width}
          height={height}
          theme={VictoryTheme.material}
          domainPadding={{ x: [0, 0], y: [0, 0] }}
          padding={
            isPreview ? { top: 20, bottom: 40, left: 40, right: 20 } : { top: 50, bottom: 26, left: 50, right: 20 }
          }
        >
          <VictoryAxis
            fixLabelOverlap
            tickFormat={(x) => {
              const date = new Date(x);
              return `${date.getMonth() + 1}/${date.getDate()}`;
            }}
          />
          <VictoryAxis dependentAxis tickFormat={(x) => x} />
          {activities.data?.map((daily) => {
            return (
              <VictoryStack key={daily.date}>
                <VictoryBar
                  barWidth={barWidth}
                  style={{ data: { fill: '#074f07' } }}
                  data={[{ x: daily.date, y: daily?.average_feed_min }]}
                />
                <VictoryBar
                  barWidth={barWidth}
                  style={{ data: { fill: '#31a062' } }}
                  data={[{ x: daily.date, y: daily?.average_move_min }]}
                />
                <VictoryBar
                  barWidth={barWidth}
                  style={{ data: { fill: '#ff8c00' } }}
                  data={[
                    {
                      x: daily.date,
                      // @ts-expect-error TS(2551): Property 'average_rumination_standing_min' does no... Remove this comment to see the full error message
                      y: daily?.average_rumination_standing_min,
                    },
                  ]}
                />
                <VictoryBar
                  barWidth={barWidth}
                  data={[
                    {
                      x: daily.date,
                      y: daily?.average_rumination_lying_min,
                    },
                  ]}
                  style={{ data: { fill: '#ffd700' } }}
                />
                <VictoryBar
                  barWidth={barWidth}
                  style={{ data: { fill: '#dcdcdc' } }}
                  data={[{ x: daily.date, y: daily?.average_stand_min }]}
                />
                <VictoryBar
                  barWidth={barWidth}
                  style={{ data: { fill: '#a9a9a9' } }}
                  data={[{ x: daily.date, y: daily?.average_lie_min }]}
                />
              </VictoryStack>
            );
          })}
          {!isPreview ? (
            <VictoryLegend
              orientation="horizontal"
              x={60}
              gutter={20}
              data={[
                { name: '採食', symbol: { fill: '#074f07' } },
                { name: '動態', symbol: { fill: '#31a062' } },
                { name: '起立(反芻)', symbol: { fill: '#ff8c00' } },
              ]}
            />
          ) : null}
          {!isPreview ? (
            <VictoryLegend
              x={60}
              y={20}
              orientation="horizontal"
              gutter={40}
              data={[
                { name: '横臥(反芻)', symbol: { fill: '#ffd700' } },
                { name: '起立(非活動)', symbol: { fill: '#dcdcdc' } },
                { name: '横臥(非活動)', symbol: { fill: '#a9a9a9' } },
              ]}
            />
          ) : null}
        </VictoryChart>
      ),
      [activities]
    );

    if (activities?.isLoading) {
      return <Skeleton height={height} />;
    }

    if (activities?.isError) {
      return (
        <GraphAlertBox
          onPress={() => {
            activities.refetch();
          }}
        />
      );
    }

    if (isPreview) {
      return <VStack>{stackedBarChart}</VStack>;
    }

    return (
      <>
        <Box paddingX="auto">{stackedBarChart}</Box>
        {activities?.isSuccess ? (
          <TableChart
            data={activities.data.map((item) => ({
              ...item,
              date_label: DateUtil.toMMDD(item.date),
            }))}
            headers={['日付', '採食', '動態', `起立(反芻)`, `起立(非活動)`, '横臥(反芻)', '横臥(非活動)']}
            columns={[
              'date_label',
              'average_feed_min',
              'average_move_min',
              'average_rumination_standing_min',
              'average_stand_min',
              'average_rumination_lying_min',
              'average_lie_min',
            ]}
            padding={4}
            showTable={showTable}
            widthArr={[50, 50, 50, 50, 50, 50, 50]}
          />
        ) : null}
        {shareButton ? shareButton : null}
      </>
    );
  }
);
