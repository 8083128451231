import { Button, VStack, Text } from '@gluestack-ui/themed-native-base';
import { useState } from 'react';
import * as React from 'react';
import { TouchableOpacity } from 'react-native-gesture-handler';

import { DetailList } from '~/components/builder';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';

export const MyAppointmentDetailScreen: React.FC = () => {
  const [selected, setSelected] = useState('');
  return (
    <BaseScreenBuilder>
      <VStack space={6}>
        <DetailList>
          <VStack space={4} paddingBottom={4}>
            <Text bold color="black">
              タイトル
            </Text>
            <Text fontSize="lg">飼養管理のアドバイス</Text>
            <Text bold color="black">
              概要
            </Text>
            {/* @ts-expect-error TS(2786): 'TouchableOpacity' cannot be used as a JSX compone... Remove this comment to see the full error message */}
            <TouchableOpacity onPress={() => navigator.navigate('AppoiuntmentApply')}>
              <Text fontSize="lg">飼養管理のアドバイスを実施します。</Text>
            </TouchableOpacity>

            <Text bold color="black">
              予約日
            </Text>
            <Text fontSize="lg">2023年8月9日 10:00〜10:30</Text>
          </VStack>
          <Button
            variant="outline"
            colorScheme="warning"
            onPress={() => {
              // @ts-expect-error TS(2304): Cannot find name 'setIsBooked'.
              setIsBooked(true);
              // @ts-expect-error TS(2304): Cannot find name 'toast'.
              toast.show({ description: 'スケジュールの予約が完了しました', placement: 'top' });
            }}
          >
            このスケジュールをキャンセルする
          </Button>
        </DetailList>
      </VStack>
    </BaseScreenBuilder>
  );
};
