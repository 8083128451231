import { U_FEED_URL_BASE } from '@env';
import { useLinkTo, useNavigation } from '@react-navigation/native';
import { useCallback } from 'react';
import { Linking } from 'react-native';
import { generateNavigateParameterFromPath } from '~/utils/generateNavigateParameterFromPath';
import { getActiveRouteState } from '~/utils/getActiveRouteState';

export const useChumlyLink = () => {
  const extractURLs = (str: string) => {
    const regex = /\\b((?:https?):\/\/[A-Z0-9+&@#\\/%?=~_|!:,.;]\\*[A-Z0-9+&@#\\/%=~_|])/gi;

    const urlList: string[] = [];
    let match;
    while ((match = regex.exec(str)) !== null) {
      urlList.push(match[0]);
    }

    return urlList;
  };

  const navigation = useNavigation();
  const linkTo = useLinkTo();
  const onPress = useCallback(
    (url: string) => {
      const parsedUrl = extractURLs(url)?.[0] || url;
      const domain = parsedUrl.match(/^https?:\/{2,}(.*?)(?:\/|\?|#|$)/)?.[1];
      const path = parsedUrl.match(/^https?:\/{2,}.*?(\/.*)/)?.[1];
      if (domain === U_FEED_URL_BASE.match(/^https?:\/{2,}(.*?)(?:\/|\?|#|$)/)?.[1] || domain?.includes('localhost')) {
        const NavigateParameter = generateNavigateParameterFromPath(path);

        const state = getActiveRouteState(navigation.getState());

        if (state?.name === 'ChatChannelMessages') {
          if (NavigateParameter.screenName === 'ChatChannelMessages') {
            // @ts-expect-error TS(2339): Property 'channelId' does not exist on type 'objec... Remove this comment to see the full error message
            if (state.params?.channelId === NavigateParameter.params.channelId) {
              // 同じチャンネルを開いている場合
              // @ts-expect-error TS(2345): Argument of type '{ messageId: any; }' is not assi... Remove this comment to see the full error message
              navigation.setParams({
                messageId: NavigateParameter.params.messageId,
              });
              return;
            }
          }
        }
        if (state?.name === 'ChatChannelMessages' || state?.name === 'ChatThreadMessages') {
          // @ts-expect-error TS(2339): Property 'push' does not exist on type 'Navigation... Remove this comment to see the full error message
          navigation.push(NavigateParameter.screenName, {
            ...NavigateParameter.params,
            queryParams: NavigateParameter.params,
          });
          return;
        }

        NavigateParameter.screenName &&
          // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
          navigation.navigate(NavigateParameter.screenName, {
            ...NavigateParameter.params,
          });
        return;
      }

      Linking.canOpenURL(parsedUrl).then((canOpenUrl) => canOpenUrl && Linking.openURL(parsedUrl));
    },
    [linkTo, navigation]
  );
  return onPress;
};
