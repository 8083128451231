export const colorTheme = {
  transparency5: '#000000',
  transparency10: '#000000',
  transparency20: '#000000',
  mono0: '#ffffff',
  mono100: '#f6f6f6',
  mono200: '#f1f1f1',
  mono300: '#ededed',
  mono400: '#d9d9d9',
  mono500: '#bfbfbf',
  mono600: '#999999',
  mono700: '#737373',
  mono800: '#4f4f4f',
  mono900: '#0d0d0d',
  mono1000: '#000000',
  red400: '#ffe8e5',
  red500: '#ff2b14',
  blue400: '#d8f5fe',
  blue500: '#3fa7fc',
  orange100: '#fffaf5',
  orange200: '#fef4e8',
  orange300: '#fbddba',
  orange400: '#ffa153',
  orange500: '#ff811a',
  yellow100: '#fffef0',
  yellow500: '#fef46f',
  yellow600: '#928205',
  green400: '#e9fde1',
  green500: '#4ac261',
} as const;

export const typographySizeTheme = {
  '2xs': 10,
  xs: 11,
  sm: 12,
  md: 14,
  lg: 16,
  xl: 19,
  '2xl': 22,
  '3xl': 28,
  '4xl': 37,
  '5xl': 56,
} as const;

export const spaceDimensionTheme = {
  none: 0,
  '3xs': 2,
  '2xs': 4,
  xs: 8,
  sm: 12,
  md: 16,
  lg: 20,
  xl: 24,
  '2xl': 32,
  '3xl': 40,
  '4xl': 48,
  '5xl': 64,
} as const;

export const borderWidthDimensionTheme = {
  medium: 1,
  thick: 2,
  extraThick: 3,
} as const;

export const lightSystemColorsTheme = {
  surface: colorTheme.mono200,
  onSurface: colorTheme.mono900,
  surfaceBright: colorTheme.mono100,
  onSurfaceBright: colorTheme.mono700,
  surfaceBrightest: colorTheme.mono0,
  onSurfaceBrightest: colorTheme.mono500,
  inverseSurface: colorTheme.mono1000,
  inverseOnSurface: colorTheme.mono0,
  outline: colorTheme.mono400,
  outlineVariant: colorTheme.mono300,
  focusOutline: colorTheme.orange500,
  scrim: colorTheme.transparency20,
} as const;

export const darkSystemColorsTheme = {
  surface: colorTheme.mono200,
  onSurface: colorTheme.mono900,
  surfaceBright: colorTheme.mono100,
  onSurfaceBright: colorTheme.mono700,
  surfaceBrightest: colorTheme.mono0,
  onSurfaceBrightest: colorTheme.mono500,
  inverseSurface: colorTheme.mono1000,
  inverseOnSurface: colorTheme.mono0,
  outline: colorTheme.mono400,
  outlineVariant: colorTheme.mono300,
  focusOutline: colorTheme.orange500,
  scrim: colorTheme.transparency20,
} as const;

export const lightImpressionColorsTheme = {
  primary: colorTheme.orange500,
  onPrimary: colorTheme.mono0,
  primaryContainer: colorTheme.orange400,
  onPrimaryContainer: colorTheme.mono0,
  notice: colorTheme.yellow500,
  onNotice: colorTheme.mono900,
  noticeContainer: colorTheme.yellow500,
  onNoticeContainer: colorTheme.mono900,
  negative: colorTheme.red500,
  onNegative: colorTheme.mono0,
  negativeContainer: colorTheme.red400,
  onNegativeContainer: colorTheme.mono900,
  positive: colorTheme.green500,
  onPositive: colorTheme.mono0,
  positiveContainer: colorTheme.green400,
  onPositiveContainer: colorTheme.mono900,
} as const;

export const darkImpressionColorsTheme = {
  primary: colorTheme.orange500,
  onPrimary: colorTheme.mono0,
  primaryContainer: colorTheme.orange400,
  onPrimaryContainer: colorTheme.mono0,
  notice: colorTheme.yellow500,
  onNotice: colorTheme.mono900,
  noticeContainer: colorTheme.yellow500,
  onNoticeContainer: colorTheme.mono900,
  negative: colorTheme.red500,
  onNegative: colorTheme.mono0,
  negativeContainer: colorTheme.red400,
  onNegativeContainer: colorTheme.mono900,
  positive: colorTheme.green500,
  onPositive: colorTheme.mono0,
  positiveContainer: colorTheme.green400,
  onPositiveContainer: colorTheme.mono900,
} as const;
