// Import the functions you need from the SDKs you need
// @ts-expect-error TS(2305): Module '"@env"' has no exported member 'FIRE_BASE_... Remove this comment to see the full error message
import { FIRE_BASE_VAPID_KEY } from '@env';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
const messaging = getMessaging();

export const requestForToken = async () => {
  const sw = await window.navigator.serviceWorker.register('/firebase-messaging-sw.js');

  return getToken(messaging, { vapidKey: FIRE_BASE_VAPID_KEY, serviceWorkerRegistration: sw })
    .then((currentToken) => {
      if (currentToken) {
        return currentToken;
      } else {
        console.error('No registration token available. Request permission to generate one.');
        sw.unregister();
      }
    })
    .catch((err) => {
      console.error('An error occurred while retrieving token. ', err);
    });
};

export const onMessageListener = () => {
  return new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
};
