import { FlatList } from '@gluestack-ui/themed';
import { Divider } from '@gluestack-ui/themed-native-base';

import { ChannelSearchResultItem } from '~/components/ChannelSearchResultItem';
import { EmptyMessage } from '~/components/EmptyMessage';
import { StreamChatGenerics } from '~/types';

interface Props {
  // @ts-expect-error TS(2304): Cannot find name 'Channel'.
  channels: Channel<StreamChatGenerics>[];
}

export const ChannelSearchResults = ({ channels }: Props) => {
  return (
    <FlatList
      data={channels}
      // @ts-expect-error
      keyExtractor={(item, index) => `${item.cid}:${index}`}
      ItemSeparatorComponent={() => <Divider borderColor="gray.200" />}
      // @ts-expect-error
      renderItem={({ item, index }) => <ChannelSearchResultItem channel={item} />}
      ListEmptyComponent={<EmptyMessage emptyMessage="マッチするチャンネルがありませんでした" />}
    />
  );
};
